import * as actionTypes from "./types";
import axios from "../axios-orders";
import {setAlert} from "./alert";

export const getNotificationStart = () => {
  return {
    type: actionTypes.GET_NOTIFICATION_START
  }
}

export const getNotificationSuccess = (notification) => {
  return {
    type: actionTypes.GET_NOTIFICATION_SUCCESS,
    notification: notification
  }
}

export const getNotificationFail = (error) => {
  return {
    type: actionTypes.GET_NOTIFICATION_FAIL,
    error: error
  }
}

export const getNotification = (status) => {
  return dispatch => {
    dispatch(getNotificationStart())
    axios.get(`admin/message/po/notification?status=${status}`,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(getNotificationSuccess(res.data.data))
        // dispatch(setAlert(res.data.message, "success"))
      })
      .catch(err => {
        dispatch(getNotificationFail(err.response.data.errors))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const storeNotificationStart = () => {
  return {
    type: actionTypes.STORE_NOTIFICATION_START
  }
}

export const storeNotificationSuccess = () => {
  return {
    type: actionTypes.STORE_NOTIFICATION_SUCCESS,
  }
}

export const storeNotificationFail = (error) => {
  return {
    type: actionTypes.STORE_NOTIFICATION_FAIL,
    error: error
  }
}

export const storeNotification = (storeData) => {
  return dispatch => {
    dispatch(storeNotificationStart())
    axios.post(`admin/message/po/notification`, storeData,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(storeNotificationSuccess())
        dispatch(setAlert(res.data.message, "success"))
      })
      .catch(err => {
        dispatch(storeNotificationFail(err.response.data.errors))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}