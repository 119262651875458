import React, {Fragment, useEffect, useState} from "react";
import {Box, Button, TextField, Paper} from "@material-ui/core";

import { TimePicker } from '@material-ui/pickers';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

import {connect} from "react-redux";
import * as actions from '../../../actions';
import {Loading} from "../../../components/UI";

const schema = yup.object().shape({
});

const CreateRound = props =>{
  const {classes, closedModalDialog,errorForm, loading, onStoreRound,onClearError} = props;


  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    onClearError()
  },[onClearError]);

  const onSubmit = data => {
    // console.log(data)
    onStoreRound(data)
    // console.log(data);
  }

  const [formState, setFormState] = useState({
    name: '',
    open: '',
    close: ''
  })

  const handleChange = (event) => {
    // console.log(event.target.value);
    const target = event.target.name
    event.persist()

    setFormState((formState) => ({
      ...formState,
      [target]: event.target.value
    }))
  }

  const [openedTime, handleOpenedTime] = useState(new Date());
  const [closedTime, handleClosedTime] = useState(new Date());

  return (loading ? <Loading/> :
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper elevation={3} className={classes.paperRoot}>
        <Box display="flexDirection">
          <Box className={classes.boxMarginRow}>
            <TextField
              label="Nama Ronde"
              variant="outlined"
              fullWidth
              size={"small"}
              name={"name"}
              inputRef={register}
              error={errorForm.name ? true : false}
              helperText={errorForm.name && errorForm.name[0]}
              defaultValue={formState.kind}
              onChange={handleChange}
            />
          </Box>
          <Box className={classes.boxMarginRow}>
            <TimePicker
              clearable
              fullWidth
              inputVariant="outlined"
              ampm={false}
              label="Jam Ronde Dibuka"
              name="open"
              error={errorForm.open ? true : false}
              helperText={errorForm.open && errorForm.open[0]}
              inputRef={register}
              value={openedTime}
              onChange={handleOpenedTime}
            />
          </Box>
          <Box className={classes.boxMarginRow}>
            <TimePicker
              clearable
              fullWidth
              inputVariant="outlined"
              ampm={false}
              label="Jam Ronde Ditutup"
              name="close"
              error={errorForm.close ? true : false}
              helperText={errorForm.close && errorForm.close[0]}
              inputRef={register}
              value={closedTime}
              onChange={handleClosedTime}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Box>
            <Button
              variant={"outlined"}
              size={"small"}
              className={classes.cancelButton}
              onClick={closedModalDialog}
            >
              Batal
            </Button>
          </Box>
          <Box>
            <Button
              type={"submit"}
              variant={"contained"}
              size={"small"}
              className={classes.submitButton}
            >
              Simpan
            </Button>
          </Box>
        </Box>
        </Paper>
      </form>
    </Fragment>
  );
};
const mapStateToProps = state => {
  return {
    loading: state.round.loading,
    errorForm: state.round.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onStoreRound: (storeData) => dispatch(actions.storeRound(storeData)),
    onClearError: () => dispatch(actions.clearErrorRound())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRound);