import {
    GET_BRANCH,
    ADD_BRANCH,
    GET_BRANCH_DETAIL,
    EDIT_BRANCH,
    DELETE_BRANCH,
    GET_BRANCH_SEARCH
} from '../actions/types'

const initialState = {
    branches : null,
    branch: {},
    detailBranch: {},
    branchSearch: null,
    loading: true,
    loadingSearch: true,
    loadingBranch: true,
    error: {}
}

const reducer = function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_BRANCH:
            return {
                ...state,
                branches: payload,
                loading: false
            }
        case GET_BRANCH_SEARCH:
            return {
                ...state,
                branchSearch: payload,
                loadingSearch: false
            }
        case GET_BRANCH_DETAIL:
            return {
                ...state,
                detailBranch: payload,
                loading: false
            }
        case ADD_BRANCH:
            return {
                ...state,
                branch: payload,
                loading: false
            }
        case EDIT_BRANCH:
            return {
                ...state,
                branch: payload,
                loading: false
            }
        case DELETE_BRANCH:
            return {
                ...state,
                branch: payload,
                loading: false
            }
        default:
            return state
    }
}

export default reducer;