import React, {Fragment, useEffect, useState} from "react";
import {Loading} from "../../../../components/UI";
import {useParams} from 'react-router-dom';
import {Main} from '../../style';
import {Modal} from '../../../../components/UI';
import {PaymentProof, OrderDetails, ListOrders, ProccessOrder, SentOrder, OrderArrived} from './components';

import * as actions from '../../../../actions';
import {connect} from "react-redux";
import {Grid} from "@material-ui/core";
import PaymentProofFileShow from "./components/PaymentProof/PaymentProofFileShow/PaymentProofFileShow";
import Paper from "@material-ui/core/Paper";
import OrderArrivedShow from "./components/OrderArrived/OrderArrivedShow/OrderArrivedShow";
import {OrderConfirmationSent} from "./components/SentOrder/components";
import {OrderConfirmationProcess} from "./components/ProccessOrder/components";

const DetailPurchaseOrder = props => {
  const {
    onGetPurchaseOrder,
    purchaseOrder,
    counting,
    loading,
    onApprovePurchaseOrder,
    onSentPurchaseOrder
  } = props;
  const {id} = useParams();
  const classes = Main();

  useEffect(() => {
    onGetPurchaseOrder(id);
    closedModalDialog()
  },[onGetPurchaseOrder,counting,id]);

  const [Form, setForm] = useState('');
  const [modalState, setModalState] = useState({
    open: false,
    title: '',
    maxWidth: 'sm',
  });
  const closedModalDialog = () => {
    setModalState({
      maxWidth: 'sm',
      title: '',
      open: false,
    });
    setForm('');
  };

  const paymentProofFileShow = data => {
    setModalState({
      open: true,
      title: 'Foto Bukti Bayar',
      maxWidth: 'sm'
    });
    setForm(<PaymentProofFileShow classes={classes} closedModalDialog={closedModalDialog} data={data}/>)
  };

  const proccess = data => {
    onApprovePurchaseOrder(id, data)
    // console.log(data)
  }

  const orderConfirmationProcess = data => {
    let title = (data.status === '1' ? (
      'Konfirmasi Purchase Order Diproses'
    ) : (
      'Konfirmasi Purchase Order Ditolak'
    ))

    setModalState({
      open: true,
      title: title,
      maxWidth: 'sm'
    });
    setForm(<OrderConfirmationProcess classes={classes} closedModalDialog={closedModalDialog} data={data} proccess={(data) => proccess(data)}/>)
  }

  const orderConfirmationSent = data => {
    setModalState({
      open: true,
      title: 'Konfirmasi Produk Dikirim',
      maxWidth: 'sm'
    });
    setForm(<OrderConfirmationSent classes={classes} data={data} closedModalDialog={closedModalDialog} sent={(data) => sent(data)}/>)
    // onSentPurchaseOrder(id, data)
  }

  const sent = data => {
    onSentPurchaseOrder(id, data)
  }

  const orderArrivedShow = data => {
    setModalState({
      open: true,
      title: 'Foto Bukti Sampai',
      maxWidth: 'sm'
    });
    setForm(<OrderArrivedShow classes={classes} closedModalDialog={closedModalDialog} data={data}/>)
  };



  return (loading ? <Loading/> :
    <Fragment>
      <Modal
        maxWidth={modalState.maxWidth}
        open={modalState.open}
        title={modalState.title}
        onCloseModal={closedModalDialog}
        contentModal={Form}
      />
      <div className={classes.root}>
        <div className={classes.row}>
          <div className={classes.tagMenu}>
            Detail Purhcase Order
          </div>
        </div>
        <Grid className={classes.row} container spacing={2}>
          <Grid item xl={8} md={8} sm={12} xs={12}>
            <PaymentProof
              classes={classes}
              purchaseOrder={purchaseOrder}
              paymentProofFileShow={(data) => paymentProofFileShow(data)}
            />
          </Grid>
          <Grid item xl={4} md={4} sm={12} xs={12}>
            <OrderDetails classes={classes} purchaseOrder={purchaseOrder}/>
          </Grid>
        </Grid>
        <div className={classes.row}>
          <Paper>
            <div className={classes.paperContent}>
              <div className={classes.paperPadding}>
                <ListOrders classes={classes} purchaseOrder={purchaseOrder}/>
              </div>
              <br/>
              <div>
                <ProccessOrder
                  classes={classes}
                  purchaseOrder={purchaseOrder}
                  // proccess={(data) => proccess(data)}
                  orderConfirmationProcess={(data) => orderConfirmationProcess(data)}
                />
              </div>
              <div className={classes.row}>
                <SentOrder
                  classes={classes}
                  purchaseOrder={purchaseOrder}
                  // sent={(data) => sent(data)}
                  orderConfirmationSent={(data) => orderConfirmationSent(data)}
                  proccess={(data) => proccess(data)}
                />
                <OrderArrived
                  classes={classes}
                  purchaseOrder={purchaseOrder}
                  orderArrivedShow={orderArrivedShow}
                />
              </div>
            </div>
          </Paper>
        </div>

      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    purchaseOrder: state.purchaseOrder.purchaseOrderV2,
    counting: state.purchaseOrder.counting,
    loading: state.purchaseOrder.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetPurchaseOrder: (id) => dispatch(actions.getPurchaseOrderV2(id)),
    onApprovePurchaseOrder: (id, storeData) => dispatch(actions.approvePurchaseOrder(id, storeData)),
    onSentPurchaseOrder: (id, storeData) => dispatch(actions.sentPurchaseOrder(id, storeData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailPurchaseOrder);