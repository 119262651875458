import {
    GET_PRODUCT,
    ADD_PRODUCT,
    DELETE_PRODUCT,
    GET_UPDATE_PRODUCT,
    PATCH_UPDATE_PRODUCT,
    PATCH_UPDATE_PRODUCT_START,
    PATCH_UPDATE_PRODUCT_FAIL,
    ADD_PRODUCT_START, ADD_PRODUCT_FAIL, DELETE_PRODUCT_START, DELETE_PRODUCT_FAIL,
} from '../actions/types'
import {updateObject} from "../shared/utility";
import * as actionTypes from "../actions/types";

const initialState = {
    product: {},
    products : null,
    detailProduct: null,
    productsV2: {},
    loading: true,
    productV2: {},
    loadingV2: false,
    error: {},
    counting : 0,
    loadingExport: false,
    loadingImportNewPrice: false,
    loadingImportBuybackPrice: false
}

const fetchProductsStart = (state, action) => {
    return updateObject(state, { loadingV2: true })
}

const fetchProductsSuccess = (state, action) => {
    return updateObject(state, {
        productsV2: action.products,
        loadingV2: false
    })
}

const fetchProductsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loadingV2: false
    })
}

const getProductStart = (state, action) => {
    return updateObject(state, { loadingV2: true })
}

const getProductSuccess = (state, action) => {
    return updateObject(state, {
        productV2: action.product,
        loadingV2: false
    })
}

const getProductFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loadingV2: false
    })
}

const fetchExportProductStart = (state, action) => {
    return updateObject(state, { loadingExport: true })
}

const fetchExportProductFinish = (state, action) => {
    return updateObject(state, { loadingExport: false })
}

const importNewPriceStart = (state, action) => {
    return updateObject(state, { loadingImportNewPrice: true })
}

const importNewPriceFinish = (state, action) => {
    return updateObject(state, { loadingImportNewPrice: false })
}

const importBuybackPriceStart = (state, action) => {
    return updateObject(state, { loadingImportBuybackPrice: true })
}

const importBuybackPriceFinish = (state, action) => {
    return updateObject(state, { loadingImportBuybackPrice: false })
}

const reducer = function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_PRODUCT:
            return {
                ...state,
                products: payload,
                loading: false
            }
        case GET_UPDATE_PRODUCT:
            return {
                ...state,
                detailProduct: payload,
                loading: false
            }
        case ADD_PRODUCT_START:
            return {
                ...state,
                loadingV2: true
            }
        case ADD_PRODUCT:
            return {
                ...state,
                product: payload,
                loading: false
            }
        case ADD_PRODUCT_FAIL:
            return {
                ...state,
                loadingV2: false
            }
        case PATCH_UPDATE_PRODUCT_START:
            return {
                ...state,
                loadingV2: true
            }
        case PATCH_UPDATE_PRODUCT:
            return {
                ...state,
                product: payload,
                loadingV2: false
            }
        case PATCH_UPDATE_PRODUCT_FAIL:
            return {
                ...state,
                loadingV2: false
            }
        case DELETE_PRODUCT_START:
            return {
                ...state,
                loadingV2: true
            }
        case DELETE_PRODUCT:
            return {
                ...state,
                product: payload,
                loading: false,
                counting : initialState.counting += 1
            }
        case DELETE_PRODUCT_FAIL:
            return {
                ...state,
                loadingV2: false
            }
        case actionTypes.FETCH_PRODUCTS_START:
            return fetchProductsStart(state, action)
        case actionTypes.FETCH_PRODUCTS_SUCCESS:
            return fetchProductsSuccess(state, action)
        case actionTypes.FETCH_PRODUCTS_FAIL:
            return fetchProductsFail(state, action)
        case actionTypes.GET_PRODUCT_START:
            return getProductStart(state, action)
        case actionTypes.GET_PRODUCT_SUCCESS:
            return getProductSuccess(state, action)
        case actionTypes.GET_PRODUCT_FAIL:
            return getProductFail(state, action)
        case actionTypes.GET_EXPORT_PRODUCT_START:
            return fetchExportProductStart(state, action)
        case actionTypes.GET_EXPORT_PRODUCT_FINISH:
            return fetchExportProductFinish(state, action)
        case actionTypes.IMPORT_BUYBACK_PRICE_START:
            return importBuybackPriceStart(state, action)
        case actionTypes.IMPORT_BUYBACK_PRICE_FINISH:
            return importBuybackPriceFinish(state, action)
        case actionTypes.IMPORT_NEW_PRICE_START:
            return importNewPriceStart(state, action)
        case actionTypes.IMPORT_NEW_PRICE_FINISH:
            return importNewPriceFinish(state, action)
        default:
            return state
    }
}

export default reducer;