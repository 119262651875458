import React, {Fragment, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Loading, TablePaginationActions} from '../../../components/UI';

import {connect} from "react-redux";
import {isEmpty} from "../../../shared/utility";

import * as actions from '../../../actions';
import ResetStockData from "./ResetStockData";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";

const columns = [
    { id: 'No', label: 'No', minWidth: '12%' },
  { id: 'tanggal', label: 'Tanggal', minWidth: '25%' },
  { id: 'cabang', label: 'Cabang', minWidth: '25%' },
  { id: 'pic', label: 'PIC', minWidth: '25%' },
  // { id: 'detail', label: 'Detail', minWidth: '12%' },

];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const ListResetStock = props => {
  const classes = useStyles();

  const {onFetchResetStock, loading, reset_stock_cabang, changing} = props;
  useEffect(() => {
    const timer = setTimeout(() => {
      onFetchResetStock(1);
    }, 1000)

    return () => clearTimeout(timer)
  },[onFetchResetStock,changing]);

  const handleChangePage = (event, newPage) => {
    // onFetchMembers(newPage, formSearch);
    onFetchResetStock(newPage)
  }

  let tableBody = '';

  if(!isEmpty(reset_stock_cabang)){
    const page = reset_stock_cabang.current_page - 1;
    const rowsPerPage = 10;
    const countRows = reset_stock_cabang.total_item

    tableBody = (
      <Fragment>
        <TableBody>
          {reset_stock_cabang.data.map((resetStock, index) => (
            <ResetStockData
              resetStock={resetStock}
              key={resetStock.id}
              index={index}
              // edited={() => props.edit(kurir)}
              // deleted={() => props.hapus(kurir)}
            />
          ))}

        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              colSpan={8}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    )
  }

  return (loading ? <Loading/> :
    <Fragment>
      <Paper className={classes.root}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    reset_stock_cabang: state.resetStockCabang.reset_stock_cabang,
    loading: state.resetStockCabang.loading,
    changing: state.resetStockCabang.changing
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchResetStock: page => dispatch(actions.fetchResetStockCabang(page))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListResetStock);