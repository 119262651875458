import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { 
  Grid, 
  Typography,
} from '@material-ui/core';
import moment from "moment";

import { Skeleton } from '@material-ui/lab'

import { 
  TotalTransaction,
  TotalSelling,
  TotalAOG,
  TotalMOG,
  TotalBranch,
  TotalUser,
  GrafikGoldPrice,
  GrafikNetIncome,
  OverviewTodayPricing,
  GrafikTransactionSales,
  GrafikTransactionSalesMoney
} from './components'

// Redux
import { connect } from 'react-redux'
import { getStatsInCard } from '../../actions/dashboard'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  bgColor: {
    backgroundColor: '#BCE0FD',
    height: '312px',
    position: 'absolute',
    // zIndex: 0
  },
  cardMobile: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  executiveSummary: {
    marginBottom: theme.spacing(2)
  }
}));

const Dashboard = ({ getStatsInCard, dashboard : { cards, loadingCard } }) => {
  const classes = useStyles();

  useEffect(() => {
    getStatsInCard()
  }, [loadingCard,getStatsInCard])

  return (
      <div className={classes.root}>
        <div className={classes.bgColor}></div>
        <Grid
          container
          spacing={3}
          justify="space-between"
        >
          <Grid item>  
            <Typography variant="h4">Dashboard</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h4">{moment.tz(new Date(), 'Asia/Jakarta').format('dddd, DD MMMM YYYY')}</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            lg={4}
            md={4}
            sm={6}
            xs={12}
          >
            {!loadingCard ? (
              <TotalTransaction item={cards.total_transaksi} />
            ):(
              <Skeleton variant="rect" height={100}></Skeleton>
            )}
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={6}
            xs={12}
          >
            {!loadingCard ? (
              <TotalSelling item={cards.total_penjualan} />
            ):(
              <Skeleton variant="rect" height={100}></Skeleton>
            )}
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={6}
            xs={12}
          >
            {!loadingCard ? (
              <TotalAOG item={cards.count_aog} />
            ):(
              <Skeleton variant="rect" height={100}></Skeleton>
            )}
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={6}
            xs={12}
          >
            {!loadingCard ? (
              <TotalMOG item={cards.count_mog} />
            ):(
              <Skeleton variant="rect" height={100}></Skeleton>
            )}
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={6}
            xs={12}
          >
            {!loadingCard ? (
              <TotalBranch item={cards.total_branch} />
            ):(
              <Skeleton variant="rect" height={100}></Skeleton>
            )}
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={6}
            xs={12}
          >
            {!loadingCard ? (
              <TotalUser item={cards.total_user} />
            ):(
              <Skeleton variant="rect" height={100}></Skeleton>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid 
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <OverviewTodayPricing />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid 
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <GrafikGoldPrice />
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <GrafikNetIncome />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid 
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <GrafikTransactionSales />
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <GrafikTransactionSalesMoney />
          </Grid>
        </Grid>
      </div>
  );
};
const mapStateToProps = state => ({
  dashboard : state.dashboard
})
export default connect(mapStateToProps, {getStatsInCard})(Dashboard);
