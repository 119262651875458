import React, {Fragment, useEffect, useState} from "react";
import {Button, Card, CardActions, CardContent, Grid, MenuItem, Switch, TextField, withStyles} from "@material-ui/core";
import CapitalizedText from "../../../../../components/layout/CapitalizedText";
import Skeleton from "@material-ui/lab/Skeleton";
// import UploadImage from "../../../CreateUser/UploadImage";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import {connect} from "react-redux";
import * as actions from '../../../../../actions';
import {useHistory} from "react-router-dom";
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";

const schema = yup.object().shape({
});

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 38,
    height: 19,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(19px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 19 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const Form = props => {
  const {
    user,
    classes,
    onGetUserRole,
    onGetBranch,
    branches,
    userRoles,
    loadingUserRole,
    loadingBranch,
    onUpdateUser,
    id,
    errors
  } = props;

  const history = useHistory()

  const [formState, setFormState] = useState({
    name: user.name,
    email: user.email,
    password: '',
    id_branch: user.id_branch,
    id_role: user.id_role,
    is_active: user.is_active === "1" ? true : false,
  });

  const handleChange = (event) => {
    const target = event.target.name
    event.persist()

    if(event.target.type === 'checkbox'){
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.checked
      }))
    }else{
      setFormState((formState) => ({
        ...formState,
        [target]:event.target.value
      }))
    }
  }

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  });
  // console.log(formState)

  const onSubmit = data => {
    data.id_branch = formState.id_branch;
    data.id_role = formState.id_role;
    data.is_active = formState.is_active === true ? "1" : "0";

    onUpdateUser(id, data, history);
    // console.log(data);
  }

  useEffect(() => {
    onGetBranch()
    onGetUserRole()
  },[onGetBranch, onGetUserRole]);

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid
            item
            lg={8}
            md={8}
            sm={12}
            xs={12}
          >
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      variant="outlined"
                      defaultValue={formState.name || ''}
                      label="Nama Anda"
                      margin="dense"
                      name="name"
                      onChange={handleChange}
                      helperText={
                        errors.name && errors.name
                      }
                      error={errors.name && true}
                      inputRef={register}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      variant="outlined"
                      defaultValue={formState.email || ''}
                      label="Email Anda"
                      margin="dense"
                      name="email"
                      type="email"
                      onChange={handleChange}
                      helperText={
                        errors.email && errors.email
                      }
                      error={errors.email && true}
                      inputRef={register}
                    />
                  </Grid>

                </Grid>
                <Grid container spacing={2}>
                  {/*<Grid*/}
                  {/*  item*/}
                  {/*  lg={6}*/}
                  {/*  md={6}*/}
                  {/*  sm={12}*/}
                  {/*  xs={12}*/}
                  {/*>*/}
                  {/*  <TextField*/}
                  {/*    fullWidth*/}
                  {/*    variant="outlined"*/}
                  {/*    defaultValue={formState.password || ''}*/}
                  {/*    label="password"*/}
                  {/*    margin="dense"*/}
                  {/*    type="password"*/}
                  {/*    name="password"*/}
                  {/*    onChange={handleChange}*/}
                  {/*    helperText={*/}
                  {/*      errors.password && errors.password*/}
                  {/*    }*/}
                  {/*    error={errors.password && true}*/}
                  {/*    inputRef={register}*/}
                  {/*  />*/}
                  {/*</Grid>*/}
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    {!loadingBranch ? (
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={formState.id_branch || ''}
                        label="Cabang"
                        margin="dense"
                        name="id_branch"
                        onChange={handleChange}
                        helperText={
                          errors.id_branch && errors.id_branch
                        }
                        error={errors.id_branch && true}
                        inputRef={register}
                        select
                      >
                        {branches.data.map((item) => (
                          <MenuItem key="gold" value={item.id}>
                            <CapitalizedText text={item.name} />
                          </MenuItem>
                        ))}
                      </TextField>
                    ):(
                      <Skeleton variant="rect" height={30}></Skeleton>
                    )}
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    {!loadingUserRole ? (
                      <TextField
                        fullWidth
                        variant="outlined"
                        defaultValue={formState.id_role || ''}
                        label="Jabatan Pengguna"
                        margin="dense"
                        name="id_role"
                        onChange={handleChange}
                        helperText={
                          errors.id_role && errors.id_role
                        }
                        error={errors.id_role && true}
                        inputRef={register}
                        select
                      >
                        {userRoles.data.map((item) => (
                          <MenuItem key="gold" value={item.id}>
                            <CapitalizedText text={item.name} />
                          </MenuItem>
                        ))}
                      </TextField>
                    ):(
                      <Skeleton variant="rect" height={30}></Skeleton>
                    )}
                  </Grid>
                </Grid>
                <br/>
                <FormGroup>
                  <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item>Tidak Aktif</Grid>
                      <Grid item>
                        <AntSwitch checked={formState.is_active} onChange={handleChange} name="is_active" inputRef={register}/>
                      </Grid>
                      <Grid item>Aktif</Grid>
                    </Grid>
                  </Typography>
                </FormGroup>

              </CardContent>
              <CardActions>
                <Button type="submit" size="medium" variant="contained" className={classes.btn}>
                  Simpan
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={12}
            xs={12}
          >
            {/*<Card>*/}
            {/*  <CardContent>*/}
            {/*    <UploadImage value={banner} handleChangeBanner={handleChangeBanner} />*/}
            {/*  </CardContent>*/}
            {/*</Card>*/}
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    branches: state.branch.branches,
    userRoles: state.user.userRole,
    loadingUserRole: state.user.loadingUserRole,
    loadingBranch: state.branch.loading,
    errors: state.user.errors
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetBranch: () => dispatch(actions.getBranch()),
    onGetUserRole: () => dispatch(actions.getUserRole()),
    onUpdateUser:(id, storeData, history) => dispatch(actions.updateUser(id, storeData, history))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Form);