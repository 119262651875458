import React,{Fragment} from "react";
import TableCell from "@material-ui/core/TableCell";
import {Avatar} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";

const ProductData = props => {
  const {product, index} = props;
  return (
    <Fragment>
      <TableRow key={product.id_product}>
        <TableCell>
          {index+1}
        </TableCell>
        <TableCell>
          <Avatar alt="Remy Sharp" src={product.product_image} />
        </TableCell>
        <TableCell>
          {product.product_name+' '+product.product_weight+' '+product.product_unit}
        </TableCell>
        <TableCell>
          {product.qty}
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default ProductData;