import * as actionTypes from './types';
import axios from '../axios-orders';
import { setAlert } from './alert'

export const fetchQuotaProductsStart = () => {
  return {
    type: actionTypes.FETCH_QUOTA_PRODUCTS_START
  }
}

export const fetchQuotaProductsSuccess = (quotaProducts) => {
  return {
    type: actionTypes.FETCH_QUOTA_PRODUCTS_SUCCESS,
    quotaProducts: quotaProducts
  }
}

export const fetchQuotaProductsFail = (error) => {
  return {
    type: actionTypes.FETCH_QUOTA_PRODUCTS_FAIL,
    error: error
  }
}

export const fetchQuotaProducts = (page) => {
  return dispatch => {
    dispatch(fetchQuotaProductsStart())
    axios.get(`admin/quota/product?page=${page}&page_size=10`,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(fetchQuotaProductsSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchQuotaProductsFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const getQuotaProductStart = () => {
  return {
    type: actionTypes.GET_QUOTA_PRODUCT_START
  }
}

export const getQuotaProductSuccess = (quotaProduct) => {
  return {
    type: actionTypes.GET_QUOTA_PRODUCT_SUCCESS,
    quotaProduct: quotaProduct
  }
}

export const getQuotaProductFail = (error) => {
  return {
    type: actionTypes.GET_QUOTA_PRODUCT_FAIL,
    error: error
  }
}

export const getQuotaProduct = (id) => {
  return dispatch => {
    dispatch(getQuotaProductStart())
    axios.get(`admin/quota/product/${id}`,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(getQuotaProductSuccess(res.data.data))
      })
      .catch(err => {
        dispatch(getQuotaProductFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const storeQuotaProductStart = () => {
  return {
    type: actionTypes.STORE_QUOTA_PRODUCT_START
  }
}

export const storeQuotaProductSuccess = () => {
  return {
    type: actionTypes.STORE_QUOTA_PRODUCT_SUCCESS,
  }
}

export const storeQuotaProductFail = (error) => {
  return {
    type: actionTypes.STORE_QUOTA_PRODUCT_FAIL,
    error: error
  }
}

export const storeQuotaProduct = (storeData,history) => {
  return dispatch => {
    dispatch(storeQuotaProductStart())
    axios.post(`admin/quota/product`, storeData,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(storeQuotaProductSuccess())
        dispatch(setAlert(res.data.message, "success"))
        history.push('/quota-product')
      })
      .catch(err => {
        dispatch(storeQuotaProductFail(err.response.data.errors))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const updateQuotaProductStart = () => {
  return {
    type: actionTypes.UPDATE_QUOTA_PRODUCT_START
  }
}

export const updateQuotaProductSuccess = () => {
  return {
    type: actionTypes.UPDATE_QUOTA_PRODUCT_SUCCESS,
  }
}

export const updateQuotaProductFail = (error) => {
  return {
    type: actionTypes.UPDATE_QUOTA_PRODUCT_FAIL,
    error: error
  }
}

export const updateQuotaProduct = (id,storeData,history) => {
  return dispatch => {
    dispatch(updateQuotaProductStart())
    axios.put(`admin/quota/product/${id}`, storeData,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(updateQuotaProductSuccess())
        dispatch(setAlert(res.data.message, "success"))
        history.push('/quota-product')
      })
      .catch(err => {
        dispatch(updateQuotaProductFail(err.response.data.errors))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const deleteQuotaProductStart = () => {
  return {
    type: actionTypes.DELETE_QUOTA_PRODUCT_START
  }
}

export const deleteQuotaProductSuccess = () => {
  return {
    type: actionTypes.DELETE_QUOTA_PRODUCT_SUCCESS,
  }
}

export const deleteQuotaProductFail = (error) => {
  return {
    type: actionTypes.DELETE_QUOTA_PRODUCT_FAIL,
    error: error
  }
}

export const deleteQuotaProduct = (id) => {
  return dispatch => {
    dispatch(deleteQuotaProductStart())
    axios.delete(`admin/quota/product/${id}`,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(deleteQuotaProductSuccess())
        dispatch(setAlert(res.data.message, "success"))
      })
      .catch(err => {
        dispatch(deleteQuotaProductFail(err.response.data.errors))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const clearErrorQuotaProduct = () => {
  return {
    type: actionTypes.CLEAR_ERROR_QUOTA_PRODUCT
  }
}