import React,{Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {isEmpty} from '../../../../../../shared/utility'
import {Search} from "@material-ui/icons";

const OrderArrived = props => {
  const {
    purchaseOrder,
    classes,
    orderArrivedShow
  } = props;

  let paymentProofFile = '';

  if (!isEmpty(purchaseOrder.file_bukti_bayar)) {
    // console.log(assetImageUrl);
    paymentProofFile = (
      <Fragment>
        {purchaseOrder.file_bukti_sampai.map((image, index) => (
          <Grid key={index} item lg={3} md={3} sm={12} xs={12}>
            <Card className={classes.imageList}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={image.link}
                  title="Contemplative Reptile"
                />
              </CardActionArea>
              <CardContent>
                <Typography variant="h5">
                  {image.caption}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonWarning}
                  startIcon={<Search />}
                  onClick={() => orderArrivedShow(image)}
                  fullWidth
                >
                  Lihat
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Fragment>
    )
  }

  return (purchaseOrder.status !== "1" ? '' :(
      <Fragment>
        <div>
          Upload Bukti Produk Telah Sampai
        </div>
        <div className={classes.row}>
          <Grid container spacing={2} className={classes.row}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Fragment>
                <Grid container spacing={2}>
                  {paymentProofFile}
                </Grid>
              </Fragment>
            </Grid>
          </Grid>
        </div>
      </Fragment>
    )
  );
};

export default OrderArrived;