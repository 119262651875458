import React, {Fragment, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Button, Grid, Paper, Typography} from "@material-ui/core";
import {Modal} from '../../components/UI';
import {Add as AddIcon} from "@material-ui/icons";
import ListRound from "./ListQuotaProduct";
import CreateRound from "./CreateQuotaProduct";
import {connect} from "react-redux";
import DeleteRound from "./DeleteRound";
import {Link} from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom:  theme.spacing(2)
  },
  tagMenu: {
    fontWeight: "bold",

  },
  addButton: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
    textTransform: "none",
    fontWeight: "bold",
    borderRadius: '60px',
    // padding: 'auto',
    '&:hover':{
      backgroundColor: theme.palette.warning.light,
    }
  },
  paperRoot: {
    padding: theme.spacing(3)
  },
  paperTag: {
    marginBottom: theme.spacing(3)
  },
  boxMarginRow: {
    margin: "auto auto 15px"
  },
  boxMarginColumn: {
    margin: "auto auto auto 10px"
  },
  submitButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
    textTransform: "none",
    fontWeight: "bold",
    margin: "auto auto auto 10px",
    // borderRadius: '60px',
    // padding: 'auto',
    '&:hover':{
      backgroundColor: theme.palette.success.light,
    }
  },
  cancelButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    textTransform: "none",
    fontWeight: "bold",
    margin: "auto auto auto 10px",
    // borderRadius: '60px',
    // padding: 'auto',
    '&:hover':{
      borderColor: theme.palette.error.light,
      color: theme.palette.error.light,
    }
  }
}))

const QuotaProduct = props => {
  const classes = useStyles();
  const {changing} = props;

  useEffect(() => {
    closedModalDialog();
  },[changing])

  const [Form, setForm] = useState('');
  const [modalState, setModalState] = useState({
    open: false,
    title: '',
    maxWidth: 'sm',
  });

  const closedModalDialog = () => {
    setModalState({
      maxWidth: 'sm',
      title: '',
      open: false,
    });
    setForm('');
  }

  const add = () => {
    setModalState({
      open: true,
      title: 'Form Ronde',
      maxWidth: 'sm'
    });
    setForm(<CreateRound closedModalDialog={() => closedModalDialog()} classes={classes}/>)
    // setCurrentPage(0);
  }

  const edit = (data) => {
    console.log(data)

  }

  const deleted = (data) => {
    setModalState({
      open: true,
      title: 'Hapus Ronde',
      maxWidth: 'xs'
    });
    setForm(<DeleteRound closedModalDialog={() => closedModalDialog()} classes={classes} round={data}/>)
    // onDeleteRound(data.id)
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <Modal
          maxWidth={modalState.maxWidth}
          open={modalState.open}
          title={modalState.title}
          onCloseModal={closedModalDialog}
          contentModal={Form}
        />

        <div className={classes.row}>
          <Grid container spacing={2} justify={"space-between"}>
            <Grid item>
              <Typography variant={"h4"} className={classes.tagMenu}>
                Pengaturan Kuota Harian Pusat
              </Typography>
            </Grid>
            <Grid item>
              <Link to='/quota-product/create'>
              <Button variant={"contained"} size={"medium"} startIcon={React.cloneElement(<AddIcon/>)} className={classes.addButton} onClick={add}>Kuota Harian</Button>
              </Link>
            </Grid>
          </Grid>

        </div>
        <div className={classes.row}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper variant={"outlined"} className={classes.paperRoot}>
                <div className={classes.paperTag}>
                  <Typography variant={"h5"} className={classes.tagMenu}>
                    List Kuota Harian Pusat
                  </Typography>
                </div>
                <div>
                  <ListRound edit={(data) => edit(data)} deleted={(data) => deleted(data)}/>
                  {/*<ListResetStock/>*/}
                </div>

              </Paper>

            </Grid>
          </Grid>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    rounds: state.round.rounds,
    loading: state.round.loading,
    changing: state.round.changing
  }
}

export default connect(mapStateToProps)(QuotaProduct);