import React, { useState, useEffect, Fragment } from 'react'
import axios from 'axios'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import { 
    Grid, 
    Typography, 
    Card, 
    CardContent, 
    TextField, 
    MenuItem, 
    CardActions, 
    Button,
    Paper,
    InputBase,
    Divider,
    IconButton,
} from '@material-ui/core'
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers"
import SchemaValidation from './validation'
import { useHistory, useParams, Link } from 'react-router-dom'

import { connect } from 'react-redux'
import { editBranch } from '../../../actions/branch'
import { getVillageEdit } from '../../../actions/village'
import Skeleton from '@material-ui/lab/Skeleton'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import SearchIcon from '@material-ui/icons/Search'
import ListUser from '../ListUser'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1)
    },
    row: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    btn: {
        backgroundColor: '#011747',
        color: '#FFF'
    },
    backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
    },
    searchRoot: {
		padding: '0px 4px',
        display: 'flex',
        alignItems: 'center',
		width: 'auto',
		// marginTop: theme.spacing(2)
	},
	input: {
        marginLeft: theme.spacing(1),
            flex: 1,
        },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 24,
        margin: 4,
    },
}))

const EditCustomer = ({ 
    branch : { detailBranch, loading },
    village : { villagesEdit },
    getVillageEdit,
    editBranch
}) => {
    const classes = useStyles()
    const history = useHistory()
    const { id } = useParams()
    const [ url ] = useState(`${process.env.REACT_APP_BASE_URL}/admin/branch/${id}`)
    const { register, handleSubmit, errors } = useForm({
		resolver: yupResolver(SchemaValidation)
    });
    const [formState, setFormState] = useState({
        values: {}
    });

    const handleSearchAddress = () => {
        if(formState.values){
            getVillageEdit(formState.values.kode_pos)
        }
    }

    const [ branch, setBranch ] = useState({})
    const [ isLoading, setIsLoading ] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const token = sessionStorage.getItem('access_token');
                // console.log(`token = ${token}`);
                const result = await axios({
                    url: url,
                    method: "GET",
                    headers: { 
                        'Content-Type': 'application/json', 
                        'Accept' : 'application/json', 
                        'Authorization' : `bearer ${token}` 
                    }
                });
                setBranch(result.data)
                setFormState(formState => ({
                    ...formState,
                    values: {
                      name: result.data.name,
                      phone: result.data.phone,
                      is_active: result.data.is_active,
                      address: result.data.address,
                      kode_pos: result.data.village ? result.data.village.kode_pos : ''
                    }
                }));
            } catch (error) {
              console.log(error)
            }
            setIsLoading(false);
        };
        fetchData()
    }, [url]);

    const handleChange = event => {
        event.persist();
    
        setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              [event.target.name]: event.target.value
            }
        }));
    };

    const onSubmit = e => {
        // console.log(formState.values)
        editBranch(id, formState.values, history)
    }

    return isLoading ? 
    <Backdrop className={classes.backdrop} open>
        <CircularProgress color="inherit" />
    </Backdrop> 
    :
        <Fragment>
            <div className={classes.root}>
            
                <div className={classes.row}>
                    <Grid container spacing={2} justify="space-between">
                        <Grid item>
                            <Typography variant="h4">Cabang</Typography>
                            <p>{formState.id_agent}</p>
                        </Grid>
                        <Grid item>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link color="inherit" to="/dashboard">
                                    Dashboard
                                </Link>
                                <Link color="inherit" to="/branch">
                                    Cabang
                                </Link>
                                <Typography color="textPrimary">Edit Cabang</Typography>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.row}>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            lg={8}
                            md={8}
                            sm={12}
                            xs={12}
                        >
                            <Card>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                {branch.village ? (
                                                    <Paper component="form" className={classes.searchRoot}>
                                                        <InputBase
                                                            className={classes.input}
                                                            defaultValue={branch.village.kode_pos || ''}
                                                            name="kode_pos"
                                                            onChange={handleChange}
                                                            placeholder="Masukan Kode Pos Anda"
                                                            inputProps={{ 'aria-label': 'Cari Kode Pos' }}
                                                        />
                                                        <Divider className={classes.divider} orientation="vertical" />
                                                        <IconButton onClick={handleSearchAddress} className={classes.iconButton} aria-label="search">
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </Paper>
                                                ):(
                                                  <Paper component="form" className={classes.searchRoot}>
                                                      <InputBase
                                                        className={classes.input}
                                                        defaultValue={''}
                                                        name="kode_pos"
                                                        onChange={handleChange}
                                                        placeholder="Masukan Kode Pos Anda"
                                                        inputProps={{ 'aria-label': 'Cari Kode Pos' }}
                                                      />
                                                      <Divider className={classes.divider} orientation="vertical" />
                                                      <IconButton onClick={handleSearchAddress} className={classes.iconButton} aria-label="search">
                                                          <SearchIcon />
                                                      </IconButton>
                                                  </Paper>
                                                )}
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                {branch ? (
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        defaultValue={branch.name || ''}
                                                        label="Nama Customer"
                                                        margin="dense"
                                                        name="name"
                                                        onChange={handleChange}
                                                        helperText={
                                                            errors.name && errors.name.message
                                                        }
                                                        error={errors.name && true}
                                                        inputRef={register}
                                                    />
                                                ):(
                                                    <Skeleton variant="rect"></Skeleton>
                                                )}
                                            </Grid>
                                            
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                {branch ? (
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        defaultValue={branch.phone || ''}
                                                        label="Nomor Telepon"
                                                        margin="dense"
                                                        name="phone"
                                                        onChange={handleChange}
                                                        helperText={
                                                            errors.phone && errors.phone.message
                                                        }
                                                        error={errors.phone && true}
                                                        inputRef={register}
                                                    />
                                                ):(
                                                    <Skeleton variant="rect"></Skeleton>
                                                )}
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                {branch ? (
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        defaultValue={branch.is_active || ''}
                                                        label="Status Aktif"
                                                        margin="dense"
                                                        name="is_active"
                                                        onChange={handleChange}
                                                        helperText={
                                                            errors.is_active && errors.is_active.message
                                                        }
                                                        error={errors.is_active && true}
                                                        inputRef={register}
                                                        select
                                                    >
                                                        <MenuItem key="aktif" value="1">
                                                            Aktif
                                                        </MenuItem>
                                                        <MenuItem key="tidak_aktif" value="0">
                                                            Tidak Aktif
                                                        </MenuItem>
                                                    </TextField>
                                                ):(
                                                    <Skeleton variant="rect"></Skeleton>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                {branch ? (
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        rows={4}
                                                        multiline={true}
                                                        defaultValue={branch.address || ''}
                                                        label="Alamat Lengkap"
                                                        margin="dense"
                                                        name="address"
                                                        onChange={handleChange}
                                                        helperText={
                                                            errors.address && errors.address.message
                                                        }
                                                        error={errors.address && true}
                                                        inputRef={register}
                                                    />
                                                ):(
                                                    <Skeleton variant="rect"></Skeleton>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="medium" variant="outlined">
                                            Batal
                                        </Button>
                                        <Button type="submit" size="medium" variant="contained" className={classes.btn}>
                                            Simpan
                                        </Button>
                                    </CardActions>
                                </form>
                            </Card>
                        </Grid>
                        <Grid
                            item
                            lg={4}
                            md={4}
                            sm={12}
                            xs={12}
                        >
                            {villagesEdit !== null && (
                                <Card>
                                    <CardContent>
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant="outlined"
                                            value={villagesEdit !== null ? villagesEdit.province.name : ''}
                                            label="Provinsi"
                                            margin="dense"
                                            name="province"
                                            onChange={handleChange}
                                            helperText={
                                                errors.province && errors.province.message
                                            }
                                            error={errors.province && true}
                                            inputRef={register}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant="outlined"
                                            value={villagesEdit !== null ? villagesEdit.regency.name : ''}
                                            label="Kota/Kabupaten"
                                            margin="dense"
                                            name="city"
                                            onChange={handleChange}
                                            helperText={
                                                errors.city && errors.city.message
                                            }
                                            error={errors.city && true}
                                            inputRef={register}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant="outlined"
                                            value={villagesEdit !== null ? villagesEdit.district.name : ''}
                                            label="Kecamatan"
                                            margin="dense"
                                            name="district"
                                            onChange={handleChange}
                                            helperText={
                                                errors.district && errors.district.message
                                            }
                                            error={errors.district && true}
                                            inputRef={register}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant="outlined"
                                            value={villagesEdit !== null ? villagesEdit.village.name : ''}
                                            label="Kelurahan"
                                            margin="dense"
                                            name="village"
                                            onChange={handleChange}
                                            helperText={
                                                errors.village && errors.village.message
                                            }
                                            error={errors.village && true}
                                            inputRef={register}
                                        />
                                    </CardContent>
                                </Card>
                            )}
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.row}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <ListUser />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Fragment>
    
    
}
EditCustomer.propTypes = {
    editBranch: PropTypes.func.isRequired,
    detailBranch: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    branch: state.branch,
    village: state.village
})

export default connect(mapStateToProps, { getVillageEdit, editBranch })(EditCustomer)