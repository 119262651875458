import * as actionTypes from './types';
import axios from '../axios-orders';
import { setAlert } from './alert'

export const fetchResetStockCabangStart = () => {
  return {
    type: actionTypes.FETCH_RESET_STOCK_CABANG_START
  }
}

export const fetchResetStockCabangSuccess = (reset_stock_cabang) => {
  return {
    type: actionTypes.FETCH_RESET_STOCK_CABANG_SUCCESS,
    reset_stock_cabang: reset_stock_cabang
  }
}

export const fetchResetStockCabangFail = (error) => {
  return {
    type: actionTypes.FETCH_RESET_STOCK_CABANG_FAIL,
    error: error
  }
}

export const fetchResetStockCabang = (page) => {
  return dispatch => {
    dispatch(fetchResetStockCabangStart())
    axios.get(`admin/reset_stock?page=${page}`,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(fetchResetStockCabangSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchResetStockCabangFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const storeResetStockCabangStart = () => {
  return {
    type: actionTypes.STORE_RESET_STOCK_CABANG_START
  }
}

export const storeResetStockCabangSuccess = (reset_stock_cabang) => {
  return {
    type: actionTypes.STORE_RESET_STOCK_CABANG_SUCCESS,
  }
}

export const storeResetStockCabangFail = (error) => {
  return {
    type: actionTypes.STORE_RESET_STOCK_CABANG_FAIL,
    error: error
  }
}

export const storeResetStockCabang = (id) => {
  return dispatch => {
    dispatch(storeResetStockCabangStart())
    axios.post(`admin/reset_stock`, {id_branch: id},{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(storeResetStockCabangSuccess(res.data.data))
        dispatch(setAlert(res.data.message, "success"))
      })
      .catch(err => {
        dispatch(storeResetStockCabangFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}