import React,{Fragment} from "react";
import {Button, Grid} from "@material-ui/core";
import {isEmpty} from "../../../../../../shared/utility";

const ProccessOrder = props => {
  const {
    purchaseOrder,
    classes,
    // proccess,
    orderConfirmationProcess
  } = props;

  let button = '';
  if (!isEmpty(purchaseOrder)){
    if(purchaseOrder.status === "0"){
      button = (
        <Fragment>
          <Grid item>
            <Button
              className={classes.buttonWarning}
              size={"medium"}
              variant={"contained"}
              onClick={() => orderConfirmationProcess({'status': '1'})}
            >
              Prosses
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.btnError}
              size={"medium"}
              variant={"contained"}
              onClick={() => orderConfirmationProcess({'status': '0'})}
            >
              Tolak
            </Button>
          </Grid>
        </Fragment>
      );
    };
  };

  return (
    <Fragment>
      <Grid container spacing={2} justify={"flex-end"}>
        {button}
      </Grid>
    </Fragment>
  );
};

export default ProccessOrder;