import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import {
	Button,
	DialogContentText,
	Typography,
	Card,
	CardContent,
	TableFooter
} from '@material-ui/core'

// Redux
import { connect } from 'react-redux'
import { getProductPriceBuyback, deleteProductPriceBuyback } from '../../../actions/productPriceBuyback'

import { 
	Box,
} from '@material-ui/core';

import CreatePrice from './CreatePrice'
import {isEmpty} from "../../../shared/utility";

import TablePaginationActions from '../../../components/UI/Table/TablePagination/TablePaginationActions'
import CardHeader from "@material-ui/core/CardHeader";
import ProductPriceBuyBackData from "./ProductPriceBuyBackData/ProductPriceBuyBackData";

const columns = [
  { id: 'no', label: 'No', minWidth: 100 },
  { id: 'buyback_price', label: 'Harga Buyback', minWidth: 200 },
  { id: 'tanggal', label: 'Tanggal Active', minWidth: 200 },
  { id: 'action', label: 'Action', minWidth: 100 },
  
];

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	container: {
		maxHeight: 440,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	statusPrice: {
        width: '250px'
    }
}));

const ProducPriceBuyBack = (props) => {
	const { 
		getProductPriceBuyback, 
		deleteProductPriceBuyback, 
		productPriceBuyback : { pricingBuyback, loading, counting },
		id_product
	} = props
	const classes = useStyles();

	// Create Price
	const [createPriceOpen, setCreatePriceOpen] = React.useState(false);

	const handleClickOpen = () => {
		setCreatePriceOpen(true);
	};

	const handleCloseCreatePrice = () => {
		setCreatePriceOpen(false);
	};
	// End Create price

	// Delete Price
	const [deleteOpen, setDeleteOpen] = React.useState({
		open : false,
		item : null
	});

	const handleClickOpenDeletePrice = (item) => {
		setDeleteOpen({
			open : true,
			item : item
		});
	};

	const handleCloseDeletePrice = () => {
		setDeleteOpen({
			open : false,
		});
	};

	const doDeletePrice = (event) => {
		deleteProductPriceBuyback(event.id, id_product)
		handleCloseDeletePrice()
	}


	useEffect(() => {
		getProductPriceBuyback(id_product,1)
	}, [loading, getProductPriceBuyback, id_product, counting])

	const handleChangePage = (event, newPage) => {
		getProductPriceBuyback(id_product,newPage)
	};

	let tabelData = '';
	if (!isEmpty(pricingBuyback)){
		const page = pricingBuyback.current_page - 1;
		const rowsPerPage = 10;
		const countRows = pricingBuyback.total_item
		tabelData = (
			<Fragment>
				<TableBody>
					{pricingBuyback.data.map((pricingBuybackData, index) => (
						<ProductPriceBuyBackData
							item={pricingBuybackData}
							key={pricingBuybackData.id}
							index={index}
							deleted={() => handleClickOpenDeletePrice(pricingBuybackData)}
						/>
					))}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[rowsPerPage]}
							colSpan={5}
							count={countRows}
							rowsPerPage={rowsPerPage}
							page={page}
							onChangePage={handleChangePage}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Fragment>
		)
	}

	return pricingBuyback === null ? 
		<Backdrop className={classes.backdrop} open>
			<CircularProgress color="inherit" />
		</Backdrop> 
		: 
		<Fragment>
			<Card>
				<CardHeader
					title="Product Price Buyback"
				/>
				<CardContent>
					<Paper className={classes.root}>
						<TableContainer >
							<Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
							<TableHead>
								<TableRow>
								{columns.map((column) => (
									<TableCell
									key={column.id}
									align={column.align}
									style={{ minWidth: column.minWidth }}
									>
									{column.label}
									</TableCell>
								))}
								</TableRow>
							</TableHead>
							{tabelData}
							</Table>
						</TableContainer>

						<Box display="flex" flexDirection="row-reverse" p={1} m={1}>
							<Button color="primary" variant="contained" onClick={handleClickOpen}>
								Tambah Harga Buyback Baru
							</Button>
						</Box>
					</Paper>
				</CardContent>
			</Card>
			
			<Dialog open={createPriceOpen} onClose={handleCloseCreatePrice} aria-labelledby="form-dialog-title">
				<DialogContent>	
					<CreatePrice handleCloseCreatePrice={handleCloseCreatePrice} id_product={id_product} />
				</DialogContent>
			</Dialog>
			<Dialog open={deleteOpen.open} onClose={handleCloseDeletePrice} aria-labelledby="form-dialog-title">
				<DialogContent>	
					<DialogContentText>
						<Typography>
							Apakah anda yakin ingin menghapus harga ini?
						</Typography>
					</DialogContentText>
					<DialogActions>
					<Button onClick={handleCloseDeletePrice} color="primary">
						Cancel
					</Button>
					<Button onClick={() => doDeletePrice(deleteOpen.item)} color="primary">
						Hapus
					</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
		</Fragment>
}

const mapStateToProps = state => ({
	productPriceBuyback: state.productPriceBuyback
})

export default connect(mapStateToProps, { getProductPriceBuyback, deleteProductPriceBuyback })(ProducPriceBuyBack)