import React, { forwardRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Grid,
  Fab,
  Paper,
  IconButton,
  InputBase,
  Divider,
  Hidden,
  Box,
} from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import { Link as RouterLink } from 'react-router-dom';
import * as actions from '../../actions';

// components
import ListProduct from './ListProduct';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  btnAddCustomer: {
    backgroundColor: '#0277BD',
    '&:hover': {
      backgroundColor: '#2092D6',
    },
    color: '#FFFFFF',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    // marginTop: theme.spacing(2)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(2),
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const Branch = ({ onExportProduct, loadingExport }) => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const handleSearchChange = (event) => {
    // const target = event.target.name;
    const { value } = event.target;
    event.persist();

    setName(value);
  };

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant='h4'>Manajemen Produk</Typography>
          </Grid>
        </Grid>
      </div>
      <div className={classes.row}>
        <Grid container justify='space-between'>
          <Box display={'flex'}>
            <Hidden only={['xs', 'sm']}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Fab
                  variant='extended'
                  className={classes.btnAddCustomer}
                  component={CustomRouterLink}
                  to='/product/create'
                >
                  <AddCircle className={classes.extendedIcon} />
                  Tambah Produk
                </Fab>
              </Grid>
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
              <Fab
                color='primary'
                aria-label='add'
                className={classes.fab}
                component={CustomRouterLink}
                to='/product/create'
              >
                <AddCircle />
              </Fab>
            </Hidden>
            <Fab
              variant='extended'
              className={classes.btnAddCustomer}
              style={{ whiteSpace: 'nowrap', marginLeft: '10px' }}
              onClick={onExportProduct}
            >
              {loadingExport ? 'Loading...' : 'Download Produk'}
            </Fab>
          </Box>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Paper component='form' className={classes.searchRoot}>
              <InputBase
                className={classes.input}
                name='name'
                placeholder='Cari Product'
                inputProps={{ 'aria-label': 'Cari Product' }}
                defaultValue={name}
                onChange={handleSearchChange}
              />
              <Divider className={classes.divider} orientation='vertical' />
              <IconButton
                type='submit'
                className={classes.iconButton}
                aria-label='search'
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <div className={classes.row}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ListProduct name={name} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loadingExport: state.product.loadingExport,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onExportProduct: () => dispatch(actions.exportProduct()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Branch);
