import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Login } from './layouts';

import {
  Dashboard as DashboardView,
  Branch as BranchView,
  CreateBranch as CreateBranchView,
  EditBranch as EditBranchView,
  PurchaseOrderV2 as PurchaseOrderV2View,
  DetailPurchaseOrder as DetailPurchaseOrderView,
  PurchaseOrderDetail as PurchaseOrderDetailView,
  Product as ProductView,
  CreateProduct as CreateProductView,
  EditProduct as EditProductView,
  User as UserView,
  CreateUser as CreateUserView,
  ProductPrice as ProductPriceView,
  ResetStockComponent as ResetStockView,
  Round as RoundView,
  QuotaProduct as QuotaProductView,
  ViewQuotaProduct as ViewQuotaProductView,
  CreateQuotaProduct as CreateQuotaProductView,
  EditQuotaProduct as EditQuotaProductView,
  BranchPurchaseQuota as BranchPurchaseQuotaView,
  GetBranchPurchaseQuota as GetBranchPurchaseQuotaView,
  Whatsapp as WhatsappView, UpdateUser
} from './views';

const Routes = () => {
  
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />

      <RouteWithLayout
        component={BranchView}
        exact
        layout={MainLayout}
        path="/branch"
      />

      <RouteWithLayout
        component={CreateBranchView}
        exact
        layout={MainLayout}
        path="/branch/create"
      />

      <RouteWithLayout
        component={EditBranchView}
        exact
        layout={MainLayout}
        path="/branch/edit/:id"
      />

      <RouteWithLayout
        component={PurchaseOrderV2View}
        exact
        layout={MainLayout}
        path="/purchase-order-v2"
      />

      <RouteWithLayout
        component={DetailPurchaseOrderView}
        exact
        layout={MainLayout}
        path="/purchase-order-v2/:id"
      />

      <RouteWithLayout
        component={PurchaseOrderDetailView}
        exact
        layout={MainLayout}
        path="/purchase-order/detail/:id"
      />

      <RouteWithLayout
        component={ProductView}
        exact
        layout={MainLayout}
        path="/product"
      />

      <RouteWithLayout
        component={CreateProductView}
        exact
        layout={MainLayout}
        path="/product/create"
      />

      <RouteWithLayout
        component={EditProductView}
        exact
        layout={MainLayout}
        path="/product/edit/:id"
      />

      <RouteWithLayout
        component={UserView}
        exact
        layout={MainLayout}
        path="/user"
      />

      <RouteWithLayout
        component={CreateUserView}
        exact
        layout={MainLayout}
        path="/user/create"
      />

      <RouteWithLayout
        component={UpdateUser}
        exact
        layout={MainLayout}
        path="/user/:id"
      />

      <RouteWithLayout
        component={ProductPriceView}
        exact
        layout={MainLayout}
        path="/product-price"
      />

      <RouteWithLayout
        component={RoundView}
        exact
        layout={MainLayout}
        path="/round"
      />

      <RouteWithLayout
        component={QuotaProductView}
        exact
        layout={MainLayout}
        path="/quota-product"
      />

      <RouteWithLayout
        component={CreateQuotaProductView}
        exact
        layout={MainLayout}
        path="/quota-product/create"
      />

      <RouteWithLayout
        component={EditQuotaProductView}
        exact
        layout={MainLayout}
        path="/quota-product/:id/edit"
      />

      <RouteWithLayout
        component={ViewQuotaProductView}
        exact
        layout={MainLayout}
        path="/quota-product/:id"
      />

      <RouteWithLayout
        component={BranchPurchaseQuotaView}
        exact
        layout={MainLayout}
        path="/branch-purchase-quota"
      />

      <RouteWithLayout
        component={GetBranchPurchaseQuotaView}
        exact
        layout={MainLayout}
        path="/branch-purchase-quota/:id"
      />

      <RouteWithLayout
        component={ResetStockView}
        exact
        layout={MainLayout}
        path="/reset-stok-cabang"
      />

      <RouteWithLayout
        component={WhatsappView}
        exact
        layout={MainLayout}
        path="/whatsapp"
      />

      <Route
        component={Login}
        exact
        // layout={Login}
        path="/sign-in"
      />
      {/* <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      /> */}

      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
