import React,{Fragment} from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {CardHeader, Grid} from "@material-ui/core";

const Variable = props => {
  const {classes} = props;
  return (
    <Fragment>
      <Card>
        <CardHeader
          title={'Variable'}
          style={{fontWeight: 'bold'}}
        >
        </CardHeader>
        <hr/>
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            <Grid container spacing={2} className={classes.row}>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                {'{numberInvoice}'}
              </Grid>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                Nomor Invoice
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.row}>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                {'{orderDate}'}
              </Grid>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                Tanggal Order
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.row}>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                {'{orderName}'}
              </Grid>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                Nama Pemesan
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.row}>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                {'{branch}'}
              </Grid>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                Cabang
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.row}>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                {'{totalPayment}'}
              </Grid>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                Total Bayar
              </Grid>
            </Grid>
          </Typography>
        </CardContent>
      </Card>

    </Fragment>
  );
};

export default Variable;