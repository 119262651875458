import * as actionTypes from './types';
import axios from '../axios-orders';
import { setAlert } from './alert'

export const fetchBranchPurchaseQuotasStart = () => {
  return {
    type: actionTypes.FETCH_BRANCH_PURCHASE_QUOTAS_START
  }
}

export const fetchBranchPurchaseQuotasSuccess = (branchPurchaseQuotas) => {
  return {
    type: actionTypes.FETCH_BRANCH_PURCHASE_QUOTAS_SUCCESS,
    branchPurchaseQuotas: branchPurchaseQuotas
  }
}

export const fetchBranchPurchaseQuotasFail = (error) => {
  return {
    type: actionTypes.FETCH_BRANCH_PURCHASE_QUOTAS_FAIL,
    error: error
  }
}

export const fetchBranchPurchaseQuotas = (page, formSearch) => {
  return dispatch => {
    let query = `&search=${formSearch.search}`;
    dispatch(fetchBranchPurchaseQuotasStart())
    axios.get(`admin/quota/product/branch?page=${page}&page_size=10${query}`,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(fetchBranchPurchaseQuotasSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchBranchPurchaseQuotasFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const getBranchPurchaseQuotaStart = () => {
  return {
    type: actionTypes.GET_BRANCH_PURCHASE_QUOTA_START
  }
}

export const getBranchPurchaseQuotaSuccess = (branchPurchaseQuota) => {
  return {
    type: actionTypes.GET_BRANCH_PURCHASE_QUOTA_SUCCESS,
    branchPurchaseQuota: branchPurchaseQuota
  }
}

export const getBranchPurchaseQuotaFail = (error) => {
  return {
    type: actionTypes.GET_BRANCH_PURCHASE_QUOTA_FAIL,
    error: error
  }
}

export const getBranchPurchaseQuota = (id) => {
  return dispatch => {
    dispatch(getBranchPurchaseQuotaStart())
    axios.get(`admin/quota/product/branch/${id}`,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(getBranchPurchaseQuotaSuccess(res.data.data))
      })
      .catch(err => {
        dispatch(getBranchPurchaseQuotaFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const storeBranchPurchaseQuotaStart = () => {
  return {
    type: actionTypes.STORE_BRANCH_PURCHASE_QUOTA_START
  }
}

export const storeBranchPurchaseQuotaSuccess = () => {
  return {
    type: actionTypes.STORE_BRANCH_PURCHASE_QUOTA_SUCCESS,
  }
}

export const storeBranchPurchaseQuotaFail = (error) => {
  return {
    type: actionTypes.STORE_BRANCH_PURCHASE_QUOTA_FAIL,
    error: error
  }
}

export const storeBranchPurchaseQuota = (storeData) => {
  return dispatch => {
    dispatch(storeBranchPurchaseQuotaStart())
    axios.post(`admin/quota/product/branch`, storeData,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(storeBranchPurchaseQuotaSuccess())
        dispatch(setAlert(res.data.message, "success"))
        // history.push('/branch-purchase-quota')
      })
      .catch(err => {
        dispatch(storeBranchPurchaseQuotaFail(err.response.data.errors))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const clearErrorBranchPurchaseQuota = () => {
  return {
    type: actionTypes.CLEAR_ERROR_BRANCH_PURCHASE_QUOTA
  }
}