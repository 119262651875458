import React,{Fragment} from "react";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Edit} from "@material-ui/icons";
import TableRow from "@material-ui/core/TableRow";
import {Avatar} from "@material-ui/core";

const BranchPurchaseQuotaData = props => {
  const {itemData, index,edited} = props;

  return (
    <Fragment>
      <TableRow key={itemData.id}>
        <TableCell>
          {index+1}
        </TableCell>
        <TableCell>
          {<Avatar src={itemData.product ? itemData.product.image : ''} alt={"image"}></Avatar>}
        </TableCell>
        <TableCell>
          {itemData.product ? (itemData.product.name+' '+itemData.product.weight+' '+itemData.product.unit) : ''}
        </TableCell>
        <TableCell>
          {itemData.qty}
        </TableCell>
        <TableCell>
          {itemData.qty_left}
        </TableCell>
        <TableCell>
          <Tooltip title="Edit Pembelian Kuota Cabang">
            <IconButton aria-label="deleted" onClick={() => edited(itemData)}>
              <Edit />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default BranchPurchaseQuotaData;