import React,{Fragment} from "react";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Search} from "@material-ui/icons";
import TableRow from "@material-ui/core/TableRow";

const BranchPurchaseQuotaData = props => {
  const {branchPurchaseQuota,index,detailed} = props;

  return (
    <Fragment>
      <TableRow key={branchPurchaseQuota.id}>
        <TableCell>
          {index+1}
        </TableCell>
        <TableCell>
          {branchPurchaseQuota.updated_at}
        </TableCell>
        <TableCell>
          {branchPurchaseQuota.branch ? branchPurchaseQuota.branch.name : ''}
        </TableCell>
        <TableCell>
          {branchPurchaseQuota.admin ? branchPurchaseQuota.admin.name : ''}
        </TableCell>
        <TableCell>
          <Tooltip title="Detail Pembelian Kuota Cabang">
            <IconButton aria-label="deleted" onClick={() => detailed(branchPurchaseQuota)}>
              <Search />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default BranchPurchaseQuotaData;