import React, { useState, Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import { 
    Grid, 
    Typography, 
    Card, 
    CardContent, 
    TextField, 
    CardActions, 
    Button, 
    Paper, 
    IconButton, 
    Divider,
    InputBase,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText
} from '@material-ui/core'
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers"
import SchemaValidation from './validation'
import { useHistory } from 'react-router-dom'

import { connect } from 'react-redux'
import { addBranch } from '../../../actions/branch'
import { getVillage } from '../../../actions/village'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1)
    },
    row: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    btn: {
        backgroundColor: '#011747',
        color: '#FFF'
    },
    backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
    },
    searchRoot: {
		padding: '0px 4px',
        display: 'flex',
        alignItems: 'center',
		width: 'auto',
		// marginTop: theme.spacing(2)
	},
	input: {
        marginLeft: theme.spacing(1),
            flex: 1,
        },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 24,
        margin: 4,
    },
}))

const CreateBranch = ({ addBranch, getVillage, village : { villages, loading } }) => {
    const classes = useStyles()
    const history = useHistory()
    const { register, handleSubmit, errors } = useForm({
		resolver: yupResolver(SchemaValidation)
    });
    
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = event => {
        // console.log(event.target.value);
        event.persist();
    
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [event.target.name]: event.target.value
          }
        }));
    };

    const onSubmit = e => {
        addBranch(formState.values, history)
    }

    const handleSearchAddress = () => {
        getVillage(formState.values.kode_pos)
    }

    return (
        <Fragment>
            <div className={classes.root}>
                <div className={classes.row}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Typography variant="h4">Buat Cabang Baru</Typography>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.row}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid
                                item
                                lg={8}
                                md={8}
                                sm={12}
                                xs={12}
                            >
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Paper component="form" className={classes.searchRoot}>
                                                    <InputBase
                                                        className={classes.input}
                                                        defaultValue={formState.values.kode_pos || ''}
                                                        name="kode_pos"
                                                        onChange={handleChange}
                                                        placeholder="Masukan Kode Pos Anda"
                                                        inputProps={{ 'aria-label': 'Cari Kode Pos' }}
                                                    />
                                                    <Divider className={classes.divider} orientation="vertical" />
                                                    <IconButton onClick={handleSearchAddress} className={classes.iconButton} aria-label="search">
                                                        <SearchIcon />
                                                    </IconButton>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    defaultValue={formState.values.name || ''}
                                                    label="Nama Cabang"
                                                    margin="dense"
                                                    name="name"
                                                    onChange={handleChange}
                                                    helperText={
                                                        errors.name && errors.name.message
                                                    }
                                                    error={errors.name && true}
                                                    inputRef={register}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    defaultValue={formState.values.phone || ''}
                                                    label="Nomor Telepon"
                                                    margin="dense"
                                                    name="phone"
                                                    onChange={handleChange}
                                                    helperText={
                                                        errors.phone && errors.phone.message
                                                    }
                                                    error={errors.phone && true}
                                                    inputRef={register}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    rows={4}
                                                    multiline={true}
                                                    defaultValue={formState.values.address || ''}
                                                    label="Alamat Lengkap"
                                                    margin="dense"
                                                    name="address"
                                                    onChange={handleChange}
                                                    helperText={
                                                        errors.address && errors.address.message
                                                    }
                                                    error={errors.address && true}
                                                    inputRef={register}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        {villages !== null ? (
                                            <Button type="submit" size="medium" variant="contained" className={classes.btn}>
                                                Simpan
                                            </Button>
                                        ):(
                                            <Button type="button" onClick={handleClickOpen} size="medium" variant="contained" className={classes.btn}>
                                                Simpan
                                            </Button>
                                        )}
                                    </CardActions>
                                </Card>
                            </Grid>
                            <Grid
                                item
                                lg={4}
                                md={4}
                                sm={12}
                                xs={12}
                            >
                                {villages !== null && (
                                    <Card>
                                        <CardContent>
                                            <TextField
                                                fullWidth
                                                disabled
                                                variant="outlined"
                                                value={villages !== null ? villages.province.name : ''}
                                                label="Provinsi"
                                                margin="dense"
                                                name="province"
                                                onChange={handleChange}
                                                helperText={
                                                    errors.province && errors.province.message
                                                }
                                                error={errors.province && true}
                                                inputRef={register}
                                            />
                                            <TextField
                                                fullWidth
                                                disabled
                                                variant="outlined"
                                                value={villages !== null ? villages.regency.name : ''}
                                                label="Kota/Kabupaten"
                                                margin="dense"
                                                name="city"
                                                onChange={handleChange}
                                                helperText={
                                                    errors.city && errors.city.message
                                                }
                                                error={errors.city && true}
                                                inputRef={register}
                                            />
                                            <TextField
                                                fullWidth
                                                disabled
                                                variant="outlined"
                                                value={villages !== null ? villages.district.name : ''}
                                                label="Kecamatan"
                                                margin="dense"
                                                name="district"
                                                onChange={handleChange}
                                                helperText={
                                                    errors.district && errors.district.message
                                                }
                                                error={errors.district && true}
                                                inputRef={register}
                                            />
                                            <TextField
                                                fullWidth
                                                disabled
                                                variant="outlined"
                                                value={villages !== null ? villages.village.name : ''}
                                                label="Kelurahan"
                                                margin="dense"
                                                name="village"
                                                onChange={handleChange}
                                                helperText={
                                                    errors.village && errors.village.message
                                                }
                                                error={errors.village && true}
                                                inputRef={register}
                                            />
                                        </CardContent>
                                    </Card>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Pemberitahuan"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Anda harus mencari alamat Cabang anda dengan menggunakan kode pos pada kolom "Masukan Kode Pos Anda".
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                    Setuju
                </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    village : state.village
})

export default connect(mapStateToProps, { addBranch, getVillage })(CreateBranch)