import React, {Fragment, useEffect, useState} from "react";
import {Add as AddIcon, Search} from "@material-ui/icons";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  DialogActions,
  useTheme,
  useMediaQuery,
  Paper,
  IconButton,
  Divider,
  InputBase,
  Table,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TablePagination,
  TableFooter, TableBody
} from "@material-ui/core";

import {Loading, TablePaginationActions} from '../../../components/UI';

import { connect } from 'react-redux'
import * as actions from '../../../actions';
import {isEmpty} from "../../../shared/utility";
// import { getBranchSearch } from '../../../actions/branch'

const columns = [
  { id: 'no', label: 'No', minWidth: 10 },
  { id: 'nama_cabang', label: 'Nama Cabang', minWidth: 280 },
  { id: 'action', label: 'Action', minWidth: 10 },
];

const SearchBranch = props => {
  const {classes, onSearchBranch, branches, loading, onStoreResetBranch} = props;
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [ keyword, setKeyword ] = useState({
    kata_kunci: '',
    status: '1'
  });
  const handleChangeSearch = event => {
    event.persist();
    setKeyword((keyword) => ({
      ...keyword,
      [event.target.name]: event.target.value
    }))
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      onSearchBranch(keyword.kata_kunci, keyword.status, 1);
    }, 1000)

    return () => clearTimeout(timer)
  },[onSearchBranch,keyword]);

  const handleChangePage = (event, newPage) => {
    onSearchBranch(keyword.kata_kunci, keyword.status, newPage);
  }

  let tableBody = '';

  const selectBranch = id => {
    onStoreResetBranch(id)
    setOpen(false)
  }

  if(!isEmpty(branches)){
    const page = branches.current_page - 1;
    const rowsPerPage = branches.per_page;
    const countRows = branches.total
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, countRows - page * rowsPerPage)
    // console.log(page, rowsPerPage, countRows, emptyRows);

    tableBody = (
      <Fragment>
        <TableBody>
          {branches.data.map((branch, index) => (
            <TableRow key={branch.id}>
              <TableCell>
                {index+1}
              </TableCell>
              <TableCell>
                {branch.name}
              </TableCell>
              <TableCell>
                <Button
                  variant={"contained"}
                  size={"small"}
                  className={classes.btnSelect}
                  onClick={() => selectBranch(branch.id)}
                >
                  Pilih
                </Button>
                {/*{index+1}*/}
              </TableCell>
            </TableRow>
          ))}

        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              colSpan={8}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    )
  }


  return (loading ? <Loading/>:
    <Fragment>
      <Button variant={"contained"} size={"medium"} startIcon={React.cloneElement(<AddIcon/>)} className={classes.addButton} onClick={handleClickOpen}>Reset Stock</Button>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Pilih Cabang"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <form >
                  <div>
                    <Paper component="form" className={classes.searchRoot}>
                      <InputBase
                        autoFocus
                        className={classes.input}
                        name="kata_kunci"
                        value={keyword.kata_kunci || ''}
                        onChange={handleChangeSearch}
                        // inputRef={register}
                      />
                      <Divider className={classes.divider} orientation="vertical" />
                      <IconButton type="submit" className={classes.iconButton} aria-label="search">
                        <Search />
                      </IconButton>
                      {/*<Divider className={classes.divider} orientation="vertical" />*/}
                    </Paper>
                  </div>
                </form>

              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <Typography>Hasil Pencarian</Typography>
                <div className={classes.row}>
                   {/*{customers_v2 !== null && ( */}
                  <Paper className={classes.root}>
                    <TableContainer className={classes.container}>
                      <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        {tableBody}
                      </Table>
                    </TableContainer>
                  </Paper>
                </div>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    branches: state.branch.branchSearch,
    loading: state.branch.loadingSearch
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSearchBranch: (keyword, status, page) => dispatch(actions.getBranchSearch(keyword, status, page)),
    onStoreResetBranch: (id) => dispatch(actions.storeResetStockCabang(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBranch);