import React, { useEffect, Fragment, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import {
    Card,
    CardContent,
    CardActions,
    Grid,
    Typography,
    Box,
    Button
} from '@material-ui/core'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import moment from 'moment'
import CapitalizedText from '../../../components/layout/CapitalizedText'

// Redux
import { connect } from 'react-redux'
import { 
    getPurchaseOrderDetail, 
    updatePurchaseOrderStatus
} from '../../../actions/purchaseOrder'
import NumberFormat from 'react-number-format'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1)
    },
    row: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    btn: {
        backgroundColor: '#FF9300',
        color : '#FFFFFF',
        // marginTop: theme.spacing(1)
    },
    backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
    },
    totalPrice: {
        color: '#FF9300',
        fontSize: 30
    }
}))

const columns = [
    { id: 'no', label: 'No', minWidth: 30 },
    { id: 'item', label: 'Item Produk', minWidth: 100 },
    { id: 'stok', label: 'Stok Tersedia', minWidth: 70 },
    { id: 'qty', label: 'Jumlah Order', minWidth: 80 },
    { id: 'hpp', label: 'Harga', minWidth: 100 },
    { id: 'total', label: 'Harga Total', minWidth: 100 },
  ];

const DetailPurchaseOrder = ({ 
    getPurchaseOrderDetail, 
    updatePurchaseOrderStatus,
    purchaseOrder: { purchaseOrderDetails, loading, counting },
}) => {
    const classes = useStyles()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const history = useHistory()
    var no = 1;
    
    const { id } = useParams()

    useEffect(() => {
        getPurchaseOrderDetail(id)
    }, [id, loading, getPurchaseOrderDetail, counting]);

	const handleChangePage = newPage => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
    };

    const onUpdateAccept = e => {
        updatePurchaseOrderStatus(id, history, '1')
    }

    const onUpdateDenied = e => {
        updatePurchaseOrderStatus(id, history, '2')
    }

    return loading || purchaseOrderDetails == null ? 
    <Backdrop className={classes.backdrop} open>
        <CircularProgress color="inherit" />
    </Backdrop>  
    :
    <Fragment>
        <div className={classes.root}>
            <div className={classes.row}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item>  
                        <Typography variant="h4">Purchase Order</Typography>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.row}>
                <Card>
                    <CardContent>
                        <Grid container justify="center">
                            <Grid item>
                                <img alt='logo' width='100' height='100' src={`${process.env.PUBLIC_URL}/images/logo/logo_eoa.png`} />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardContent>
                        <Grid container spacing={2} justify="space-between">
                            <Grid
                                item
                            >
                                <Typography variant="h4">Invoice</Typography>
                                <Typography variant="body1">
                                    <CapitalizedText text={purchaseOrderDetails.branch.name} />
                                </Typography>
                                <Typography variant="body1">
                                    <CapitalizedText text={purchaseOrderDetails.branch.address} />
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant="body1">Tanggal : {moment(purchaseOrderDetails.created_at).format('DD MMMM yyyy HH:mm')}</Typography>
                                <Typography variant="body1">Nama Admin : {purchaseOrderDetails.admin !== null ? purchaseOrderDetails.admin.name : '-'}</Typography>
                                <Typography variant="body1">No Invoice : {purchaseOrderDetails.inv_name}</Typography>
                                <Typography variant="body1">Status Invoice : {purchaseOrderDetails.status_name}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardContent>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
                        <TableHead>
                            <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                                >
                                {column.label}
                                </TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {purchaseOrderDetails.branch_po_detail.map((product) => (
                                <TableRow key={product.id}>
                                    <TableCell>
                                        {no++}
                                    </TableCell>
                                    <TableCell>
                                        <CapitalizedText text={product.product.name} /> {product.product.weight} <CapitalizedText text={product.product.unit} />
                                    </TableCell>
                                    <TableCell>
                                        {product.in_stock}
                                    </TableCell>
                                    <TableCell>
                                        {product.qty}
                                    </TableCell>
                                    <TableCell>
                                        <NumberFormat value={product.sell_price} displayType={'text'} thousandSeparator={true} prefix={`RP `} />
                                    </TableCell>
                                    <TableCell>
                                        <NumberFormat value={product.sell_price * product.qty} displayType={'text'} thousandSeparator={true} prefix={`RP `} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={!loading && purchaseOrderDetails.branch_po_detail.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <Box display="flex" flexDirection="row-reverse" p={1} m={1}>
                            <Box p={1}>
                                <Typography className={classes.totalPrice}>
                                    <NumberFormat value={purchaseOrderDetails.total_price_invoice} displayType={'text'} thousandSeparator={true} prefix={`RP `} />
                                </Typography>
                            </Box>
                            <Box p={1}>
                                <Typography variant="h3">
                                    Total :
                                </Typography>
                            </Box>
                        </Box>
                        {purchaseOrderDetails.status === '0' && (
                            <Box display="flex" flexDirection="row-reverse" p={1} m={1}>
                                <Box p={1}>
                                    <Button variant="contained" className={classes.btn} onClick={onUpdateAccept}>
                                        Terima Pembelian
                                    </Button>
                                </Box>
                                <Box p={1}>
                                    <Button variant="outlined" onClick={onUpdateDenied}>
                                        Tolak Pembelian
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </CardContent>
                    {purchaseOrderDetails.status !== '0' && (
                        <CardActions>
                            <Grid container justify="center">
                                <Grid item>
                                    <Typography variant="h3">Terima Kasih</Typography>
                                </Grid>
                            </Grid>
                        </CardActions>
                    )}
                </Card>
            </div>
        </div>
    </Fragment>
    
}

DetailPurchaseOrder.propTypes = {
    getPurchaseOrderDetail: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    purchaseOrder: state.purchaseOrder
})

export default connect(mapStateToProps, { getPurchaseOrderDetail, updatePurchaseOrderStatus })(DetailPurchaseOrder)