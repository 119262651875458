import React,{Fragment} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Delete, Edit} from "@material-ui/icons";
import moment from "moment";

const RoundData = props => {
  const {round, index, edited, deleted} = props;

  return (
    <Fragment>
      <TableRow key={round.id}>
        <TableCell>
          {index+1}
        </TableCell>
        <TableCell>
          {round.updated_at}
        </TableCell>
        <TableCell>
          {round.name}
        </TableCell>
        <TableCell>
          {moment.tz(round.open,'Asia/Jakarta').format('DD MMM YYYY HH:mm z')}
        </TableCell>
        <TableCell>
          {moment.tz(round.close,'Asia/Jakarta').format('DD MMM YYYY HH:mm z')}
        </TableCell>
        <TableCell>
          {round.admin ? round.admin.name : ''}
        </TableCell>
        <TableCell>
          <Tooltip title="Edit Ronde">
            <IconButton aria-label="edited" onClick={() => edited(round)}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Hapus Ronde">
            <IconButton aria-label="deleted" onClick={() => deleted(round)}>
              <Delete />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default RoundData;