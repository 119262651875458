import React,{Fragment} from "react";
import {Button, Grid} from "@material-ui/core";
import {isEmpty} from "../../../../../../shared/utility";

const SentOrder = props => {
  const {
    purchaseOrder,
    classes,
    orderConfirmationSent,
    // proccess
  } = props;

  let button = '';
  if (!isEmpty(purchaseOrder)){
    if(purchaseOrder.status === "4"){
      button = (
        <Fragment>
          <Grid item>
            <Button
              className={classes.btnPurple}
              size={"medium"}
              variant={"contained"}
              onClick={() => orderConfirmationSent({})}
            >
              Kirim
            </Button>
          </Grid>
          {/*<Grid item>*/}
          {/*  <Button*/}
          {/*    className={classes.btnError}*/}
          {/*    size={"medium"}*/}
          {/*    variant={"contained"}*/}
          {/*    onClick={() => proccess({'status': '0'})}*/}
          {/*  >*/}
          {/*    Tolak*/}
          {/*  </Button>*/}
          {/*</Grid>*/}
        </Fragment>
      );
    };
  };

  return (
    <Fragment>
      <Grid container spacing={2} justify={"flex-end"}>
        {button}
      </Grid>
    </Fragment>
  );
};

export default SentOrder;