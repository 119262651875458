import * as yup from "yup";

const schema = yup.object().shape({
	name: yup.string().required("Nama tidak boleh kosong"),
	email: yup.string().required("Email tidak boleh kosong"),
	password: yup.string().required("Password tidak boleh kosong"),
	id_role: yup.string().required("Jabatan tidak boleh kosong"),
	id_branch: yup.string().required("Cabang tidak boleh kosong"),
	is_active: yup.boolean().required("Status tidak boleh kosong"),
});

export default schema;