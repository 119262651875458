import React,{Fragment} from "react";
import TableCell from "@material-ui/core/TableCell";
import {Avatar} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import TableRow from "@material-ui/core/TableRow";

const ProductData = props => {
  const {product, index, handleQuotaChange, quota,register,errors} = props;
  return (
    <Fragment>
      <TableRow key={product.id_product}>
        <TableCell>
          {index+1}
        </TableCell>
        <TableCell>
          <Avatar alt="Remy Sharp" src={product.product_image} />
        </TableCell>
        <TableCell>
          {product.product_name+' '+product.product_weight+' '+product.product_unit}
        </TableCell>
        <TableCell>
          <TextField
            fullWidth
            name="qty"
            type="number"
            variant="outlined"
            size={"small"}
            inputRef={register}
            helperText={
              errors.qty && errors.qty.message
            }
            InputProps={{ inputProps: { min: 0 } }}
            error={errors.qty && true}
            onChange={e => handleQuotaChange(e,product,index)}
            placeholder={0}
            defaultValue={quota[index].qty || ''}
          />
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default ProductData;