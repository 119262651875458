
import axios from 'axios'
import axiosOrders from '../axios-orders';
import { setAlert } from './alert'
import { 
    GET_PURCHASE_ORDER, GET_PURCHASE_ORDER_DETAIL, UPDATE_PURCHASE_ORDER_STATUS
} from './types'
import * as actionTypes from './types';
import fileDownload from 'js-file-download';

export const getPurchaseOrder = (keyword, status) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/purchase_order/filter?type=name&kata_kunci=${keyword}&status=${status}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_PURCHASE_ORDER,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getPurchaseOrderDetail = (id) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/purchase_order/${id}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_PURCHASE_ORDER_DETAIL,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const updatePurchaseOrderStatus = (id, history, status) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/purchase_order/${id}`
    const token = sessionStorage.getItem('access_token')

    const myData = {
        status: status
    }

    try {
        const res = await axios({
            url: endpoint,
            method: "PATCH",
            data: myData,
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: UPDATE_PURCHASE_ORDER_STATUS,
            payload: res.data
        })

        if(status === '1'){
            dispatch(setAlert("Invoice accepted", "success"))
        }else{
            dispatch(setAlert("Invoice Denied", "error"))
        }
        history.push(`/purchase-order`);

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const fetchPurchaseOrdersStart = () => {
    return {
        type: actionTypes.FETCH_PURCHASE_ORDERS_START
    }
}

export const fetchPurchaseOrdersSuccess = (purchase_orders) => {
    return {
        type: actionTypes.FETCH_PURCHASE_ORDERS_SUCCESS,
        purchase_orders: purchase_orders
    }
}

export const fetchPurchaseOrdersFail = (error) => {
    return {
        type: actionTypes.FETCH_PURCHASE_ORDERS_FAIL,
        error: error
    }
}

export const fetchPurchaseOrders = (page, formSearch) => {
    let param = `&start_date=${formSearch.start_date}&end_date=${formSearch.end_date}&status=${formSearch.status}&search=${formSearch.search}&round=${formSearch.round_id}`;
    return dispatch => {
        dispatch(fetchPurchaseOrdersStart())
        axiosOrders.get(`admin/purchase_order/fetch?page=${page}&page_size=10`+param,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
            }
        })
          .then(res => {
              dispatch(fetchPurchaseOrdersSuccess(res.data))
          })
          .catch(err => {
              dispatch(fetchPurchaseOrdersFail(err.response.data.message))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}

export const getPurchaseOrderStart = () => {
    return {
        type: actionTypes.GET_PURCHASE_ORDER_START
    }
}

export const getPurchaseOrderSuccess = (purchase_order) => {
    return {
        type: actionTypes.GET_PURCHASE_ORDER_SUCCESS,
        purchase_order: purchase_order
    }
}

export const getPurchaseOrderFail = (error) => {
    return {
        type: actionTypes.GET_PURCHASE_ORDER_FAIL,
        error: error
    }
}

export const getPurchaseOrderV2 = (id) => {
    return dispatch => {
        dispatch(getPurchaseOrderStart())
        axiosOrders.get(`admin/purchase_order/fetch/${id}`,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
            }
        })
          .then(res => {
              dispatch(getPurchaseOrderSuccess(res.data.data))
          })
          .catch(err => {
              dispatch(getPurchaseOrderFail(err.response.data.message))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}

export const approvePurchaseOrderStart = () => {
    return {
        type: actionTypes.APPROVE_PURCHASE_ORDER_START
    }
}

export const approvePurchaseOrderSuccess = () => {
    return {
        type: actionTypes.APPROVE_PURCHASE_ORDER_SUCCESS
    }
}

export const approvePurchaseOrderFail = (error) => {
    return {
        type: actionTypes.APPROVE_PURCHASE_ORDER_FAIL,
        error: error
    }
}

export const approvePurchaseOrder = (id,storeData) => {
    return dispatch => {
        dispatch(approvePurchaseOrderStart())
        axiosOrders.patch(`admin/purchase_order/approval/${id}`, storeData,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
            }
        })
          .then(res => {
              dispatch(setAlert(res.data.message, "success"))
              dispatch(approvePurchaseOrderSuccess())
          })
          .catch(err => {
              dispatch(approvePurchaseOrderFail(err.response.data.message))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}

export const sentPurchaseOrderStart = () => {
    return {
        type: actionTypes.SENT_PURCHASE_ORDER_START
    }
}

export const sentPurchaseOrderSuccess = () => {
    return {
        type: actionTypes.SENT_PURCHASE_ORDER_SUCCESS
    }
}

export const sentPurchaseOrderFail = (error) => {
    return {
        type: actionTypes.SENT_PURCHASE_ORDER_FAIL,
        error: error
    }
}

export const sentPurchaseOrder = (id, storeData) => {
    return dispatch => {
        dispatch(sentPurchaseOrderStart())
        axiosOrders.patch(`admin/purchase_order/sent/${id}`, storeData,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
            }
        })
          .then(res => {
              dispatch(setAlert(res.data.message, "success"))
              dispatch(sentPurchaseOrderSuccess())
          })
          .catch(err => {
              dispatch(sentPurchaseOrderFail(err.response.data.message))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}

export const downloadPurchaseOrdersStart = () => {
    return {
        type: actionTypes.DOWNLOAD_PURCHASE_ORDERS_START
    }
}

export const downloadPurchaseOrdersSuccess = () => {
    return {
        type: actionTypes.DOWNLOAD_PURCHASE_ORDERS_SUCCESS
    }
}

export const downloadPurchaseOrdersFail = (error) => {
    return {
        type: actionTypes.DOWNLOAD_PURCHASE_ORDERS_FAIL,
        error: error
    }
}

export const downloadPurchaseOrders = (formSearch) => {
    let param = `start=${formSearch.start_date}&end=${formSearch.end_date}&status=${formSearch.status}&search=${formSearch.search}&round_id=${formSearch.round_id}`;
    return dispatch => {
        dispatch(downloadPurchaseOrdersStart())
        axiosOrders.get(`admin/purchase_order/export?`+param,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            },
            responseType: 'blob',
        })
          .then(res => {
              fileDownload(res.data, `download-po.xlsx`)
              dispatch(downloadPurchaseOrdersSuccess(res.data))
              dispatch(setAlert('Download Finished', "success"))
          })
          .catch(err => {
              dispatch(downloadPurchaseOrdersFail(err.response.data.message))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}

export const downloadPurchaseOrdersOldStart = () => {
    return {
        type: actionTypes.DOWNLOAD_PURCHASE_ORDERS_OLD_START
    }
}

export const downloadPurchaseOrdersOldSuccess = () => {
    return {
        type: actionTypes.DOWNLOAD_PURCHASE_ORDERS_OLD_SUCCESS
    }
}

export const downloadPurchaseOrdersOldFail = (error) => {
    return {
        type: actionTypes.DOWNLOAD_PURCHASE_ORDERS_OLD_FAIL,
        error: error
    }
}

export const downloadPurchaseOrdersOld = (formSearch) => {
    let param = `start=${formSearch.start_date}&end=${formSearch.end_date}&status=${formSearch.status}&search=${formSearch.search}&round_id=${formSearch.round_id}`;
    return dispatch => {
        dispatch(downloadPurchaseOrdersOldStart())
        axiosOrders.get(`admin/purchase_order/export/old?`+param,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            },
            responseType: 'blob',
        })
          .then(res => {
              fileDownload(res.data, `download-po.xlsx`)
              dispatch(downloadPurchaseOrdersOldSuccess(res.data))
              dispatch(setAlert('Download Finished', "success"))
          })
          .catch(err => {
              dispatch(downloadPurchaseOrdersOldFail(err.response.data.message))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}