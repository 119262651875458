import {
    GET_PRODUCT_PRICE_BUYBACK,
    ADD_PRODUCT_PRICE_BUYBACK,
    DELETE_PRODUCT_PRICE_BUYBACK
} from '../actions/types'

const initialState = {
    priceBuyback: {},
    pricingBuyback : null,
    loading: true,
    error: {},
    counting: 0
}

const reducer = function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_PRODUCT_PRICE_BUYBACK:
            return {
                ...state,
                pricingBuyback: payload,
                loading: false
            }
        case ADD_PRODUCT_PRICE_BUYBACK:
            return {
                ...state,
                priceBuyback: payload,
                loading: false,
                counting: initialState.counting += 1
            }
        case DELETE_PRODUCT_PRICE_BUYBACK:
            return {
                ...state,
                priceBuyback: payload,
                loading: false,
                counting: initialState.counting -= 1
            }
        default:
            return state
    }
}

export default reducer;