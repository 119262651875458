import * as actionTypes from '../actions/types'
import { updateObject } from '../shared/utility'

const initialState = {
  quotaProducts: {},
  quotaProduct: {},
  changing: 0,
  loading: false,
  error: {},
}

const fetchQuotaProductsStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const fetchQuotaProductsSuccess = (state, action) => {
  return updateObject(state, {
    quotaProducts: action.quotaProducts,
    loading: false
  })
}

const fetchQuotaProductsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const getQuotaProductStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const getQuotaProductSuccess = (state, action) => {
  return updateObject(state, {
    quotaProduct: action.quotaProduct,
    loading: false
  })
}

const getQuotaProductFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const storeQuotaProductStart = (state, action) => {
  return updateObject(state, {
    error: {},
    loading: true
  })
}

const storeQuotaProductSuccess = (state, action) => {
  // const newOrder = updateObject(action.storeData, { id: action.storeDataId })
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
}

const storeQuotaProductFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  })
}

const updateQuotaProductStart = (state, action) => {
  return updateObject(state, {
    error: {},
    loading: true
  })
}

const updateQuotaProductSuccess = (state, action) => {
  // const newOrder = updateObject(action.storeData, { id: action.storeDataId })
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
}

const updateQuotaProductFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  })
}

const deleteQuotaProductStart = (state, action) => {
  return updateObject(state, {
    error: {},
    loading: true
  })
}

const deleteQuotaProductSuccess = (state, action) => {
  // const newOrder = updateObject(action.storeData, { id: action.storeDataId })
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
}

const deleteQuotaProductFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  })
}

const clearErrorQuotaProduct = (state, action) => {
  return updateObject(state, {
    error: {},
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_QUOTA_PRODUCTS_START:
      return fetchQuotaProductsStart(state, action)
    case actionTypes.FETCH_QUOTA_PRODUCTS_SUCCESS:
      return fetchQuotaProductsSuccess(state, action)
    case actionTypes.FETCH_QUOTA_PRODUCTS_FAIL:
      return fetchQuotaProductsFail(state, action)
    case actionTypes.GET_QUOTA_PRODUCT_START:
      return getQuotaProductStart(state, action)
    case actionTypes.GET_QUOTA_PRODUCT_SUCCESS:
      return getQuotaProductSuccess(state, action)
    case actionTypes.GET_QUOTA_PRODUCT_FAIL:
      return getQuotaProductFail(state, action)
    case actionTypes.STORE_QUOTA_PRODUCT_START:
      return storeQuotaProductStart(state, action)
    case actionTypes.STORE_QUOTA_PRODUCT_SUCCESS:
      return storeQuotaProductSuccess(state, action)
    case actionTypes.STORE_QUOTA_PRODUCT_FAIL:
      return storeQuotaProductFail(state, action)
    case actionTypes.UPDATE_QUOTA_PRODUCT_START:
      return updateQuotaProductStart(state, action)
    case actionTypes.UPDATE_QUOTA_PRODUCT_SUCCESS:
      return updateQuotaProductSuccess(state, action)
    case actionTypes.UPDATE_QUOTA_PRODUCT_FAIL:
      return updateQuotaProductFail(state, action)
    case actionTypes.DELETE_QUOTA_PRODUCT_START:
      return deleteQuotaProductStart(state, action)
    case actionTypes.DELETE_QUOTA_PRODUCT_SUCCESS:
      return deleteQuotaProductSuccess(state, action)
    case actionTypes.DELETE_QUOTA_PRODUCT_FAIL:
      return deleteQuotaProductFail(state, action)
    case actionTypes.CLEAR_ERROR_QUOTA_PRODUCT:
      return clearErrorQuotaProduct(state, action)
    default: return state
  }
}

export default reducer