import React, {Fragment, useEffect, useState} from "react";
import {Grid, Paper, Typography} from "@material-ui/core";
import useStyles from "../styles/useStyles";
import TableBranchPuchaseQuota from "./TableBranchPuchaseQuota";
import {Modal} from "../../../components/UI";
import EditBranchPurchaseQuota from "./EditBranchPurchaseQuota";
import {connect} from "react-redux";

const GetBranchPurchaseQuota = props => {
  const classes = useStyles();
  const {changing} = props;
  const [Form, setForm] = useState('');
  const [modalState, setModalState] = useState({
    open: false,
    title: '',
    maxWidth: 'sm',
  });

  useEffect(() => {
    closedModalDialog()
  },[changing])

  const closedModalDialog = () => {
    setModalState({
      maxWidth: 'sm',
      title: '',
      open: false,
    });
    setForm('');
  }

  const edited = (data) => {
    console.log(data);
    setModalState({
      open: true,
      title: 'Ubah Batas Quota Pembelian',
      maxWidth: 'sm'
    });
    setForm(<EditBranchPurchaseQuota closedModalDialog={() => closedModalDialog()} classes={classes} branchPurchaseQuota={data}/>)

  }

  return (
    <Fragment>
      <div className={classes.root}>
        <Modal
          maxWidth={modalState.maxWidth}
          open={modalState.open}
          title={modalState.title}
          onCloseModal={closedModalDialog}
          contentModal={Form}
        />
        <div className={classes.row}>
          <Grid container spacing={2} justify={"space-between"}>
            <Grid item>
              <Typography variant={"h4"} className={classes.tagMenu}>
                Pengaturan Kuota Pembelian Cabang
              </Typography>
            </Grid>
            {/*<Grid item>*/}
            {/*  */}
            {/*</Grid>*/}
          </Grid>
        </div>

        <div className={classes.row}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper variant={"outlined"} className={classes.paperRoot}>
                <div className={classes.paperTag}>
                  <Typography variant={"h5"} className={classes.tagMenu}>
                    Detail Kuota Pembelian Cabang
                  </Typography>
                </div>
                <div>
                  <TableBranchPuchaseQuota classes={classes} edited={(data) => edited(data)}/>
                  {/*<ListRound edit={(data) => edit(data)} deleted={(data) => deleted(data)}/>*/}
                  {/*<ListResetStock/>*/}
                </div>

              </Paper>

            </Grid>
          </Grid>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    changing: state.branchPurchaseQuota.changing
  };
};

export default connect(mapStateToProps)(GetBranchPurchaseQuota);