import {makeStyles} from "@material-ui/core/styles";

const UseStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom:  theme.spacing(2)
  },
  tagMenu: {
    fontWeight: "bold",

  },
  addButton: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
    textTransform: "none",
    fontWeight: "bold",
    borderRadius: '60px',
    // padding: 'auto',
    '&:hover':{
      backgroundColor: theme.palette.warning.light,
    }
  },
  paperRoot: {
    padding: theme.spacing(3)
  },
  paperTag: {
    marginBottom: theme.spacing(3)
  },
  boxMarginRow: {
    margin: "auto auto 15px"
  },
  boxMarginColumn: {
    margin: "auto auto auto 10px"
  },
  submitButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
    textTransform: "none",
    fontWeight: "bold",
    margin: "auto auto auto 10px",
    // borderRadius: '60px',
    // padding: 'auto',
    '&:hover':{
      backgroundColor: theme.palette.success.light,
    }
  },
  draftButton: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
    textTransform: "none",
    fontWeight: "bold",
    margin: "auto auto auto 10px",
    // borderRadius: '60px',
    // padding: 'auto',
    '&:hover':{
      backgroundColor: theme.palette.warning.light,
    }
  },
  cancelButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    textTransform: "none",
    fontWeight: "bold",
    margin: "auto auto auto 10px",
    // borderRadius: '60px',
    // padding: 'auto',
    '&:hover':{
      borderColor: theme.palette.error.light,
      color: theme.palette.error.light,
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  chipSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
  },
  chipDraft: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white
  }
}))

export default UseStyles;