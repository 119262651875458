import {
    GET_PRODUCT_PRICE,
    ADD_PRODUCT_PRICE,
    UPDATE_PRODUCT_PRICE,
    DELETE_PRODUCT_PRICE
} from '../actions/types'

const initialState = {
    price: {},
    pricing : null,
    loading: true,
    error: {},
    counting: 0
}

const reducer = function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_PRODUCT_PRICE:
            return {
                ...state,
                pricing: payload,
                loading: false
            }
        case ADD_PRODUCT_PRICE:
            return {
                ...state,
                price: payload,
                loading: false,
                counting: initialState.counting += 1
            }
        case UPDATE_PRODUCT_PRICE:
            return {
                ...state,
                price: payload,
                loading: false,
                counting: initialState.counting += 1
            }
        case DELETE_PRODUCT_PRICE:
            return {
                ...state,
                price: payload,
                loading: false,
                counting: initialState.counting -= 1
            }
        default:
            return state
    }
}

export default reducer;