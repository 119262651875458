import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogActions from '@material-ui/core/DialogActions';
import NumberFormat from 'react-number-format'
import moment from 'moment'
import {
	Button, 
	// DialogContentText, 
	// Typography, 
	Card, 
	CardContent, 
	CardHeader, 
	// TextField, 
	// MenuItem
} from '@material-ui/core'

// Redux
import { connect } from 'react-redux'
import { getProductPrice, deleteProductPrice } from '../../../actions/productPrice'

import { 
	Box,
} from '@material-ui/core';

import CreatePrice from '../CreatePrice'
// import EditPrice from './EditPrice'

const columns = [
  { id: 'no', label: 'No', minWidth: 100 },
  { id: 'kategori', label: 'Kategori Harga', minWidth: 200 },
  { id: 'sell_price', label: 'Harga', minWidth: 200 },
  { id: 'tanggal', label: 'Tanggal Active', minWidth: 200 },
  
];

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	container: {
		maxHeight: 440,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	statusPrice: {
        width: '250px'
    }
}));

const ListProductPrice = (props) => {
	const { 
        getProductPrice, 
        productPrice : { pricing, loading, counting },
        id_product
    } = props
	const classes = useStyles();
	const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
	var no = 1

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	// Create Price
	const [createPriceOpen, setCreatePriceOpen] = React.useState(false);

	const handleClickOpen = () => {
		setCreatePriceOpen(true);
	};

	const handleCloseCreatePrice = () => {
		setCreatePriceOpen(false);
	};
	// End Create price

	const [ status ] = useState('active')

    // const handleChangeStatusPrice = event => {
    //     setStatus(event.target.value)
    // }    

	useEffect(() => {
		getProductPrice(id_product, status)
	}, [loading, getProductPrice, id_product, counting, status])

	return loading || pricing === null ? 
		<Backdrop className={classes.backdrop} open>
			<CircularProgress color="inherit" />
		</Backdrop> 
		: 
		<Fragment>
			<Card>
				<CardHeader 
					title="Daftar Harga Produk"
				/>
				<CardContent>
					<Paper className={classes.root}>
						<TableContainer className={classes.container}>
							<Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
							<TableHead>
								<TableRow>
								{columns.map((column) => (
									<TableCell
									key={column.id}
									align={column.align}
									style={{ minWidth: column.minWidth }}
									>
									{column.label}
									</TableCell>
								))}
								</TableRow>
							</TableHead>
							<TableBody>
								{pricing.data.map((item) => (
									<TableRow key={item.id}>
										<TableCell>
											{no++}
										</TableCell>
										<TableCell>
											{item.type_name}
										</TableCell>
										<TableCell>
											<NumberFormat value={item.sell_price} displayType={'text'} thousandSeparator={true} prefix={`RP `} />
										</TableCell>
										<TableCell>
											{moment(item.created_at).format('DD MMMM YYYY HH:mm')}
										</TableCell>
										{/* <TableCell>
											<Tooltip title="Edit Harga">
												<IconButton aria-label="detail" color="primary" onClick={() => handleClickOpenEditPrice(item)}>
													<EditIcon />
												</IconButton>
											</Tooltip>
											<Tooltip title="Delete Harga">
												<IconButton aria-label="detail" color="primary" onClick={() => handleClickOpenDeletePrice(item)}>
													<DeleteIcon />
												</IconButton>
											</Tooltip>
										</TableCell> */}
									</TableRow>
								))}
							</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[10, 25, 100]}
							component="div"
							count={!pricing === null || pricing.data.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onChangePage={handleChangePage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
						/>
						<Box display="flex" flexDirection="row-reverse" p={1} m={1}>
							<Button color="primary" variant="contained" onClick={handleClickOpen}>
								Tambah Harga Baru
							</Button>
						</Box>
					</Paper>
				</CardContent>
			</Card>
			
			<Dialog open={createPriceOpen} onClose={handleCloseCreatePrice} aria-labelledby="form-dialog-title">
				<DialogContent>	
					<CreatePrice handleCloseCreatePrice={handleCloseCreatePrice} id_product={id_product} />
				</DialogContent>
			</Dialog>
		</Fragment>
}

const mapStateToProps = state => ({
    productPrice: state.productPrice,
})

export default connect(mapStateToProps, { getProductPrice, deleteProductPrice })(ListProductPrice)