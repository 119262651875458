import React, {Fragment, useState} from "react";
import {Box, Button, Paper, TextField} from "@material-ui/core";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import * as yup from "yup";
import {useParams} from 'react-router-dom';

import * as actions from '../../../../actions';
import {connect} from "react-redux";

const schema = yup.object().shape({
  qty: yup.string().required("Quantity tidak boleh kosong"),
});

const EditBranchPurchaseQuota = props => {
  const {classes,closedModalDialog,branchPurchaseQuota,onStoreBranchPurchaseQuota} = props;
  const {id} = useParams();
  // console.log(id);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });

  let products = [];
  const onSubmit = data => {
    products.push({
      id_product: formState.id_product,
      qty: formState.qty
    })
    data.id_branch = formState.id_branch;
    data.products = products;
    onStoreBranchPurchaseQuota(data)
  }

  const [formState, setFormState] = useState({
    id_branch: id,
    id_product: branchPurchaseQuota.id_product,
    qty: branchPurchaseQuota.qty
  });

  const handleChange = (event) => {
    const target = event.target.name;
    const {value} = event.target;
    event.persist()

    setFormState((formState) => ({
      ...formState,
      [target]: (value === '') ? 0 : parseInt(value)
    }));
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper elevation={3} className={classes.paperRoot}>
          <Box display="flexDirection">
            <Box className={classes.boxMarginRow}>
              <TextField
                label="Batas Kuota Pembelian"
                variant="outlined"
                fullWidth
                size={"small"}
                name={"qty"}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                inputRef={register}
                error={errors.qty && true}
                helperText={
                  errors.qty && errors.qty.message
                }
                placeholder={0}
                defaultValue={formState.qty || ''}
                onChange={handleChange}
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Box>
              <Button
                variant={"outlined"}
                size={"small"}
                className={classes.cancelButton}
                onClick={closedModalDialog}
              >
                Batal
              </Button>
            </Box>
            <Box>
              <Button
                type={"submit"}
                variant={"contained"}
                size={"small"}
                className={classes.submitButton}
              >
                Ubah
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
    </Fragment>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onStoreBranchPurchaseQuota: (storeData) => dispatch(actions.storeBranchPurchaseQuota(storeData))
  }
}

export default connect(null,mapDispatchToProps)(EditBranchPurchaseQuota);