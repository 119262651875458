import * as actionTypes from './types';
import axios from '../axios-orders';
import { setAlert } from './alert'

export const fetchRoundsStart = () => {
  return {
    type: actionTypes.FETCH_ROUNDS_START
  }
}

export const fetchRoundsSuccess = (rounds) => {
  return {
    type: actionTypes.FETCH_ROUNDS_SUCCESS,
    rounds: rounds
  }
}

export const fetchRoundsFail = (error) => {
  return {
    type: actionTypes.FETCH_ROUNDS_FAIL,
    error: error
  }
}

export const fetchRounds = () => {
  return dispatch => {
    dispatch(fetchRoundsStart())
    axios.get(`admin/round`,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(fetchRoundsSuccess(res.data.data))
      })
      .catch(err => {
        dispatch(fetchRoundsFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const storeRoundStart = () => {
  return {
    type: actionTypes.STORE_ROUND_START
  }
}

export const storeRoundSuccess = () => {
  return {
    type: actionTypes.STORE_ROUND_SUCCESS,
  }
}

export const storeRoundFail = (error) => {
  return {
    type: actionTypes.STORE_ROUND_FAIL,
    error: error
  }
}

export const storeRound = (storeData) => {
  return dispatch => {
    dispatch(storeRoundStart())
    axios.post(`admin/round`, storeData,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(storeRoundSuccess())
        dispatch(setAlert(res.data.message, "success"))
      })
      .catch(err => {
        dispatch(storeRoundFail(err.response.data.errors))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const updateRoundStart = () => {
  return {
    type: actionTypes.UPDATE_ROUND_START
  }
}

export const updateRoundSuccess = () => {
  return {
    type: actionTypes.UPDATE_ROUND_SUCCESS,
  }
}

export const updateRoundFail = (error) => {
  return {
    type: actionTypes.UPDATE_ROUND_FAIL,
    error: error
  }
}

export const updateRound = (id,storeData) => {
  return dispatch => {
    dispatch(updateRoundStart())
    axios.put(`admin/round/${id}`, storeData,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(updateRoundSuccess())
        dispatch(setAlert(res.data.message, "success"))
      })
      .catch(err => {
        dispatch(updateRoundFail(err.response.data.errors))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const deleteRoundStart = () => {
  return {
    type: actionTypes.DELETE_ROUND_START
  }
}

export const deleteRoundSuccess = () => {
  return {
    type: actionTypes.DELETE_ROUND_SUCCESS,
  }
}

export const deleteRoundFail = (error) => {
  return {
    type: actionTypes.DELETE_ROUND_FAIL,
    error: error
  }
}

export const deleteRound = (id) => {
  return dispatch => {
    dispatch(deleteRoundStart())
    axios.delete(`admin/round/${id}`,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(deleteRoundSuccess())
        dispatch(setAlert(res.data.message, "success"))
      })
      .catch(err => {
        dispatch(deleteRoundFail(err.response.data.errors))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const clearErrorRound = () => {
  return {
    type: actionTypes.CLEAR_ERROR_ROUND
  }
}