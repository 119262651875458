import React,{Fragment} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {Avatar} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

const ProductData = props => {
  const {product, index,handleQuotaChange,quota,register,errors} = props;
  return (
    <Fragment>
      <TableRow key={product.id}>
        <TableCell>
          {index+1}
        </TableCell>
        <TableCell>
          <Avatar alt="Remy Sharp" src={product.image} />
        </TableCell>
        <TableCell>
          {product.name+' '+product.weight+' '+product.unit}
        </TableCell>
        <TableCell>
          <TextField
            fullWidth
            name="qty"
            type="number"
            variant="outlined"
            size={"small"}
            inputRef={register}
            helperText={
              errors.qty && errors.qty.message
            }
            error={errors.qty && true}
            onChange={e => handleQuotaChange(e,product,index)}
            placeholder={quota[index].qty}
          />
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default ProductData;