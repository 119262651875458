import React,{Fragment} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Edit, Search} from "@material-ui/icons";
import Chip from "@material-ui/core/Chip";

const QuotaProductData = props => {
  const {quotaProduct, index, edited,classes,viewed} = props;
  const selesai = <Chip label="Selesai" className={classes.chipSuccess} size={"medium"}/>;
  const pending = <Chip label="Pending" className={classes.chipDraft} size={"medium"}/>;

  return (
    <Fragment>
      <TableRow key={quotaProduct.id}>
        <TableCell>
          {index+1}
        </TableCell>
        <TableCell>
          {quotaProduct.updated_at}
        </TableCell>
        <TableCell>
          {quotaProduct.admin ? quotaProduct.admin.name : ''}
        </TableCell>
        <TableCell>
          {quotaProduct.status === 'saved' ? selesai : pending}
        </TableCell>
        <TableCell>
          <Tooltip title="Edit Kuota Produk">
            <IconButton aria-label="edited" onClick={() => edited(quotaProduct)}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Detail Kuota Produk">
            <IconButton aria-label="deleted" onClick={() => viewed(quotaProduct)}>
              <Search />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default QuotaProductData;