import React,{Fragment} from "react";
import TableCell from "@material-ui/core/TableCell";
// import {IconButton, Tooltip} from "@material-ui/core";
// import DetailIcon from "@material-ui/icons/Search";
import TableRow from "@material-ui/core/TableRow";

const ResetStockData = props => {
  const {resetStock, index} = props;
  return (
    <Fragment>
      <TableRow key={resetStock.id}>
        <TableCell>
          {index+1}
        </TableCell>
        <TableCell>
          {resetStock.created_at}
        </TableCell>
        <TableCell>
          {resetStock.branch.name}
        </TableCell>
        <TableCell>
          {resetStock.admin.name}
        </TableCell>
        {/*<TableCell>*/}
        {/*  <Tooltip title="Detail Branch">*/}
        {/*    <IconButton aria-label="detail">*/}
        {/*      <DetailIcon />*/}
        {/*    </IconButton>*/}
        {/*  </Tooltip>*/}
        {/*</TableCell>*/}
      </TableRow>
    </Fragment>
  );
};

export default ResetStockData;