import React, { useState, Fragment, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Grid, Typography, Card, CardContent, TextField, CardActions, Button, withStyles, Switch, FormControl
} from '@material-ui/core'
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers"
import SchemaValidation from './validation'
import { useHistory } from 'react-router-dom'
import Skeleton from '@material-ui/lab/Skeleton'
// import Backdrop from '@material-ui/core/Backdrop'
// import CircularProgress from '@material-ui/core/CircularProgress'

import { connect } from 'react-redux'
import { getBranch } from '../../../actions/branch'
import { getUserRole, addUser } from '../../../actions/user'

// component
import UploadImage from './UploadImage'
// import CapitalizedText from '../../../components/layout/CapitalizedText'
// import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1)
    },
    row: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    btn: {
        backgroundColor: '#011747',
        color: '#FFF',
        '&:hover': {
          backgroundColor: '#032162'
        }
    },
    backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}))

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 38,
    height: 19,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(19px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 19 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const CreateUser = ({ 
    addUser, 
    getBranch, 
    branch : { branches, loading },
    getUserRole,
    user : { userRole, loadingUserRole, loadingAddUser, user }
 }) => {
    const classes = useStyles()
    const history = useHistory()
    const { register, handleSubmit, errors } = useForm({
		resolver: yupResolver(SchemaValidation)
    });
    
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });

    const [checked, setChecked] = useState({
      is_active: false
    });

  const handleChecked = (event) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };

    // Handle Change Banner
    const [banner, setBanner] = useState([]);
    const [base64, setB64] = useState();
    const handleChangeBanner = event => {
        setBanner(event[0]);
            let reader = new FileReader();
            reader.readAsDataURL(event[0]);
            reader.onload = function(){
            setB64(reader.result);
        // console.log(reader.result);
        }
    };

    const handleChange = event => {
        // console.log(event.target.value);
        event.persist();

      if(event.target.type === 'checkbox'){
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [event.target.name]: event.target.checked
          }
        }));
      }else{
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [event.target.name]: event.target.value
          }
        }));
      }

    };

    const onSubmit = e => {
        // console.log(formState.values, base64)
      // console.log(e)
      // console.log(formState.values.is_active);
      formState.values.is_active = checked.is_active === true ? "1" : "0";
      // console.log(formState.values);
        addUser(formState.values, base64, history)
    }

    useEffect(() => {
        getBranch()
        getUserRole()
    }, [loading, getBranch, getUserRole])

    return (
        <Fragment>
            <div className={classes.root}>
                <div className={classes.row}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Typography variant="h4">Buat User Baru</Typography>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.row}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={3}>
                            <Grid
                                item
                                lg={8}
                                md={8}
                                sm={12}
                                xs={12}
                            >
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    defaultValue={formState.values.name || ''}
                                                    label="Nama Anda"
                                                    margin="dense"
                                                    name="name"
                                                    onChange={handleChange}
                                                    helperText={
                                                        errors.name && errors.name.message
                                                    }
                                                    error={errors.name && true}
                                                    inputRef={register}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    defaultValue={formState.values.email || ''}
                                                    label="Email Anda"
                                                    margin="dense"
                                                    name="email"
                                                    type="email"
                                                    onChange={handleChange}
                                                    helperText={
                                                        errors.email && errors.email.message
                                                    }
                                                    error={errors.email && true}
                                                    inputRef={register}
                                                />
                                            </Grid>
                                            
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    defaultValue={formState.values.password || ''}
                                                    label="password"
                                                    margin="dense"
                                                    type="password"
                                                    name="password"
                                                    onChange={handleChange}
                                                    helperText={
                                                        errors.password && errors.password.message
                                                    }
                                                    error={errors.password && true}
                                                    inputRef={register}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                {!loading ? (
                                                  <FormControl
                                                    error={errors.id_branch ? true : false}
                                                    variant="outlined" fullWidth size={"small"} className={classes.marginForm}>
                                                    <InputLabel htmlFor="outlined-age-native-simple">Cabang</InputLabel>
                                                    <Select
                                                      fullWidth
                                                      native
                                                      defaultValue={formState.id_branch}
                                                      onChange={handleChange}
                                                      label="Cabang"
                                                      inputProps={{
                                                        name: 'id_branch',
                                                        id: 'outlined-age-native-simple'
                                                      }}
                                                      name="id_branch"
                                                      inputRef={register}
                                                    >
                                                      <option aria-label="None" value="" />
                                                       {branches.data.map((item) => (
                                                         <option value={item.id}>{item.name}</option>
                                                       ))}
                                                    </Select>
                                                    <FormHelperText>{errors.id_branch && errors.id_branch.message}</FormHelperText>
                                                  </FormControl>
                                                ):(
                                                    <Skeleton variant="rect" height={30}></Skeleton>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                {!loadingUserRole ? (
                                                    <FormControl
                                                      error={errors.id_role ? true : false}
                                                      variant="outlined" fullWidth size={"small"} className={classes.marginForm}>
                                                      <InputLabel htmlFor="outlined-age-native-simple">Jabatan Pengguna</InputLabel>
                                                      <Select
                                                        fullWidth
                                                        native
                                                        defaultValue={formState.id_role}
                                                        onChange={handleChange}
                                                        label="Jabatan Pengguna"
                                                        inputProps={{
                                                          name: 'id_role',
                                                          id: 'outlined-age-native-simple'
                                                        }}
                                                        name="id_role"
                                                        inputRef={register}
                                                      >
                                                        <option aria-label="None" value="" />
                                                        {userRole.data.map((item) => (
                                                          <option value={item.id}>{item.name}</option>
                                                        ))}
                                                      </Select>
                                                      <FormHelperText>{errors.id_role && errors.id_role.message}</FormHelperText>
                                                    </FormControl>
                                                ):(
                                                    <Skeleton variant="rect" height={30}></Skeleton>
                                                )}
                                            </Grid>
                                          <Grid
                                            item
                                            lg={6}
                                            md={6}
                                            sm={12}
                                            xs={12}
                                          >
                                            {/*<br/>*/}
                                            {/*<FormGroup>*/}
                                              <FormControl
                                                error={errors.is_active && true}
                                              >
                                                <Typography component="div">
                                                  <Grid component="label" container alignItems="center" spacing={1}>
                                                    <Grid item>Tidak Aktif</Grid>
                                                    <Grid item>
                                                      <AntSwitch checked={checked.is_active} onChange={handleChecked} name="is_active" inputRef={register}/>
                                                    </Grid>
                                                    <Grid item>Aktif</Grid>
                                                  </Grid>
                                                </Typography>
                                              </FormControl>
                                              <FormHelperText>{errors.is_active && errors.is_active.message}</FormHelperText>
                                            {/*</FormGroup>*/}

                                          </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button type="submit" size="medium" variant="contained" className={classes.btn}>
                                            Simpan
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                            <Grid
                                item
                                lg={4}
                                md={4}
                                sm={12}
                                xs={12}
                            >
                                <Card>
                                    <CardContent>
                                        <UploadImage value={banner} handleChangeBanner={handleChangeBanner} />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    branch : state.branch,
    user : state.user
})

export default connect(mapStateToProps, { addUser, getBranch, getUserRole })(CreateUser)