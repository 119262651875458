import React,{Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {FileCopy} from "@material-ui/icons";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Chip from "@material-ui/core/Chip";
import moment from "moment";
import {isEmpty} from "../../../../../../shared/utility";
import TableBody from "@material-ui/core/TableBody";
import DataOrder from "./DataOrder/DataOrder";

const columns = [
  { id: 'no', label: 'No', minWidth: 100 },
  { id: 'item_produk', label: 'Item Produk', minWidth: 100 },
  { id: 'harga_satuan', label: 'Harga Satuan', minWidth: 100 },
  { id: 'jumlah_order', label: 'Jumlah Order', minWidth: 100 },
  { id: 'sub_total', label: 'Sub Total', minWidth: 100 },
];

const ListOrders = props => {
  const {
    classes,purchaseOrder
  } = props;
  const menunggu_konfirmasi = <Chip label="Menunggu Konfirmasi" className={classes.chipWarning} size={"medium"}/>;
  const sampai = <Chip label="Sampai" className={classes.chipSuccess} size={"medium"}/>;
  const dikirim = <Chip label="Dikirim" className={classes.chipPrimary} size={"medium"}/>;
  const pending = <Chip label="Pending" className={classes.chipWarning} size={"medium"}/>;
  const diproses = <Chip label="Diproses" className={classes.chipSecondary} size={"medium"}/>;
  const ditolak = <Chip label="Ditolak" className={classes.chipError} size={"medium"}/>;
  const expired = <Chip label="Expired" size={"medium"}/>;
  const new_invoice = <Chip label="New Invoice" className={classes.chipWarning} size={"medium"}/>;

  let tableBody = '';

  if(!isEmpty(purchaseOrder)){
    tableBody = (
      <Fragment>
        <TableBody>
          {purchaseOrder.details.map((data, index) => (
            <DataOrder
              data={data}
              key={data.id}
              index={index}
              classes={classes}
              // edited={() => props.edit(kurir)}
              // deleted={() => props.hapus(kurir)}
            />

          ))}
        </TableBody>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xl={6} md={6} sm={12} xs={12}>
          <Box display={"flex"} flexDirection="row" m={1}>
            <Box width={"30%"} className={classes.flexItemCenter}>
              Ronde
            </Box>
            <Box width={"70%"}>
              : {purchaseOrder.round && purchaseOrder.round.name}
            </Box>
          </Box>
          <Box display={"flex"} flexDirection="row" m={1}>
            <Box width={"30%"} className={classes.flexItemCenter}>
              No Invoice
            </Box>
            <Box width={"70%"}>
              : {purchaseOrder.inv_name}
              <CopyToClipboard text={purchaseOrder.inv_name}>
                <Tooltip title="copy to clipboard" placement={"right"}>
                  <IconButton className={classes.zeroPadding} color={"primary"}>
                    <FileCopy fontSize={"small"}/>
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection="row" m={1}>
            <Box width={"30%"}>
              Tanggal
            </Box>
            <Box width={"70%"}>
              : {moment(purchaseOrder.created_at).format('dddd, DD MMMM YYYY')}
            </Box>
          </Box>
          <Box display={"flex"} flexDirection="row" m={1}>
            <Box width={"30%"}>
              PIC
            </Box>
            <Box width={"70%"}>
              : {purchaseOrder.user}
            </Box>
          </Box>
          <Box display={"flex"} flexDirection="row" m={1}>
            <Box width={"30%"} className={classes.flexItemCenter}>
              No Telp
            </Box>
            <Box width={"70%"}>
              : {purchaseOrder.phone}
              <CopyToClipboard text={purchaseOrder.phone}>
                <Tooltip title="copy to clipboard" placement={"right"}>
                  <IconButton className={classes.zeroPadding} color={"primary"}>
                    <FileCopy fontSize={"small"}/>
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </Box>
          </Box>
        </Grid>
        <Grid item xl={6} md={6} sm={12} xs={12}>
          <Box display={"flex"} flexDirection="row" m={1}>
            <Box width={"30%"}>
              Status
            </Box>
            <Box width={"70%"}>
              :
              {
                (purchaseOrder.status_name === 'Menunggu Konfirmasi') ? menunggu_konfirmasi :
                  (purchaseOrder.status_name === 'Sampai') ? sampai :
                    (purchaseOrder.status_name === 'Dikirim') ? dikirim :
                      (purchaseOrder.status_name === 'Pending') ? pending :
                        (purchaseOrder.status_name === 'Diproses') ? diproses :
                          (purchaseOrder.status_name === 'Ditolak') ? ditolak :
                            (purchaseOrder.status_name === 'Expired') ? expired :
                              (purchaseOrder.status_name === 'New Invoice') ? new_invoice :
                                ''
              }
              {/*: {purchaseOrder.status_name}*/}
            </Box>
          </Box>
          <Box display={"flex"} flexDirection="row" m={1}>
            <Box width={"30%"}>
              Alamat Pengiriman
            </Box>
            <Box width={"65%"}>
              <div style={{textAlign:"justify"}}>
                : {purchaseOrder.address}
              </div>

            </Box>
            <Box width={"5%"}>
              <CopyToClipboard text={purchaseOrder.address}>
                <Tooltip title="copy to clipboard" placement={"top"}>
                  <IconButton className={classes.zeroPadding} color={"primary"}>
                    <FileCopy fontSize={"small"}/>
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <div className={classes.row}>
        <div className={classes.tagMenu}>
          List Order
        </div>
      </div>

      <div className={classes.row}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
      </div>
    </Fragment>
  );
};

export default ListOrders;