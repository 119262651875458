import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment'; // eslint-disable-next-line
import moment_tz from 'moment-timezone'
// import {options} from './chart'

// Redux
import { connect } from 'react-redux'
import {getGrafikTransactionMoney} from '../../../../actions/dashboard'
import Skeleton from '@material-ui/lab/Skeleton';
import * as d3 from "d3-format";
import C3Chart from "react-c3js";
import palette from "../../../../theme/palette";

const GrafikTransactionMoney = (props) => {
    const { getGrafikTransactionMoney, dashboard : { loadingV2, grafikTransactionMoney} } = props

    const [selectedDate ] = useState(new Date());

    const submitDefault = moment().subtract(7, 'd').format('YYYY-MM-DD');
    const submitDefaultEndDate = moment({}).format('YYYY-MM-DD');
    const [ startDate, setStartDate ] = useState({
        submit: {
            submit: submitDefault
        },
        view: {
            view: moment().subtract(7, 'd').format('YYYY-MM-DD')
        }
    });
    const handleStartDate = (date) => {
    const changeDate = moment(date).format('YYYY-MM-DD');
        setStartDate(startDate => ({
            ...startDate,
                submit: {
                    submit: changeDate
            },
                view: {
                    view: date
            }
        }));
    };

    const [ endDate, setEndDate ] = useState({
        submit: {
            submit: submitDefaultEndDate
        },
        view: {selectedDate}
    });
    const handleEndDate = (date) => {
    const all = moment(date).format('YYYY-MM-DD');
        setEndDate(endDate => ({
            ...endDate,
            submit: {
                submit: all
            },
            view: {
                view: date
            }
        }));
    };

    useEffect(() => {
        getGrafikTransactionMoney(startDate.submit.submit, endDate.submit.submit, 'jumlah_uang')
    }, [getGrafikTransactionMoney, startDate, endDate])

    const colors = {
        JumlahUang: palette.warning.light,
    }

    let JumlahUang = ['JumlahUang'];
    let x = ['x']
    let yMin = 0;
    let yMax = 1;

    if(grafikTransactionMoney !== null){
        for (let i = 0; i < grafikTransactionMoney.data.length; i++) {
            const newDate = new Date(grafikTransactionMoney.data[i].date)
            x.push(moment(newDate).format('YYYY-MM-DD HH:mm:ss'));
            JumlahUang.push(grafikTransactionMoney.data[i].jumlah_uang);
        }

        if(grafikTransactionMoney.data.length > 0){
            const succesValue = JumlahUang.slice(1);
            yMax = (Math.max(...succesValue))
            yMin = (Math.min(...succesValue))
        }
    }

    const area = {
        data: {
            x: 'x',
            xFormat: '%Y-%m-%d %H:%M:%S',
            columns: [
                x,
                JumlahUang
            ],
            types: {
                // Primary: 'area',
                JumlahUang: 'area-spline',
            },
        },
        color: {
            pattern: [colors.JumlahUang],
        },
        subchart: {
            show: true,
        },
        axis: {
            x: {
                type: 'timeseries',
                label: {
                    text: 'Tanggal',
                    position: 'outer-center'
                },
                tick: {
                    format: '%d-%m'
                }
            },
            y: {
                min: yMin,
                max: yMax,
                label: {
                    text: 'Jumlah Uang',
                    position: 'outer-middle'
                },
                tick: {
                    format: function (y) {
                        const format =  d3.format("$,");
                        return format(y);
                    }
                }
            }
        },
        tooltip: {
            format: {
                title: function (title) {
                    return `Tanggal ${moment(title).format('DD/MMM/YYYY HH:mm')}`;
                }
            }
        }
    }
    
    return(loadingV2 ? <Skeleton variant="rect" height={100}></Skeleton> :
        <div>
                <Card>
                    <CardHeader 
                        title={`Grafik Transaksi Penjualan (Jumlah Uang)`}
                    />
                    <CardContent>
                        <Grid container spacing={2} justify="space-between">
                            <Grid 
                                item
                            >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker 
                                        fullWidth
                                        label="Tanggal Awal"
                                        variant="outlined"
                                        name="start_date"
                                        format="dd MMMM yyyy"
                                        value={startDate.view.view} 
                                        onChange={handleStartDate} 
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid 
                                item
                            >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker 
                                        fullWidth
                                        label="Tanggal Akhir"
                                        variant="outlined"
                                        name="end_date"
                                        format="dd MMMM yyyy"
                                        value={endDate.view.view} 
                                        onChange={handleEndDate} 
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardContent>
                        <C3Chart data={area.data} color={area.color} subchart={area.subchart} axis={area.axis} tooltip={area.tooltip}/>
                    </CardContent>
                </Card>

        </div>
    )
}

const mapStateToProps = state => ({
    dashboard: state.dashboard
})

export default connect(mapStateToProps, {getGrafikTransactionMoney})(GrafikTransactionMoney)