import React, { forwardRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
    Typography,
    Grid,
    Fab,
    Paper,
    IconButton,
    InputBase,
    Divider,
    Hidden,
    Card,
    CardContent,
    CardHeader,
    TextField,
    MenuItem
} from '@material-ui/core'
import AddCircle from '@material-ui/icons/AddCircle'
import SearchIcon from '@material-ui/icons/Search'
import { Link as RouterLink } from 'react-router-dom';

// Redux
import { connect } from 'react-redux'
import { getBranchSearch } from '../../actions/branch'

// components
import ListBranch from './ListBranch'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1)
    },
    row: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    btnAddCustomer: {
        backgroundColor: '#0277BD',
        '&:hover' : {
            backgroundColor: '#2092D6'
        },
        color: '#FFFFFF',
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    searchRoot: {
		padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
		width: 'auto',
		// marginTop: theme.spacing(2)
	},
	input: {
        marginLeft: theme.spacing(1),
            flex: 1,
        },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(2),
    },
    statusPrice: {
        width: '150px'
    }
}))

const CustomRouterLink = forwardRef((props, ref) => (
    <div
      ref={ref}
      style={{ flexGrow: 1 }}
    >
      <RouterLink {...props} />
    </div>
));

const Branch = ({ getBranchSearch, branch : { branchSearch, loadingSearch } }) => {
    const classes = useStyles()
    const [ keyword, setKeyword ] = useState('')
    const [ status, setStatus ] = useState(1)
    const [page, setPage] = React.useState(0);

    useEffect(() => {
        getBranchSearch(keyword, status, page+1)
    }, [ loadingSearch, getBranchSearch, keyword, status, page ])

    const handleChangeKeyword = event => {
        setKeyword(event.target.value)
    }

    const handleChangeStatusPrice = event => {
        setStatus(event.target.value)
    }    

    return(
        <div className={classes.root}>
            <div className={classes.row}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item>  
                        <Typography variant="h4">Cabang</Typography>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.row}>
                <Grid
                    container
                    spacing={2}
                    justify='space-between'
                >
                    <Hidden only={['xs','sm']}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Fab variant="extended" className={classes.btnAddCustomer} component={CustomRouterLink} to='/branch/create'>
                                <AddCircle className={classes.extendedIcon} />
                                Tambah Cabang
                            </Fab>
                        </Grid>
                    </Hidden>
                    <Hidden only={['md','lg','xl']}>
                        <Fab color="primary" aria-label="add" className={classes.fab} component={CustomRouterLink} to='/branch/create'>
                            <AddCircle/>
                        </Fab>
                    </Hidden>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Paper component="form" className={classes.searchRoot}>
                            <IconButton type="button" className={classes.iconButton} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                            <Divider className={classes.divider} orientation="vertical" />
                            <InputBase
                                className={classes.input}
                                name="pesan"
                                onChange={handleChangeKeyword}
                                placeholder="Cari Cabang"
                                inputProps={{ 'aria-label': 'Cari Cabang' }}
                            />
                            
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.row}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid 
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                    >
                        <Card>
                            <CardHeader 
                                title="List Cabang"
                                action={
                                    <TextField 
                                        select
                                        fullWidth
                                        className={classes.statusPrice}
                                        variant="outlined"
                                        name="status"
                                        defaultValue={status}
                                        label="Status Aktif"
                                        onChange={handleChangeStatusPrice}
                                    >
                                        <MenuItem value="1">Aktif</MenuItem>
                                        <MenuItem value="0">Tidak Aktif</MenuItem>
                                    </TextField>
                                }
                            />
                            <CardContent>
                                <ListBranch 
                                    branchSearch={branchSearch} 
                                    loadingSearch={loadingSearch} 
                                    page={page}
                                    setPage={setPage}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    branch : state.branch
})

export default connect(mapStateToProps, {getBranchSearch})(Branch)