import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    CardHeader,
    Grid
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';

// Redux
import { connect } from 'react-redux'
import { getGrafikNetIncome } from '../../../../actions/dashboard'
import Skeleton from '@material-ui/lab/Skeleton';
import C3Chart from "react-c3js";
import palette from "../../../../theme/palette";

import * as d3 from 'd3-format';

const locale =  {
  "decimal": ".",
  "thousands": ",",
  "grouping": [3],
  "currency": ["Rp", ""]
}
d3.formatDefaultLocale(locale);

const GrafikNetIncome = (props) => {
    const { getGrafikNetIncome, dashboard : { loadingV2, grafikNetIncome} } = props

    const [selectedDate ] = useState(new Date());

    const submitDefault = moment({}).format('YYYY-MM-DD');
    const [ startDate, setStartDate ] = useState({
        submit: {
            submit: submitDefault
        },
        view: {selectedDate}
    });
    const handleStartDate = (date) => {
    const changeDate = moment(date).format('YYYY-MM-DD');
        setStartDate(startDate => ({
            ...startDate,
                submit: {
                    submit: changeDate
            },
                view: {
                    view: date
            }
        }));
    };

    const [ endDate, setEndDate ] = useState({
        submit: {
            submit: submitDefault
        },
        view: {selectedDate}
    });
    const handleEndDate = (date) => {
    const all = moment(date).format('YYYY-MM-DD');
        setEndDate(endDate => ({
            ...endDate,
            submit: {
                submit: all
            },
            view: {
                view: date
            }
        }));
    };

    useEffect(() => {
        getGrafikNetIncome(startDate.submit.submit, endDate.submit.submit)
    }, [getGrafikNetIncome, startDate, endDate])

    const colors = {
      NetIncome: palette.warning.light,
    }

    let NetIncome = ['NetIncome'];
    let x = ['x']
    let yMin = 0;
    let yMax = 1;

    if(grafikNetIncome !== null){
      for (let i = 0; i < grafikNetIncome.data.length; i++) {
        const newDate = new Date(grafikNetIncome.data[i].date)
        x.push(moment(newDate).format('YYYY-MM-DD HH:mm:ss'));
        NetIncome.push(grafikNetIncome.data[i].value);
      }

      if(grafikNetIncome.data.length > 0){
        const succesValue = NetIncome.slice(1);
        yMax = (Math.max(...succesValue))
        yMin = (Math.min(...succesValue))
      }
    }

    const area = {
      data: {
        x: 'x',
        xFormat: '%Y-%m-%d %H:%M:%S',
        columns: [
          x,
          NetIncome
        ],
        types: {
          // Primary: 'area',
          NetIncome: 'area-spline',
        },
      },
      color: {
        pattern: [colors.NetIncome],
      },
      subchart: {
        show: true,
      },
      axis: {
        x: {
          type: 'timeseries',
          label: {
            text: 'Tanggal',
            position: 'outer-center'
          },
          tick: {
            format: '%d-%m'
          }
        },
        y: {
          min: yMin,
          max: yMax,
          label: {
            text: 'Net Income',
            position: 'outer-middle'
          },
          tick: {
            format: function (y) {
              const format =  d3.format("$,");
              return format(y);
            }
          }
        }
      },
      tooltip: {
        format: {
          title: function (title) {
            return `Tanggal ${moment(title).format('DD/MMM/YYYY HH:mm')}`;
          }
        }
      }
    }
    
    return(loadingV2 ? <Skeleton variant="rect" height={100}></Skeleton> :
        <div>
                <Card>
                    <CardHeader 
                        title="Grafik Net Income"
                    />
                    <CardContent>
                        <Grid container justify="space-between">
                            <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker 
                                        fullWidth
                                        label="Tanggal Awal"
                                        variant="outlined"
                                        name="start_date"
                                        format="dd MMMM yyyy"
                                        value={startDate.view.view} 
                                        onChange={handleStartDate} 
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker 
                                        fullWidth
                                        label="Tanggal Akhir"
                                        variant="outlined"
                                        name="end_date"
                                        format="dd MMMM yyyy"
                                        value={endDate.view.view} 
                                        onChange={handleEndDate} 
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardContent>
                      <C3Chart data={area.data} color={area.color} subchart={area.subchart} axis={area.axis} tooltip={area.tooltip}/>
                    </CardContent>
                </Card>
        </div>
    )
}

const mapStateToProps = state => ({
    dashboard: state.dashboard
})

export default connect(mapStateToProps, {getGrafikNetIncome})(GrafikNetIncome)