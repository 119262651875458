import React,{Fragment} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import NumberFormat from "react-number-format";
import moment from "moment";
import {IconButton, Tooltip} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const ProductPriceBuyBackData = props => {
  const {
    item,
    index,
    deleted
  } = props;

  return (
    <Fragment>
      <TableRow key={item.id}>
        <TableCell>
          {index+1}
        </TableCell>
        <TableCell>
          <NumberFormat value={item.buyback_price} displayType={'text'} thousandSeparator={true} prefix={`RP `} />
        </TableCell>
        <TableCell>
          {moment(item.created_at).format('DD MMMM YYYY HH:mm')}
        </TableCell>
        <TableCell>
          <Tooltip title="Delete Harga">
            <IconButton aria-label="detail" color="primary" onClick={() => deleted()}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default ProductPriceBuyBackData;