import React, {Fragment, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";

import * as actions from '../../../actions';
import {connect} from "react-redux";
import {isEmpty} from "../../../shared/utility";
import TableBody from "@material-ui/core/TableBody";
import {Loading} from "../../../components/UI";
import RoundData from "./RoundData";

const columns = [
  { id: 'No', label: 'No', minWidth: '5%' },
  { id: 'tanggal', label: 'Tanggal Update', minWidth: '18%' },
  { id: 'cabang', label: 'Nama Ronde', minWidth: '18%' },
  { id: 'pic', label: 'Jam Buka', minWidth: '18%' },
  { id: 'pic', label: 'Jam Tutup', minWidth: '18%' },
  { id: 'pic', label: 'PIC', minWidth: '18%' },
  { id: 'pic', label: 'Action', minWidth: '5%' },
  // { id: 'detail', label: 'Detail', minWidth: '12%' },

];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const ListRound = props => {
  const classes = useStyles();

  const {onFetchRound, loading, rounds, changing,edit,deleted} = props;
  useEffect(() => {
    onFetchRound()
  },[onFetchRound,changing]);

  let tableBody = '';
  if (!isEmpty(rounds)){
    tableBody = (
      <Fragment>
        <TableBody>
          {rounds.map((round, index) => (
            <RoundData
              round={round}
              key={round.id}
              index={index}
              edited={(data) => edit(data)}
              deleted={(data) => deleted(data)}
            />
          ))}

        </TableBody>
      </Fragment>
    )
  }

  return (loading ? <Loading/> :
    <Fragment>
      <Paper className={classes.root}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    rounds: state.round.rounds,
    loading: state.round.loading,
    changing: state.round.changing
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchRound: () => dispatch(actions.fetchRounds())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListRound);