import React,{Fragment} from "react";
import {TableCell, TableRow, IconButton, Tooltip, Chip} from "@material-ui/core";
import {Search as DetailIcon} from "@material-ui/icons";
import moment from "moment";
import NumberFormat from "react-number-format";

// const ITEM_HEIGHT = 48;

const DataPurchaseOrder = props => {
  const {purchaseOrder, index,classes} = props;
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  //
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  //
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const menunggu_konfirmasi = <Chip label="Menunggu Konfirmasi" className={classes.chipWarning} size={"medium"}/>;
  const sampai = <Chip label="Sampai" className={classes.chipSuccess} size={"medium"}/>;
  const dikirim = <Chip label="Dikirim" className={classes.chipPrimary} size={"medium"}/>;
  const pending = <Chip label="Pending" className={classes.chipWarning} size={"medium"}/>;
  const diproses = <Chip label="Diproses" className={classes.chipSecondary} size={"medium"}/>;
  const ditolak = <Chip label="Ditolak" className={classes.chipError} size={"medium"}/>;
  const expired = <Chip label="Expired" size={"medium"}/>;
  const new_invoice = <Chip label="New Invoice" className={classes.chipWarning} size={"medium"}/>;

  return (
    <Fragment>
      <TableRow key={purchaseOrder.id}>
        <TableCell>
          {index+1}
        </TableCell>
        <TableCell>
          {purchaseOrder.inv_name}
        </TableCell>
        <TableCell>
          {moment(purchaseOrder.created_at).format('dddd, DD MMMM YYYY')}
        </TableCell>
        <TableCell>
          {purchaseOrder.round && purchaseOrder.round.name}
        </TableCell>
        <TableCell>
          {purchaseOrder.user.name}
        </TableCell>
        <TableCell>
          {purchaseOrder.branch && purchaseOrder.branch.name}
        </TableCell>
        <TableCell>
          {purchaseOrder.total_item}
        </TableCell>
        {/*<TableCell>*/}
        {/*  <NumberFormat value={purchaseOrder.total_price_payment} displayType={'text'} thousandSeparator={true} prefix={`Rp `}/>*/}
        {/*</TableCell>*/}
        <TableCell>
          <NumberFormat value={purchaseOrder.total_price_invoice} displayType={'text'} thousandSeparator={true} prefix={`Rp `}/>
        </TableCell>
        <TableCell>
          {
            (purchaseOrder.status_name === 'Menunggu Konfirmasi') ? menunggu_konfirmasi :
              (purchaseOrder.status_name === 'Sampai') ? sampai :
                (purchaseOrder.status_name === 'Dikirim') ? dikirim :
                  (purchaseOrder.status_name === 'Pending') ? pending :
                    (purchaseOrder.status_name === 'Diproses') ? diproses :
                      (purchaseOrder.status_name === 'Ditolak') ? ditolak :
                        (purchaseOrder.status_name === 'Expired') ? expired :
                          (purchaseOrder.status_name === 'New Invoice') ? new_invoice :
                            ''
          }
          {/*{purchaseOrder.status_name}*/}
        </TableCell>
        <TableCell>
          <Tooltip title="Detail Purchase Order">
            {/*<RouterLink to={`/report/selling/detail/${trx.id}`}>*/}
            <IconButton aria-label="detail" onClick={() => props.detail(purchaseOrder.id)}>
              <DetailIcon />
            </IconButton>
            {/*</RouterLink>*/}
          </Tooltip>
          {/*<Tooltip title="More Actions">*/}
          {/*  /!*<RouterLink to={`/report/selling/detail/${trx.id}`}>*!/*/}
          {/*  <IconButton*/}
          {/*    aria-label="more"*/}
          {/*    aria-controls="long-menu"*/}
          {/*    aria-haspopup="true"*/}
          {/*    onClick={handleClick}*/}
          {/*  >*/}
          {/*    <MoreVert />*/}
          {/*  </IconButton>*/}
          {/*  /!*</RouterLink>*!/*/}
          {/*</Tooltip>*/}

          {/*<Menu*/}
          {/*  id="long-menu"*/}
          {/*  anchorEl={anchorEl}*/}
          {/*  keepMounted*/}
          {/*  open={open}*/}
          {/*  onClose={handleClose}*/}
          {/*  PaperProps={{*/}
          {/*    style: {*/}
          {/*      maxHeight: ITEM_HEIGHT * 4.5,*/}
          {/*      width: '20ch',*/}
          {/*    },*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <MenuItem onClick={handleClose}>*/}
          {/*    Edit*/}
          {/*  </MenuItem>*/}
          {/*  <MenuItem onClick={handleClose}>*/}
          {/*    Delete*/}
          {/*  </MenuItem>*/}
          {/*  /!*{options.map((option) => (*!/*/}
          {/*  /!*  <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>*!/*/}
          {/*  /!*    {option}*!/*/}
          {/*  /!*  </MenuItem>*!/*/}
          {/*  /!*))}*!/*/}
          {/*</Menu>*/}
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default DataPurchaseOrder;