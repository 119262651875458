import * as actionTypes from '../actions/types'
import { updateObject } from '../shared/utility'

const initialState = {
  branchPurchaseQuotas: {},
  branchPurchaseQuota: {},
  changing: 0,
  loading: false,
  error: {},
}

const fetchBranchPurchaseQuotasStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const fetchBranchPurchaseQuotasSuccess = (state, action) => {
  return updateObject(state, {
    branchPurchaseQuotas: action.branchPurchaseQuotas,
    loading: false
  })
}

const fetchBranchPurchaseQuotasFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const getBranchPurchaseQuotaStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const getBranchPurchaseQuotaSuccess = (state, action) => {
  return updateObject(state, {
    branchPurchaseQuota: action.branchPurchaseQuota,
    loading: false
  })
}

const getBranchPurchaseQuotaFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const storeBranchPurchaseQuotaStart = (state, action) => {
  return updateObject(state, {
    error: {},
    loading: true
  })
}

const storeBranchPurchaseQuotaSuccess = (state, action) => {
  // const newOrder = updateObject(action.storeData, { id: action.storeDataId })
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
}

const storeBranchPurchaseQuotaFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  })
}

const clearErrorBranchPurchaseQuota = (state, action) => {
  return updateObject(state, {
    error: {},
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BRANCH_PURCHASE_QUOTAS_START:
      return fetchBranchPurchaseQuotasStart(state, action)
    case actionTypes.FETCH_BRANCH_PURCHASE_QUOTAS_SUCCESS:
      return fetchBranchPurchaseQuotasSuccess(state, action)
    case actionTypes.FETCH_BRANCH_PURCHASE_QUOTAS_FAIL:
      return fetchBranchPurchaseQuotasFail(state, action)
    case actionTypes.GET_BRANCH_PURCHASE_QUOTA_START:
      return getBranchPurchaseQuotaStart(state, action)
    case actionTypes.GET_BRANCH_PURCHASE_QUOTA_SUCCESS:
      return getBranchPurchaseQuotaSuccess(state, action)
    case actionTypes.GET_BRANCH_PURCHASE_QUOTA_FAIL:
      return getBranchPurchaseQuotaFail(state, action)
    case actionTypes.STORE_BRANCH_PURCHASE_QUOTA_START:
      return storeBranchPurchaseQuotaStart(state, action)
    case actionTypes.STORE_BRANCH_PURCHASE_QUOTA_SUCCESS:
      return storeBranchPurchaseQuotaSuccess(state, action)
    case actionTypes.STORE_BRANCH_PURCHASE_QUOTA_FAIL:
      return storeBranchPurchaseQuotaFail(state, action)
    case actionTypes.CLEAR_ERROR_BRANCH_PURCHASE_QUOTA:
      return clearErrorBranchPurchaseQuota(state, action)
    default: return state
  }
}

export default reducer