import React,{Fragment} from "react";
import {Paper,Grid,TextField,InputAdornment,FormControl,InputLabel,Select,InputBase,IconButton} from "@material-ui/core";
import {MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers";
import {CalendarToday, Search as SearchIcon} from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {isEmpty} from "../../../../shared/utility";

const FormSearchPurchaseOrder = props => {
  const {
    classes,
    moment,
    isOpen,
    isOpenEnd,
    setIsOpen,
    setIsOpenEnd,
    startDate,
    endDate,
    handleStartDate,
    handleEndDate,
    handleSearch,
    formSearch,
    rounds
  } = props;

  let optionRounds = '';
  if(!isEmpty(rounds)){
    optionRounds = rounds.map((round) => (
      <option value={round.id}>{round.name}</option>
    ))
  }

  return (
    <Fragment>
      <Paper className={classes.paperContent}>
        <Grid
          container
          spacing={2}
        >
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <TextField
                onClick={() => setIsOpen(true)}
                value={moment(startDate.startDate).format('dddd DD MMMM YYYY')}
                fullWidth
                className={classes.margin}
                label="Tanggal Awal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" disablePointerEvents>
                      <CalendarToday />
                    </InputAdornment>
                  ),
                }}
              />
              <DatePicker
                fullWidth
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                disableFuture
                // minDate={minDate}
                variant="outlined"
                name="start_date"
                format="dd MMMM yyyy HH:mm"
                value={startDate.startDate}
                onChange={e => handleStartDate(e, 'endDate')}
                TextFieldComponent={() => null}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <TextField
                onClick={() => setIsOpenEnd(true)}
                value={moment(endDate.endDate).format('dddd DD MMMM YYYY')}
                fullWidth
                className={classes.margin}
                label="Tanggal Akhir"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" disablePointerEvents>
                      <CalendarToday />
                    </InputAdornment>
                  ),
                }}
              />
              <DatePicker
                fullWidth
                open={isOpenEnd}
                onOpen={() => setIsOpenEnd(true)}
                onClose={() => setIsOpenEnd(false)}
                disableFuture
                // minDate={minDate}
                variant="outlined"
                name="end_date"
                format="dd MMMM yyyy HH:mm"
                value={endDate.endDate}
                onChange={handleEndDate}
                TextFieldComponent={() => null}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xl={3} md={3} sm={12} xs={12}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel shrink htmlFor="age-native-label-placeholder">Status</InputLabel>
              <Select
                fullWidth
                native
                defaultValue={formSearch.status}
                onChange={handleSearch}
                label="Status"
                inputProps={{
                  name: 'status',
                  id: 'age-native-label-placeholder'
                }}
                name="status"
                // onChange={handleChange}
              >
                {/*<option value={''}></option>*/}
                <option value={''}>Semua</option>
                <option value={0}>Menunggu Konfirmasi</option>
                <option value={1}>Sampai</option>
                <option value={2}>Dikirim</option>
                <option value={3}>Pending</option>
                <option value={4}>Diproses</option>
                <option value={5}>Ditolak</option>
                <option value={6}>Expired</option>
                <option value={99}>New Invoice</option>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xl={3} md={3} sm={12} xs={12}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel shrink htmlFor="age-native-label-placeholder">Ronde</InputLabel>
              <Select
                fullWidth
                native
                defaultValue={formSearch.round_id}
                onChange={handleSearch}
                label="Ronde"
                inputProps={{
                  name: 'round_id',
                  id: 'age-native-label-placeholder'
                }}
                name="round_id"
                // onChange={handleChange}
              >
                {/*<option value={''}></option>*/}
                <option value={''}>Semua</option>
                {optionRounds}
                {/*<option value={0}>Menunggu Konfirmasi</option>*/}
                {/*<option value={1}>Sampai</option>*/}
                {/*<option value={2}>Dikirim</option>*/}
                {/*<option value={3}>Pending</option>*/}
                {/*<option value={4}>Diproses</option>*/}
                {/*<option value={5}>Ditolak</option>*/}
                {/*<option value={6}>Expired</option>*/}
                {/*<option value={99}>New Invoice</option>*/}
              </Select>
            </FormControl>
          </Grid>

        </Grid>
      </Paper>
      <div className={classes.row}>
        <Grid container justify={"flex-end"}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <Paper component="form" className={classes.searchRoot}>
              <InputBase
                className={classes.input}
                placeholder="Cari Kode Unik Invoice"
                inputProps={{ 'aria-label': 'Cari Kode Unik Invoice' }}
                defaultValue={formSearch.search}
                name={'search'}
                onChange={handleSearch}
              />
              <IconButton className={classes.iconButton} aria-label="search" disabled>
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default FormSearchPurchaseOrder;