
import axios from 'axios'
import { setAlert } from './alert'
import { 
    GET_BRANCH, ADD_BRANCH, GET_BRANCH_DETAIL, EDIT_BRANCH, DELETE_BRANCH, GET_BRANCH_SEARCH
} from './types'

export const getBranch = () => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/branch`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_BRANCH,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getBranchSearch = (keyword, status, page) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/branch/search?kata_kunci=${keyword}&status=${status}&page=${page}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_BRANCH_SEARCH,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getBranchDetail = (id) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/branch/${id}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_BRANCH_DETAIL,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const addBranch = (formData, history) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/branch`
    const token = sessionStorage.getItem('access_token')

    const myData = new FormData()
    myData.set('kode_pos', formData.kode_pos);
    myData.set('name', formData.name);
    myData.set('address', formData.address);
    myData.set('phone', formData.phone);

    try {
        const res = await axios({
            url: endpoint,
            method: "POST",
            data: myData,
            loading: true,
            headers: { 
              'Content-Type': 'multipart/form-data', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: ADD_BRANCH,
            payload: res.data
        })

        dispatch(setAlert("SearchBranch Added", "success"))
        history.push(`/branch`);

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const editBranch = (id, formData, history) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/branch/${id}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "PATCH",
            data: formData,
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: EDIT_BRANCH,
            payload: res.data
        })

        dispatch(setAlert("SearchBranch Edited", "success"))
        history.push(`/branch`);

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const deleteBranch = (id) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/branch/${id}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "DELETE",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: DELETE_BRANCH,
            payload: res.data
        })

        dispatch(setAlert("Cabang Terhapus", "success"))

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}