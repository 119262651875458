import {
    GET_PRODUCT_DASHBOARD,
    GET_STATS_IN_CARD,
    GET_GOLD_PRICE,
    GET_NET_INCOME,
    GET_TRANSACTION_SALES,
    GET_GOLD_PRICE_START,
    GET_GOLD_PRICE_FAIL,
    GET_NET_INCOME_START,
    GET_NET_INCOME_FAIL,
    GET_TRANSACTION_SALES_START,
    GET_TRANSACTION_SALES_FAIL, GET_TRANSACTION_MONEY_START, GET_TRANSACTION_MONEY, GET_TRANSACTION_MONEY_FAIL
} from '../actions/types'

const initialState = {
    products : null,
    cards : null,
    grafikNetIncome : null,
    grafikGoldPrice: null,
    loading: true,
    loadingCard: true,
    loadingGrafik: true,
    loadingGoldPrice: true,
    loadingTransactionSales: true,
    grafikTransactionSales: null,
    grafikTransactionMoney: null,
    loadingV2: false,
    error: {}
}

const reducer = function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_PRODUCT_DASHBOARD:
            return {
                ...state,
                products: payload,
                loading: false
            }
        case GET_STATS_IN_CARD:
            return {
                ...state,
                cards: payload,
                loadingCard: false
            }
        case GET_NET_INCOME_START: return {
            ...state,
            loadingV2: true
        }
        case GET_NET_INCOME:
            return {
                ...state,
                grafikNetIncome: payload,
                loadingGrafik: false,
                loadingV2: false
            }
        case GET_NET_INCOME_FAIL: return {
            ...state,
            loadingV2: false
        }

        case GET_GOLD_PRICE_START: return {
            ...state,
            loadingV2: true
        }
        case GET_GOLD_PRICE:
            return {
                ...state,
                grafikGoldPrice: payload,
                loadingGoldPrice: false,
                loadingV2: false
            }
        case GET_GOLD_PRICE_FAIL: return {
            ...state,
            loadingV2: false
        }
        case GET_TRANSACTION_SALES_START: return {
            ...state,
            loadingV2: true
        }
        case GET_TRANSACTION_SALES:
            return {
                ...state,
                grafikTransactionSales: payload,
                loadingTransactionSales: false,
                loadingV2: false
            }
        case GET_TRANSACTION_SALES_FAIL: return {
            ...state,
            loadingV2: false
        }
        case GET_TRANSACTION_MONEY_START: return {
            ...state,
            loadingV2: true
        }
        case GET_TRANSACTION_MONEY:
            return {
                ...state,
                grafikTransactionMoney: payload,
                loadingV2: false
            }
        case GET_TRANSACTION_MONEY_FAIL: return {
            ...state,
            loadingV2: false
        }
        default:
            return state
    }
}

export default reducer;