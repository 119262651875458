import React, {Fragment, useState} from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import ProductData from "./ProductData";
import TableBody from "@material-ui/core/TableBody";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import * as yup from "yup";
import {Box, Button} from "@material-ui/core";
import {CustomRouterLink} from "../../../../shared/utility";
import {Modal} from "../../../../components/UI";
import FormCreateValidation from "./FormCreateValidation/FormCreateValidation";

const columns = [
  { id: 'No', label: 'No', minWidth: '5%' },
  { id: 'foto_produk', label: 'Foto Produk', minWidth: '31%' },
  { id: 'nama_produk', label: 'Nama Produk', minWidth: '31%' },
  { id: 'kuota_produk', label: 'Kuota Produk', minWidth: '31%' },
];

const schema = yup.object().shape({
  qty: yup.string().required("Quantity tidak boleh kosong, tetapi boleh diisi 0"),
});

const TableProduct = props => {
  const {quotaProduct,classes} = props;

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });

  const list = []
  quotaProduct.map((itemData, index) => (
    list[index] = {
      id_product: itemData.id_product,
      qty: itemData.qty,
    }));
  const [ quota, setQuota ] = useState(list);

  const handleQuotaChange = (e,product, index) => {
    const { value } = e.target;
    const list = [...quota]
    list[index] = {
      id_product : product.id_product,
      [e.target.name] : (value === '') ? 0 : parseInt(value),
    }
    setQuota(list)
  }

  const onSubmit = () => {
    setModalState({
      open: true,
      title: 'Update Kuota Harian',
      maxWidth: 'sm'
    });
    setForm(<FormCreateValidation quota={quota} closedModalDialog={() => closedModalDialog()} classes={classes}/>)
  }

  // console.log(quota);
  let tableBody = (
    <Fragment>
      <TableBody>
        {quotaProduct.map((product, index) => (
          <ProductData
            product={product}
            key={product.id}
            index={index}
            handleQuotaChange={(e) => handleQuotaChange(e,product,index)}
            quota={quota}
            register={register}
            errors={errors}
            // classes={classes}
          />
        ))}

      </TableBody>
    </Fragment>
  );

  const [Form, setForm] = useState('');
  const [modalState, setModalState] = useState({
    open: false,
    title: '',
    maxWidth: 'sm',
  });

  const closedModalDialog = () => {
    setModalState({
      maxWidth: 'sm',
      title: '',
      open: false,
    });
    setForm('');
  }

  return (
    <Fragment>
      <Modal
        maxWidth={modalState.maxWidth}
        open={modalState.open}
        title={modalState.title}
        onCloseModal={closedModalDialog}
        contentModal={Form}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper className={classes.root}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {tableBody}
            </Table>
          </TableContainer>
        </Paper>
        <br/>
        <Box display="flex" justifyContent="flex-end">
          <Box>
            <Button
              variant={"outlined"}
              size={"medium"}
              className={classes.cancelButton}
              component={CustomRouterLink}
              to='/quota-product'
            >
              Batal
            </Button>
          </Box>
          <Box>
            <Button
              type={"submit"}
              variant={"contained"}
              size={"medium"}
              className={classes.submitButton}
            >
              Simpan
            </Button>
          </Box>
        </Box>
      </form>
    </Fragment>
  );
};

export default TableProduct;