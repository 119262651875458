import * as actionTypes from '../actions/types'
import { updateObject } from '../shared/utility'

const initialState = {
  rounds: {},
  changing: 0,
  loading: false,
  error: {},
}

const fetchRoundsStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const fetchRoundsSuccess = (state, action) => {
  return updateObject(state, {
    rounds: action.rounds,
    loading: false
  })
}

const fetchRoundsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const storeRoundStart = (state, action) => {
  return updateObject(state, {
    error: {},
    loading: true
  })
}

const storeRoundSuccess = (state, action) => {
  // const newOrder = updateObject(action.storeData, { id: action.storeDataId })
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
}

const storeRoundFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  })
}

const updateRoundStart = (state, action) => {
  return updateObject(state, {
    error: {},
    loading: true
  })
}

const updateRoundSuccess = (state, action) => {
  // const newOrder = updateObject(action.storeData, { id: action.storeDataId })
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
}

const updateRoundFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  })
}

const deleteRoundStart = (state, action) => {
  return updateObject(state, {
    error: {},
    loading: true
  })
}

const deleteRoundSuccess = (state, action) => {
  // const newOrder = updateObject(action.storeData, { id: action.storeDataId })
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
}

const deleteRoundFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  })
}

const clearErrorRound = (state, action) => {
  return updateObject(state, {
    error: {},
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ROUNDS_START:
      return fetchRoundsStart(state, action)
    case actionTypes.FETCH_ROUNDS_SUCCESS:
      return fetchRoundsSuccess(state, action)
    case actionTypes.FETCH_ROUNDS_FAIL:
      return fetchRoundsFail(state, action)
    case actionTypes.STORE_ROUND_START:
      return storeRoundStart(state, action)
    case actionTypes.STORE_ROUND_SUCCESS:
      return storeRoundSuccess(state, action)
    case actionTypes.STORE_ROUND_FAIL:
      return storeRoundFail(state, action)
    case actionTypes.UPDATE_ROUND_START:
      return updateRoundStart(state, action)
    case actionTypes.UPDATE_ROUND_SUCCESS:
      return updateRoundSuccess(state, action)
    case actionTypes.UPDATE_ROUND_FAIL:
      return updateRoundFail(state, action)
    case actionTypes.DELETE_ROUND_START:
      return deleteRoundStart(state, action)
    case actionTypes.DELETE_ROUND_SUCCESS:
      return deleteRoundSuccess(state, action)
    case actionTypes.DELETE_ROUND_FAIL:
      return deleteRoundFail(state, action)
    case actionTypes.CLEAR_ERROR_ROUND:
      return clearErrorRound(state, action)
    default: return state
  }
}

export default reducer