import React,{Fragment} from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import ProductData from "./ProductData";
import TableBody from "@material-ui/core/TableBody";

const columns = [
  { id: 'No', label: 'No', minWidth: '5%' },
  { id: 'foto_produk', label: 'Foto Produk', minWidth: '31%' },
  { id: 'nama_produk', label: 'Nama Produk', minWidth: '31%' },
  { id: 'kuota_produk', label: 'Kuota Produk', minWidth: '31%' },
  // { id: 'detail', label: 'Detail', minWidth: '12%' },

];

const TableProduct = props => {
  const {quotaProduct,classes} = props;
  let tableBody = (
    <Fragment>
      <TableBody>
        {quotaProduct.map((product, index) => (
          <ProductData
            product={product}
            key={product.id}
            index={index}
            // handleQuotaChange={(e) => handleQuotaChange(e,product,index)}
            // quota={quota}
            // register={register}
            // errors={errors}
            // classes={classes}
          />
        ))}

      </TableBody>
    </Fragment>
  );

  return (
    <Fragment>
      <Paper className={classes.root}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
      </Paper>
    </Fragment>
  );
};

export default TableProduct;