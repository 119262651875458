import React, { useEffect, Fragment, forwardRef, useState } from 'react';
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
// import CapitalizedText from '../../components/layout/CapitalizedText'
import { Link as RouterLink } from 'react-router-dom'
import { connect } from 'react-redux';
import { getUser } from '../../actions/user'
import { 
	IconButton,
    Grid,
    Typography,
    Hidden,
    Fab,
    InputBase,
    Divider,
    Paper
} from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle'
import SearchIcon from '@material-ui/icons/Search'

import ListUser from './ListUser'

const CustomRouterLink = forwardRef((props, ref) => (
    <div
      ref={ref}
      style={{ flexGrow: 1 }}
    >
      <RouterLink {...props} />
    </div>
));

const useStyles = makeStyles(theme => ({
	root: {
        padding: theme.spacing(4),
	},
	container: {
		maxHeight: 440,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	statusNotActive: {
		backgroundColor: 'red',
		color: '#FFFFFF'
    },
    btnAddCustomer: {
        backgroundColor: '#0277BD',
        '&:hover' : {
            backgroundColor: '#2092D6'
        },
        color: '#FFFFFF',
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    searchRoot: {
		padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
		width: 'auto',
		// marginTop: theme.spacing(2)
	},
	input: {
        marginLeft: theme.spacing(1),
            flex: 1,
        },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(2),
    },
}));

const User = ({ 
	getUser, 
	user : { users, loading } 
}) => {
    const classes = useStyles();
    
    const [ keyword, setKeyword ] = useState('')

    const handleChangeKeyword = event => {
        setKeyword(event.target.value)
    }

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	useEffect(() => {
		getUser(keyword, page+1)
	}, [loading, getUser, keyword, page]);

	return loading || users == null ? 
		<Backdrop className={classes.backdrop} open>
			<CircularProgress color="inherit" />
		</Backdrop> 
		: 
		<Fragment>
            <div className={classes.root}>
                <div className={classes.row}>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid item>  
                            <Typography variant="h4">Pengguna</Typography>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.row}>
                <Grid
                    container
                    spacing={2}
                    justify='space-between'
                >
                    <Hidden only={['xs','sm']}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Fab variant="extended" className={classes.btnAddCustomer} component={CustomRouterLink} to='/user/create'>
                                <AddCircle className={classes.extendedIcon} />
                                Tambah User
                            </Fab>
                        </Grid>
                    </Hidden>
                    <Hidden only={['md','lg','xl']}>
                        <Fab color="primary" aria-label="add" className={classes.fab} component={CustomRouterLink} to='/user/create'>
                            <AddCircle/>
                        </Fab>
                    </Hidden>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Paper component="form" className={classes.searchRoot}>
                            <IconButton type="button" className={classes.iconButton} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                            <Divider className={classes.divider} orientation="vertical" />
                            <InputBase
                                className={classes.input}
                                name="pesan"
                                placeholder="Cari User"
                                inputProps={{ 'aria-label': 'Cari User' }}
                                onChange={handleChangeKeyword}
                                value={keyword || ''}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
                <div className={classes.row}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid 
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                    >
                        <ListUser 
                            users={users} 
                            rowsPerPage={rowsPerPage}
                            page={page}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
                </div>
            </div>
		</Fragment>
	
}

User.propTypes = {
    getUser: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps, { getUser })(User)