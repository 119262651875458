import * as yup from "yup";

const schema = yup.object().shape({
	name: yup.string(),
	phone: yup.string(),
	address: yup.string(),
	// status: yup.string().required("Tipe Customer tidak boleh kosong"),
	// is_active: yup.string().required("Status Customer tidak boleh kosong"),
});

export default schema;