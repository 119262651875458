import React,{Fragment} from "react";
import {Box, Button, Typography} from "@material-ui/core";

const OrderConfirmationSent = props => {
  const {
    classes,
    closedModalDialog,
    data,
    sent
  } = props;
  return (
    <Fragment>
      <Box display={"flex"} justifyContent={"center"} className={classes.boxMarginRow}>
        <Box>
          <Typography variant={"h6"} align={"center"}>
            Purchase Order dari cabang akan dikirim oleh pusat ke alamat cabang. Pastikan produk yang akan dikirim sesuai dengan rincian pesanan.
          </Typography>
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"center"} className={classes.boxMarginRow}>
        <Box>
          <Typography variant={"h5"} align={"center"}>
            Yakin ingin melakukan Pengiriman sekarang?
          </Typography>
        </Box>
      </Box>
      <br/>
      <Box display={"flex"} justifyContent="flex-end">
        <Box m={1}>
          <Button
            variant={"outlined"}
            size={"small"}
            className={classes.btnCancel}
            onClick={closedModalDialog}
          >
            Batal
          </Button>
        </Box>
        <Box m={1}>
          <Button
            variant={"contained"}
            size={"small"}
            className={classes.btnPurple}
            onClick={() => sent(data)}
          >
            Kirim
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
};

export default OrderConfirmationSent;