import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    TextField,
    MenuItem,
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import C3Chart from "react-c3js";

// Redux
import { connect } from 'react-redux'
import { getGrafikGoldPrice } from '../../../../actions/dashboard'
import { fetchProducts } from '../../../../actions'
import Skeleton from '@material-ui/lab/Skeleton';
import {isEmpty} from "../../../../shared/utility";
import palette from "../../../../theme/palette";
import * as d3 from 'd3-format';

const locale =  {
  "decimal": ".",
  "thousands": ",",
  "grouping": [3],
  "currency": ["Rp", ""]
}
d3.formatDefaultLocale(locale);

const GrafikGoldPrice = (props) => {
    const { getGrafikGoldPrice, fetchProducts, products, dashboard : { loadingV2, grafikGoldPrice} } = props

    const [selectedDate ] = useState(new Date());

    const [ formState, setFormState ] = useState({
        id_product : '',
        tipe_customer : '3'
    })

    const handleChange = (event) => {
        const name = event.target.name;
        setFormState({
            ...formState,
            [name]: event.target.value
        })
    }

    const submitDefault = moment().subtract(7,'d').format('YYYY-MM-DD');
    const submitDefaultEndDate = moment().format('YYYY-MM-DD');
    const [ startDate, setStartDate ] = useState({
        submit: {
            submit: submitDefault
        },
        view: {
            view: moment().subtract(7,'d').format('YYYY-MM-DD')
        }
    });
    const handleStartDate = (date) => {
    const changeDate = moment(date).format('YYYY-MM-DD');
        setStartDate(startDate => ({
            ...startDate,
                submit: {
                    submit: changeDate
            },
                view: {
                    view: date
            }
        }));
    };

    const [ endDate, setEndDate ] = useState({
        submit: {
            submit: submitDefaultEndDate
        },
        view: {selectedDate}
    });
    const handleEndDate = (date) => {
    const all = moment(date).format('YYYY-MM-DD');
        setEndDate(endDate => ({
            ...endDate,
            submit: {
                submit: all
            },
            view: {
                view: date
            }
        }));
    };

    useEffect(() => {
        getGrafikGoldPrice(formState.id_product, formState.tipe_customer, startDate.submit.submit, endDate.submit.submit)
      fetchProducts('gold')
    }, [getGrafikGoldPrice, startDate, endDate, formState,fetchProducts])

    let productsOptions = (!isEmpty(products) ?
      (
        products.map(product => (
          // console.log(product)
          <MenuItem value={product.id}>{`${product.name} ${product.weight} ${product.unit}`}</MenuItem>
        ))
      )
      :
      '')

  //set chart
  const colors = {
    GoldPrice: palette.warning.light,
  }

    let goldPrice = ['GoldPrice'];
    let x = ['x']
    let yMin = 0;
    let yMax = 1;

    if(grafikGoldPrice !== null) {
      for (let i = 0; i < grafikGoldPrice.data.length; i++) {
        const newDate = new Date(grafikGoldPrice.data[i].date)
        x.push(moment(newDate).format('YYYY-MM-DD HH:mm:ss'));
        goldPrice.push(grafikGoldPrice.data[i].harga);
      }

      if (grafikGoldPrice.data.length > 0) {
        const succesValue = goldPrice.slice(1);
        yMax = (Math.max(...succesValue))
        yMin = (Math.min(...succesValue))
      }
    }

    const area = {
      data: {
        x: 'x',
        xFormat: '%Y-%m-%d %H:%M:%S',
        columns: [
          x,
          goldPrice
        ],
        types: {
          // Primary: 'area',
          GoldPrice: 'area-spline',
        },
      },
      color: {
        pattern: [colors.GoldPrice],
      },
      subchart: {
        show: true,
      },
      axis: {
        x: {
          type: 'timeseries',
          label: {
            text: 'Tanggal',
            position: 'outer-center'
          },
          tick: {
            format: '%d-%m'
          }
        },
        y: {
          min: yMin,
          max: yMax,
          label: {
            text: 'Gold Price',
            position: 'outer-middle'
          },
          tick: {
            format: function (y) {
              const format =  d3.format("$,");
              return format(y);
            }
          }
        }
      },
      tooltip: {
        format: {
          title: function (title) {
            return `Tanggal ${moment(title).format('DD/MMM/YYYY HH:mm')}`;
          }
        }
      }
    }
    //end set chart
  console.log(area);
    
    return(loadingV2 ? <Skeleton variant="rect" height={100}></Skeleton> :
        <div>
                <Card>
                    <CardHeader 
                        title={`Grafik Harga Emas`}
                    />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid 
                                item
                                lg={3}
                                md={3}
                                sm={12}
                                xs={12}
                            >
                                {/* <InputLabel></InputLabel> */}
                                <TextField 
                                    select
                                    fullWidth
                                    name="id_product"
                                    label="Produk"
                                    defaultValue={formState.id_product}
                                    onChange={handleChange}
                                >
                                    {productsOptions}
                                    {/*<MenuItem value="1">Emas 0,1 Gram</MenuItem>*/}
                                    {/*<MenuItem value="2">Emas 0,2 Gram</MenuItem>*/}
                                    {/*<MenuItem value="3">Emas 0,5 Gram</MenuItem>*/}
                                    {/*<MenuItem value="4">Emas 1 Gram</MenuItem>*/}
                                    {/*<MenuItem value="5">Emas 2 Gram</MenuItem>*/}
                                    {/*<MenuItem value="6">Emas 5 Gram</MenuItem>*/}
                                </TextField>
                            </Grid>
                            <Grid 
                                item
                                lg={3}
                                md={3}
                                sm={12}
                                xs={12}
                            >
                                <TextField 
                                    select
                                    fullWidth
                                    label="Tipe Customer"
                                    name="tipe_customer"
                                    defaultValue={formState.tipe_customer}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="0">Cabang</MenuItem>
                                    <MenuItem value="1">AOG</MenuItem>
                                    <MenuItem value="2">MOG</MenuItem>
                                    <MenuItem value="3">Umum</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid 
                                item
                                lg={3}
                                md={3}
                                sm={12}
                                xs={12}
                            >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker 
                                        fullWidth
                                        label="Tanggal Awal"
                                        variant="outlined"
                                        name="start_date"
                                        format="dd MMMM yyyy"
                                        value={startDate.view.view} 
                                        onChange={handleStartDate} 
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid 
                                item
                                lg={3}
                                md={3}
                                sm={12}
                                xs={12}
                            >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker 
                                        fullWidth
                                        label="Tanggal Akhir"
                                        variant="outlined"
                                        name="end_date"
                                        format="dd MMMM yyyy"
                                        value={endDate.view.view} 
                                        onChange={handleEndDate} 
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardContent>
                      <C3Chart data={area.data} color={area.color} subchart={area.subchart} axis={area.axis} tooltip={area.tooltip}/>
                    </CardContent>
                </Card>
        </div>
    )
}

const mapStateToProps = state => ({
    dashboard: state.dashboard,
    products: state.product.productsV2
})

export default connect(mapStateToProps, {getGrafikGoldPrice,fetchProducts})(GrafikGoldPrice)