import React, {Fragment} from "react";
import {Main} from './styles';
import {Grid} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import * as PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {FormNotification, Variable} from "./Components";

import * as actions from '../../actions';
import {connect} from "react-redux";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const Whatsapp = props => {
  const {
    onStoreNotification,
    errorForm,
  } = props;

  const classes = Main();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onSubmit = data => {
    onStoreNotification(data);
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.row}>
          <Typography variant={"h4"} className={classes.tagMenu}>
            Pengaturan Whatsapp
          </Typography>
        </div>

          <Grid container spacing={2}>
            <Grid item xl={8} md={8} sm={12} xs={12}>
              <div className={classes.row}>
              <div className={classes.tabRoot}>
                <AppBar position="static" color="default">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab label="Menunggu Konfirmasi" {...a11yProps(0)} />
                    <Tab label="Diproses" {...a11yProps(4)} />
                    <Tab label="Dikirim" {...a11yProps(2)} />
                    <Tab label="Ditolak" {...a11yProps(5)} />
                    <Tab label="Selesai" {...a11yProps(1)} />
                  </Tabs>
                </AppBar>
                {/*<Paper>*/}
                  <TabPanel value={value} index={0}>
                    <FormNotification classes={classes} status={"0"} submit={(data) => onSubmit(data)} errorForm={errorForm}/>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <FormNotification classes={classes} status={"4"} submit={(data) => onSubmit(data)} errorForm={errorForm}/>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <FormNotification classes={classes} status={"2"} submit={(data) => onSubmit(data)} errorForm={errorForm}/>
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <FormNotification classes={classes} status={"5"} submit={(data) => onSubmit(data)} errorForm={errorForm}/>
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <FormNotification classes={classes} status={"1"} submit={(data) => onSubmit(data)} errorForm={errorForm}/>
                  </TabPanel>
                {/*</Paper>*/}
              </div>
              </div>
            </Grid>
            <Grid item xl={4} md={4} sm={12} xs={12}>
              <TabPanel value={value} index={0}>
                <Variable classes={classes} status={"0"}/>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Variable classes={classes} status={"4"}/>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Variable classes={classes} status={"2"}/>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Variable classes={classes} status={"5"}/>
              </TabPanel>
              <TabPanel value={value} index={4}>
                <Variable classes={classes} status={"1"}/>
              </TabPanel>
            </Grid>
          </Grid>

      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.notification.loading,
    errorForm: state.notification.error,
    notification: state.notification.notification,
    changing: state.notification.changing
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onStoreNotification: (storeData) => dispatch(actions.storeNotification(storeData)),
    onGetNotification: (status) => dispatch(actions.getNotification(status))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Whatsapp);