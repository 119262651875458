import {
    GET_PURCHASE_ORDER, GET_PURCHASE_ORDER_DETAIL, UPDATE_PURCHASE_ORDER_STATUS
} from '../actions/types'

import {updateObject} from "../shared/utility";
import * as actionTypes from "../actions/types";

const initialState = {
    purchaseOrder : {},
    purchaseOrders : null,
    purchaseOrderDetails: null,
    loading: true,
    error: {},
    counting: 0,
    purchaseOrdersV2: {},
    purchaseOrderV2: {},
}

const fetchPurchaseOrdersStart = (state, action) => {
    return updateObject(state, { error: {}, loading: true })
}

const fetchPurchaseOrdersSuccess = (state, action) => {
    return updateObject(state, {
        purchaseOrdersV2: action.purchase_orders,
        loading: false
    })
}

const fetchPurchaseOrdersFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false })
}

const getPurchaseOrderStart = (state, action) => {
    return updateObject(state, { error: {}, loading: true })
}

const getPurchaseOrderSuccess = (state, action) => {
    return updateObject(state, {
        purchaseOrderV2: action.purchase_order,
        loading: false
    })
}

const getPurchaseOrderFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false })
}

const approvePurchaseOrderStart = (state, action) => {
    return updateObject(state, { error: {}, loading: true })
}

const approvePurchaseOrderSuccess = (state, action) => {
    return updateObject(state, {
        counting: state.counting+1,
        loading: false
    })
}

const approvePurchaseOrderFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false })
}

const sentPurchaseOrderStart = (state, action) => {
    return updateObject(state, { error: {}, loading: true })
}

const sentPurchaseOrderSuccess = (state, action) => {
    return updateObject(state, {
        counting: state.counting+1,
        loading: false
    })
}

const sentPurchaseOrderFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false })
}

const downloadPurchaseOrdersStart = (state, action) => {
    return updateObject(state, { error: {}, loading: true })
}

const downloadPurchaseOrdersSuccess = (state, action) => {
    return updateObject(state, {
        // purchaseOrdersV2: action.purchase_orders,
        loading: false
    })
}

const downloadPurchaseOrdersFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false })
}

const downloadPurchaseOrdersOldStart = (state, action) => {
    return updateObject(state, { error: {}, loading: true })
}

const downloadPurchaseOrdersOldSuccess = (state, action) => {
    return updateObject(state, {
        // purchaseOrdersV2: action.purchase_orders,
        loading: false
    })
}

const downloadPurchaseOrdersOldFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false })
}

const reducer = function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_PURCHASE_ORDER:
            return {
                ...state,
                purchaseOrders: payload,
                loading: false
            }
        case GET_PURCHASE_ORDER_DETAIL:
            return {
                ...state,
                purchaseOrderDetails: payload,
                loading: false
            }
        case UPDATE_PURCHASE_ORDER_STATUS:
            return {
                ...state,
                purchaseOrder: payload,
                loading: false,
                counting : initialState.counting += 1
            }
        case actionTypes.FETCH_PURCHASE_ORDERS_START: return fetchPurchaseOrdersStart(state, action)
        case actionTypes.FETCH_PURCHASE_ORDERS_SUCCESS: return fetchPurchaseOrdersSuccess(state, action)
        case actionTypes.FETCH_PURCHASE_ORDERS_FAIL: return fetchPurchaseOrdersFail(state, action)
        case actionTypes.GET_PURCHASE_ORDER_START: return getPurchaseOrderStart(state, action)
        case actionTypes.GET_PURCHASE_ORDER_SUCCESS: return getPurchaseOrderSuccess(state, action)
        case actionTypes.GET_PURCHASE_ORDER_FAIL: return getPurchaseOrderFail(state, action)
        case actionTypes.APPROVE_PURCHASE_ORDER_START: return approvePurchaseOrderStart(state, action)
        case actionTypes.APPROVE_PURCHASE_ORDER_SUCCESS: return approvePurchaseOrderSuccess(state, action)
        case actionTypes.APPROVE_PURCHASE_ORDER_FAIL: return approvePurchaseOrderFail(state, action)
        case actionTypes.SENT_PURCHASE_ORDER_START: return sentPurchaseOrderStart(state, action)
        case actionTypes.SENT_PURCHASE_ORDER_SUCCESS: return sentPurchaseOrderSuccess(state, action)
        case actionTypes.SENT_PURCHASE_ORDER_FAIL: return sentPurchaseOrderFail(state, action)
        case actionTypes.DOWNLOAD_PURCHASE_ORDERS_START: return downloadPurchaseOrdersStart(state, action)
        case actionTypes.DOWNLOAD_PURCHASE_ORDERS_SUCCESS: return downloadPurchaseOrdersSuccess(state, action)
        case actionTypes.DOWNLOAD_PURCHASE_ORDERS_FAIL: return downloadPurchaseOrdersFail(state, action)
        case actionTypes.DOWNLOAD_PURCHASE_ORDERS_OLD_START: return downloadPurchaseOrdersOldStart(state, action)
        case actionTypes.DOWNLOAD_PURCHASE_ORDERS_OLD_SUCCESS: return downloadPurchaseOrdersOldSuccess(state, action)
        case actionTypes.DOWNLOAD_PURCHASE_ORDERS_OLD_FAIL: return downloadPurchaseOrdersOldFail(state, action)
        default:
            return state
    }
}

export default reducer;