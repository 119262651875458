import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  TextField,
  MenuItem,
  Grid,
  Paper,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  // Divider,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

// Redux
import { connect } from 'react-redux';
import {
  getProduct,
  fetchProducts,
  importNewPrice,
  importBuybackPrice,
} from '../../actions/product';
import {
  getProductPrice,
  deleteProductPrice,
} from '../../actions/productPrice';

import ListProductPrice from './ListProductPrice';
import ProductPriceBuyback from './ProductPriceBuyBack';
import { isEmpty } from '../../shared/utility';
import { Loading } from '../../components/UI';
import UploadPrice from './UploadPrice';
import { setAlert } from '../../actions/alert';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  container: {
    maxHeight: 440,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  statusPrice: {
    width: '250px',
  },
  searchRoot: {
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    // marginTop: theme.spacing(2)
  },
  input: {
    margin: theme.spacing(1),
    flex: 1,
    height: 28,
  },
}));

const ProducPrice = (props) => {
  const {
    fetchProducts,
    product: {
      loading,
      counting,
      productsV2,
      loadingV2,
      loadingImportNewPrice,
      loadingImportBuybackPrice,
    },
    importNewPrice,
    importBuybackPrice,
  } = props;
  const classes = useStyles();
  const [id_product, setIdProduct] = React.useState('1');

  const [openModalPriceBuyback, setOpenPriceBuyback] = React.useState(false);
  const [openModalNewPrice, setOpenNewPrice] = React.useState(false);

  const [fileImport, setFileImport] = React.useState(null);

  const handleChangeProduct = (event) => {
    setIdProduct(event.target.value);
  };

  const handleUploadNewPrice = () => {
    importNewPrice(fileImport);

    setFileImport(null);
    setOpenNewPrice(false);
  };

  const handleUploadBuybackPrice = () => {
    importBuybackPrice(fileImport);

    setFileImport(null);
    setOpenPriceBuyback(false);
  };

  useEffect(() => {
    // getProduct('gold')
    fetchProducts('gold');
  }, [fetchProducts, counting]);

  return !loading || loadingV2 ? (
    <Loading />
  ) : (
    <div className={classes.root}>
      <div className={classes.row}>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant='h4'>Manajemen Harga Produk</Typography>
          </Grid>
        </Grid>
      </div>
      <div className={classes.row}>
        <Grid container spacing={2} justify='space-between'>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <Box>
              <Typography>Pilih Produk</Typography>
              {!isEmpty(productsV2) ? (
                <Paper component='form' className={classes.searchRoot}>
                  <TextField
                    select
                    className={classes.input}
                    name='status'
                    value={id_product}
                    onChange={handleChangeProduct}
                  >
                    {productsV2.map((item) => (
                      <MenuItem value={item.id}>
                        {item.name} {item.weight} {item.unit}
                      </MenuItem>
                    ))}
                  </TextField>
                </Paper>
              ) : (
                <Skeleton variant='rect' height={150}></Skeleton>
              )}
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <Box
              display={'flex'}
              gridGap={'10px'}
              justifyContent={'flex-end'}
              alignItems={'flex-end'}
              height={'100%'}
            >
              <Button
                color='primary'
                variant='contained'
                onClick={() => setOpenPriceBuyback(true)}
              >
                Upload Harga Buyback Baru
              </Button>
              <Button
                color='primary'
                variant='contained'
                onClick={() => setOpenNewPrice(true)}
              >
                Upload Harga Baru
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
      <div className={classes.row}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {!isEmpty(productsV2) ? (
              <ListProductPrice id_product={id_product} />
            ) : (
              <Skeleton variant='rect' height={300}></Skeleton>
            )}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {!isEmpty(productsV2) ? (
              <ProductPriceBuyback id_product={id_product} />
            ) : (
              <Skeleton variant='rect' height={300}></Skeleton>
            )}
          </Grid>
        </Grid>
      </div>

      <Dialog open={openModalPriceBuyback}>
        <DialogTitle>
          <Typography variant='h5' style={{ fontWeight: 'bold' }}>
            Upload Harga Buyback
          </Typography>
        </DialogTitle>
        <DialogContent>
          <UploadPrice setState={setFileImport} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPriceBuyback(false)}>Cancel</Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={handleUploadBuybackPrice}
            disabled={fileImport === null}
          >
            {loadingImportBuybackPrice ? 'Loading...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openModalNewPrice}>
        <DialogTitle>
          <Typography variant='h5' style={{ fontWeight: 'bold' }}>
            Upload Harga Baru
          </Typography>
        </DialogTitle>
        <DialogContent>
          <UploadPrice setState={setFileImport} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNewPrice(false)}>Cancel</Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={handleUploadNewPrice}
            disabled={fileImport === null}
          >
            {loadingImportNewPrice ? 'Loading...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  productPrice: state.productPrice,
  product: state.product,
});

export default connect(mapStateToProps, {
  getProductPrice,
  deleteProductPrice,
  getProduct,
  fetchProducts,
  importNewPrice,
  importBuybackPrice,
  setAlert,
})(ProducPrice);
