import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
// import EditIcon from '@material-ui/icons/Edit'
// import DetailIcon from '@material-ui/icons/Search'
import CapitalizedText from '../../../components/layout/CapitalizedText'

import { 
	// Tooltip,
	// IconButton,
    Chip,
    Card,
    CardContent,
    CardHeader,
} from '@material-ui/core';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Edit} from "@material-ui/icons";
import {CustomRouterLink} from "../../../shared/utility";

const columns = [
  { id: 'id', label: 'No', minWidth: 100 },
  { id: 'nama', label: 'Nama', minWidth: 150 },
  { id: 'email', label: 'Email', minWidth: 200 },
  { id: 'branch', label: 'Nama Cabang', minWidth: 200 },
  { id: 'status_aktif', label: 'Status Aktif', minWidth: 100 },
  { id: 'action', label: 'Action', minWidth: 100 },
//   { id: 'action', label: 'Action', minWidth: 140 },
  
];

const useStyles = makeStyles(theme => ({
	root: {
        padding: theme.spacing(4),
	},
	container: {
		maxHeight: 440,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	statusNotActive: {
		backgroundColor: 'red',
		color: '#FFFFFF'
	}
}));

const ListUser = (props) => {
    const { users, page, handleChangePage, handleChangeRowsPerPage, rowsPerPage } = props
	const classes = useStyles();
	
    var no = users.from

	return (
		<Fragment>
            <Card>
                <CardHeader title="List Akun User" />
                <CardContent>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
                        <TableHead>
                            <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                                >
                                {column.label}
                                </TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.data.map((user) => (
                                <TableRow key={user.id}>
                                    <TableCell>
                                        {no++}
                                    </TableCell>
                                    <TableCell>
                                        <CapitalizedText text={user.name} />
                                    </TableCell>
                                    <TableCell>
                                        {user.email}
                                    </TableCell>
                                    <TableCell>
                                        {user.branch.name}
                                    </TableCell>
                                    <TableCell>
                                        {user.is_active === '1' ? (
                                            <Chip label='Aktif' color="secondary" />
                                        ): (
                                            <Chip label='Tidak Aktif' className={classes.statusNotActive} />
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {/*<Tooltip title="Detail user">*/}
                                        {/*        <IconButton aria-label="detail">*/}
                                        {/*            <DetailIcon />*/}
                                        {/*        </IconButton>*/}
                                        {/*</Tooltip>*/}
                                        <Tooltip title="Edit user">
                                          <IconButton aria-label="edit" component={CustomRouterLink} to={`user/${user.id}`}>
                                              <Edit/>
                                          </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[15]}
                        component="div"
                        count={users.total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </CardContent>
            </Card>
		</Fragment>
    )
	
}

export default ListUser