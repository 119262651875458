
import axios from 'axios'
import axiosOrders from '../axios-orders'
import { setAlert } from './alert'
import { 
    GET_USER,
    GET_USER_ROLE,
    ADD_USER
} from './types'
import * as actionTypes from './types';

export const getUser = (keyword, page) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/user/search?kata_kunci=${keyword}&page=${page}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_USER,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getUserRole = () => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user_role`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_USER_ROLE,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const addUser = (formData, image, history) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/user`
    const token = sessionStorage.getItem('access_token')

    const myData = new FormData()
    myData.set('id_branch', formData.id_branch)
    myData.set('id_role', formData.id_role)
    myData.set('name', formData.name)
    myData.set('email', formData.email)
    myData.set('password', formData.password)
    myData.set('image', image)
    myData.set('is_active', 1)

    try {
        const res = await axios({
            url: endpoint,
            method: "POST",
            data: myData,
            loading: true,
            headers: { 
              'Content-Type': 'multipart/form-data', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: ADD_USER,
            payload: res.data
        })

        dispatch(setAlert("New User Added", "success"))
        history.push(`/user`);

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getUserDetailStart = () => {
    return {
        type: actionTypes.GET_USER_DETAIL_START
    }
}

export const getUserDetailSuccess = (user) => {
    return {
        type: actionTypes.GET_USER_DETAIL_SUCCESS,
        user: user
    }
}

export const getUserDetailFail = (errors) => {
    return {
        type: actionTypes.GET_USER_DETAIL_FAIL,
        errors: errors
    }
}

export const getUserDetail = (id) => {
    return dispatch => {
        dispatch(getUserDetailStart())
        axiosOrders.get(`admin/user/${id}`,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
            }
        })
          .then(res => {
              dispatch(getUserDetailSuccess(res.data))
          })
          .catch(err => {
              dispatch(getUserDetailFail(err.response.data.message))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}

export const updateUserStart = () => {
    return {
        type: actionTypes.UPDATE_USER_START
    }
}

export const updateUserSuccess = (user) => {
    return {
        type: actionTypes.UPDATE_USER_SUCCESS,
        user: user
    }
}

export const updateUserFail = (errors) => {
    return {
        type: actionTypes.UPDATE_USER_FAIL,
        errors: errors
    }
}

export const updateUser = (id, storeData, history) => {
    return dispatch => {
        dispatch(updateUserStart())
        axiosOrders.put(`admin/user/${id}/information`, storeData,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
            }
        })
          .then(res => {
              dispatch(updateUserSuccess(res.data))
              dispatch(setAlert(res.data.message, "success"))
              history.push('/user')
          })
          .catch(err => {
              dispatch(updateUserFail(err.response.data.errors ? err.response.data.errors : ''))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}