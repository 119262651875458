import axios from 'axios'
import { setAlert } from './alert'
import { 
    GET_PRODUCT_PRICE,
    ADD_PRODUCT_PRICE,
    UPDATE_PRODUCT_PRICE,
    DELETE_PRODUCT_PRICE
} from './types'

export const getProductPrice = (id, status) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/product/${id}/filter?current=active`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_PRODUCT_PRICE,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const addProductPrice = (id_product, formData, history) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/product/${id_product}/product_price`
    const token = sessionStorage.getItem('access_token')

    const myData = {
        id_product : id_product,
        sell_price : formData.sell_price,
        buy_price : formData.buy_price,
        buyback_price : formData.buyback_price,
        type : formData.type
    }

    try {
        const res = await axios({
            url: endpoint,
            method: "POST",
            data: myData,
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: ADD_PRODUCT_PRICE,
            payload: res.data
        })

        dispatch(setAlert("New Product Price Added", "success"))
        history.push(`/product-price`);

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const updateProductPrice = (id_price, id_product, formData, history) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/product/${id_product}/product_price/${id_price}`
    const token = sessionStorage.getItem('access_token')

    const myData = {
        id_product : id_product,
        sell_price : formData.sell_price,
        buy_price : formData.buy_price,
        buyback_price : formData.buyback_price,
        type : formData.type
    }

    try {
        const res = await axios({
            url: endpoint,
            method: "PATCH",
            data: myData,
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: UPDATE_PRODUCT_PRICE,
            payload: res.data
        })

        dispatch(setAlert("Product Price Edited", "success"))
        history.push(`/product/edit/${id_product}`);

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const deleteProductPrice = (id_price, id) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/product/${id}/product_price/${id_price}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "DELETE",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: DELETE_PRODUCT_PRICE,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}