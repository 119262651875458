import React, {Fragment, useState} from "react";
import useStyles from "./styles/useStyles";
import {Grid, Paper, Typography} from "@material-ui/core";
import ListBranchPurchaseQuota from "./ListBranchPurchaseQuota";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import {Search} from "@material-ui/icons";

const BranchPurchaseQuota = props => {
  const classes = useStyles();

  const [formSearch,setFormSearch] = useState({
    search: ''
  });

  const handleSearchChange = (event) => {
    const target = event.target.name;
    const {value} = event.target;
    event.persist()

    setFormSearch((formState) => ({
      ...formSearch,
      [target]: value
    }));
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.row}>
          <Grid container spacing={2} justify={"space-between"}>
            <Grid item>
              <Typography variant={"h4"} className={classes.tagMenu}>
                Pengaturan Kuota Pembelian Cabang
              </Typography>
            </Grid>
            {/*<Grid item>*/}
            {/*  <Link to='/quota-product/create'>*/}
            {/*    <Button variant={"contained"} size={"medium"} startIcon={React.cloneElement(<AddIcon/>)} className={classes.addButton} onClick={add}>Kuota Harian</Button>*/}
            {/*  </Link>*/}
            {/*</Grid>*/}
          </Grid>
        </div>

        <div className={classes.row}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper variant={"outlined"} className={classes.paperRoot}>
                <Grid container justify={"space-between"}>
                  <Grid item>
                    <div className={classes.paperTag}>
                      <Typography variant={"h5"} className={classes.tagMenu}>
                        List Kuota Pembelian Cabang
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item>
                    <Paper component="form" className={classes.rootSearch}>
                      <InputBase
                        className={classes.input}
                        placeholder="Cari Nama Cabang"
                        inputProps={{ 'aria-label': 'search google maps' }}
                        defaultValue={formSearch.search}
                        onChange={handleSearchChange}
                        name={'search'}
                      />
                      <Divider className={classes.divider} orientation="vertical" />
                      <IconButton type="submit" className={classes.iconButton} aria-label="search">
                        <Search />
                      </IconButton>
                    </Paper>
                  </Grid>
                </Grid>
                <div>
                  <ListBranchPurchaseQuota classes={classes} formSearch={formSearch}/>
                  {/*<ListRound edit={(data) => edit(data)} deleted={(data) => deleted(data)}/>*/}
                  {/*<ListResetStock/>*/}
                </div>

              </Paper>

            </Grid>
          </Grid>
        </div>
      </div>
    </Fragment>
  );
};

export default BranchPurchaseQuota;