import React,{Fragment} from "react";
import {Box, Button} from "@material-ui/core";
import * as actions from "../../../actions";
import {connect} from "react-redux";

const DeleteRound = props => {
  const {round, classes, closedModalDialog,onDeleteRound} = props;
  console.log(props);
  const deleted = (id) => {
    onDeleteRound(id);
  }
  return (
    <Fragment>
      <Box display={"flex"} justifyContent={"center"} className={classes.boxMarginRow}>
        <Box>
          Yakin Ingin Hapus Data {round.name} ?
        </Box>
      </Box>
      <br/>
      <Box display={"flex"} justifyContent="flex-end">
        <Box>
          <Button
            variant={"outlined"}
            size={"small"}
            className={classes.cancelButton}
            onClick={closedModalDialog}
          >
            Batal
          </Button>
        </Box>
        <Box>
          <Button
            variant={"contained"}
            size={"small"}
            className={classes.submitButton}
            onClick={() => deleted(round.id)}
          >
            Hapus
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onDeleteRound: (id) => dispatch(actions.deleteRound(id))
  }
}

export default connect(null, mapDispatchToProps)(DeleteRound);