import React, {Fragment, useEffect} from "react";
import {useParams} from 'react-router-dom';
import * as actions from '../../../../actions';
import {connect} from "react-redux";
import {Loading} from "../../../../components/UI";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import {isEmpty} from "../../../../shared/utility";
import BranchPurchaseQuotaData from "./BranchPurchaseQuotaData";

const columns = [
  { id: 'No', label: 'No', minWidth: '5%' },
  { id: 'tanggal', label: 'Foto Produk', minWidth: '22.5%' },
  { id: 'pic', label: 'Nama Produk', minWidth: '22.5%' },
  { id: 'status', label: 'Batas Kuotas Pembelian', minWidth: '22.5%' },
  { id: 'status', label: 'Sisa Kuotas', minWidth: '22.5%' },
  { id: 'action', label: 'Edit', minWidth: '5%' },
  // { id: 'detail', label: 'Detail', minWidth: '12%' },

];

const TableBranchPuchaseQuota = props => {
  const {id} = useParams();
  const {branchPurchaseQuota,loading,onGetBranchPuchaseQuota,edited,changing} = props;

  useEffect(() => {
    onGetBranchPuchaseQuota(id)
  },[onGetBranchPuchaseQuota,id,changing])

  // console.log(branchPurchaseQuota);
  let tableBody = '';
  if(!isEmpty(branchPurchaseQuota)){
    tableBody = (
      <Fragment>
        {branchPurchaseQuota.map((itemData,index) => (
          <BranchPurchaseQuotaData
            itemData={itemData}
            index={index}
            key={itemData.id}
            edited={(data) => edited(data)}
          />
        ))}
      </Fragment>
    )

  }

  return (loading ? <Loading/> :
    <Fragment>
      <Paper>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    branchPurchaseQuota: state.branchPurchaseQuota.branchPurchaseQuota,
    loading: state.branchPurchaseQuota.loading,
    changing: state.branchPurchaseQuota.changing
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetBranchPuchaseQuota: (id) => dispatch(actions.getBranchPurchaseQuota(id))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(TableBranchPuchaseQuota);