import React,{Fragment} from "react";
import {Box, Button, Typography} from "@material-ui/core";

const OrderConfirmationProcess = props => {
  const {
    classes,
    closedModalDialog,
    data,
    proccess
  } = props;

  let modalBody = '';
  let btnAction = '';
  if(data.status === '1'){
   modalBody = (
     <Fragment>
       <Box display={"flex"} justifyContent={"center"} className={classes.boxMarginRow}>
         <Box>
           <Typography variant={"h6"} align={"center"}>
             Purchase Order dari cabang akan diproses oleh pusat sesuai dengan rincian pesanan. Pastikan semua informasi Purchase Order tidak ada kesalahan.
           </Typography>
         </Box>
       </Box>
       <Box display={"flex"} justifyContent={"center"} className={classes.boxMarginRow}>
         <Box>
           <Typography variant={"h5"} align={"center"}>
             Yakin ingin memproses Purchase Order sekarang?
           </Typography>
         </Box>
       </Box>
     </Fragment>
   )
    btnAction = (
      <Box m={1}>
        <Button
          variant={"contained"}
          size={"small"}
          className={classes.buttonWarning}
          onClick={() => proccess(data)}
        >
          Proses
        </Button>
      </Box>
    )
  }

  if(data.status === '0'){
    modalBody = (
      <Fragment>
        <Box display={"flex"} justifyContent={"center"} className={classes.boxMarginRow}>
          <Box>
            <Typography variant={"h6"} align={"center"}>
              Purchase Order dari cabang akan ditolak karena alasan tertentu. Informasikan kepada cabang terkait, mengenai Purchase Order yang akan ditolak.
            </Typography>
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"center"} className={classes.boxMarginRow}>
          <Box>
            <Typography variant={"h5"} align={"center"}>
              Yakin ingin Menolak Purchase Order sekarang?
            </Typography>
          </Box>
        </Box>
      </Fragment>
    )
    btnAction = (
      <Box m={1}>
        <Button
          variant={"contained"}
          size={"small"}
          className={classes.btnError}
          onClick={() => proccess(data)}
        >
          Tolak
        </Button>
      </Box>
    )
  }

  return (
    <Fragment>
      {modalBody}
      <br/>
      <Box display={"flex"} justifyContent="flex-end">
        <Box m={1}>
          <Button
            variant={"outlined"}
            size={"small"}
            className={classes.btnCancel}
            onClick={closedModalDialog}
          >
            Batal
          </Button>
        </Box>
        {btnAction}
      </Box>
    </Fragment>
  );
};

export default OrderConfirmationProcess;