import React, { useState, Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import { 
    Grid, 
    Typography, 
    Card, 
    CardContent, 
    TextField, 
    MenuItem, 
    CardActions, 
    Button, 
    Paper,
    IconButton,
    Divider
} from '@material-ui/core'
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers"
import SchemaValidation from './validation'
import { useHistory, useParams } from 'react-router-dom'

import { connect } from 'react-redux'
import { addProductPrice } from '../../../../../actions/productPrice'
import NumberFormat from 'react-number-format'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
        width: '100%'
    },
    row: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    btn: {
        backgroundColor: '#011747',
        color: '#FFF'
    },
    backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
    },
    // input: {
    //     width: '250px'
    // },
    searchRoot: {
		// padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
		width: 'auto',
		marginTop: theme.spacing(1)
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        minWidth: '200px'
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    dividerHorizontal: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: 3,
        margin: 4
    },
}))

const CreateProduct = (props) => {
    const { addProductPrice, handleCloseCreatePrice } = props
    const classes = useStyles()
    const history = useHistory()
    const { id } = useParams()
    const { register, handleSubmit, errors } = useForm({
		resolver: yupResolver(SchemaValidation)
    });
    
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });

    const handleChange = event => {
        // console.log(event.target.value);
        event.persist();
    
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [event.target.name]: event.target.value
          }
        }));
    };

    const handleChangePrice = event => {
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [event.target.name]: event.target.value
          }
        }));
    };

    const onSubmit = e => {
        // console.log(id)
        addProductPrice(id, formState.values, history)
        handleCloseCreatePrice()
    }

    return (
        <Fragment>
            <div className={classes.root}>
                <div className={classes.row}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Typography variant="h4">Buat Harga Produk</Typography>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.row}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={3}>
                            <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                            >
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    defaultValue={formState.values.type || ''}
                                                    label="Tipe Harga Produk"
                                                    margin="dense"
                                                    name="type"
                                                    className={classes.input}
                                                    onChange={handleChange}
                                                    helperText={
                                                        errors.type && errors.type.message
                                                    }
                                                    error={errors.type && true}
                                                    inputRef={register}
                                                    select
                                                >
                                                    <MenuItem key="aog" value="1">
                                                        AOG
                                                    </MenuItem>
                                                    <MenuItem key="mog" value="2">
                                                        MOG
                                                    </MenuItem>
                                                    <MenuItem key="cabang" value="0">
                                                        Cabang
                                                    </MenuItem>
                                                    <MenuItem key="umum" value="3">
                                                        Umum
                                                    </MenuItem>
                                                </TextField>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                {/* <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    defaultValue={formState.values.sell_price || ''}
                                                    label="Harga Jual"
                                                    margin="dense"
                                                    name="sell_price"
                                                    className={classes.input}
                                                    onChange={handleChange}
                                                    helperText={
                                                        errors.sell_price && errors.sell_price.message
                                                    }
                                                    error={errors.sell_price && true}
                                                    inputRef={register}
                                                    InputProps={{
                                                        startAdornment: (
                                                          <InputAdornment position="start">
                                                            Rp
                                                          </InputAdornment>
                                                        ),
                                                    }}
                                                /> */}
                                                <Paper component="form" className={classes.searchRoot}>
                                                    <IconButton type="submit" className={classes.iconButton} aria-label="search">
                                                        <Typography variant="subtitle2">Rp</Typography>
                                                    </IconButton>
                                                    <Divider className={classes.divider} orientation="vertical" />
                                                    <NumberFormat
                                                        {...props}
                                                        defaultValue={formState.values.sell_price || ''}
                                                        name="sell_price"
                                                        customInput={TextField}
                                                        type="text"
                                                        inputRef={register}
                                                        thousandSeparator
                                                        onValueChange={({ value: v }) => handleChangePrice({ target : { name : 'sell_price', value: v} })}
                                                    />
                                                </Paper>
                                            </Grid>
                                        
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="medium" variant="outlined">
                                            Batal
                                        </Button>
                                        <Button type="submit" size="medium" variant="contained" className={classes.btn}>
                                            Simpan
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default connect(null, { addProductPrice })(CreateProduct)