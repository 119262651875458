import { GET_USER, GET_USER_ROLE, ADD_USER } from '../actions/types'
import {updateObject} from "../shared/utility";
import * as actionTypes from "../actions/types";

const initialState = {
    users : null,
    user : null,
    userRole: null,
    loadingUserRole: true,
    loading: true,
    loadingAddUser: true,
    loadingV2: {},
    userV2: {},
    errors: {},
    error: {}
}

const getUserDetailStart = (state, action) => {
    return updateObject(state, { loadingV2: true })
}

const getUserDetailSuccess = (state, action) => {
    return updateObject(state, {
        userV2: action.user,
        loadingV2: false
    })
}

const getUserDetailFail = (state, action) => {
    return updateObject(state, {
        errors: action.errors,
        loadingV2: false
    })
}

const updateUserStart = (state, action) => {
    return updateObject(state, { loadingV2: true })
}

const updateUserSuccess = (state, action) => {
    return updateObject(state, {
        userV2: action.user,
        loadingV2: false
    })
}

const updateUserFail = (state, action) => {
    return updateObject(state, {
        errors: action.errors,
        loadingV2: false
    })
}

const reducer = function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_USER:
            return {
                ...state,
                users: payload,
                loading: false
            }
        case GET_USER_ROLE:
            return {
                ...state,
                userRole: payload,
                loadingUserRole: false
            }
        case ADD_USER:
            return {
                ...state,
                user: payload,
                loadingAddUser: false
            }
        case actionTypes.GET_USER_DETAIL_START:
            return getUserDetailStart(state, action)
        case actionTypes.GET_USER_DETAIL_SUCCESS:
            return getUserDetailSuccess(state, action)
        case actionTypes.GET_USER_DETAIL_FAIL:
            return getUserDetailFail(state, action)
            case actionTypes.UPDATE_USER_START:
            return updateUserStart(state, action)
        case actionTypes.UPDATE_USER_SUCCESS:
            return updateUserSuccess(state, action)
        case actionTypes.UPDATE_USER_FAIL:
            return updateUserFail(state, action)
        default:
            return state
    }
}

export default reducer;