import {makeStyles} from "@material-ui/core";

const Main = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  tagMenu: {
    fontWeight: "bold"
  },
  tabRoot: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  btnSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
    fontWeight: "bold",
    '&:hover': {
      backgroundColor: theme.palette.success.light
    }
  },
  paperContent: {
    padding: theme.spacing(4)
  },
  paperPadding: {
    marginBottom: theme.spacing(2)
  }
}));

export default Main;