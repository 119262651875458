import React, { Fragment, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
    Typography,
    Grid,
    // Fab,
    Paper,
    IconButton,
    InputBase,
    Divider,
    Card,
    CardHeader,
    MenuItem,
    TextField,
    CardContent
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import Skeleton from '@material-ui/lab/Skeleton'

// components
import ListPurchaseOrder from './ListPurchaseOrder'

// Redux
import { connect } from 'react-redux'
import { getPurchaseOrder } from '../../actions/purchaseOrder'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1)
    },
    row: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    btnAddCustomer: {
        backgroundColor: '#0277BD',
        '&:hover' : {
            backgroundColor: '#2092D6'
        },
        color: '#FFFFFF',
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    searchRoot: {
		padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
		width: 'auto',
		// marginTop: theme.spacing(2)
	},
	input: {
        marginLeft: theme.spacing(1),
            flex: 1,
        },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(2),
    },
    backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
    },
    statusPO: {
        width: '250px'
    }
}))

const PurchaseOrder = ({ getPurchaseOrder, purchaseOrder : { purchaseOrders, loading } }) => {
    const classes = useStyles()
    // const history = useHistory()

    const [ status, setStatus ] = useState(3)
    const [ keyword, setKeyword ] = useState('')

    const handleChangeStatusPrice = event => {
        setStatus(event.target.value)
    }    

    const handleChangeKeyword = event => {
        setKeyword(event.target.value)
    }

    useEffect(() => {
        getPurchaseOrder(keyword, status)
    }, [loading, getPurchaseOrder, status, keyword])

    return (
        <Fragment>
            <div className={classes.root}>
                <div className={classes.row}>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item>  
                            <Typography variant="h4">Verifikasi Purchase Order</Typography>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.row}>
                    <Grid
                        container
                        spacing={2}
                        justify='space-between'
                    >
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Paper component="form" className={classes.searchRoot}>
                                <InputBase
                                    className={classes.input}
                                    name="pesan"
                                    value={keyword || ''}
                                    placeholder="Cari Invoice dan Nama Cabang"
                                    onChange={handleChangeKeyword}
                                    inputProps={{ 'aria-label': 'Cari Invoice' }}
                                />
                                <Divider className={classes.divider} orientation="vertical" />
                                <IconButton type="submit" className={classes.iconButton} aria-label="search">
                                    <SearchIcon />
                                </IconButton>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.row}>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid 
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <Card>
                                <CardHeader 
                                    title="List Purchase Order"
                                    action={
                                        <TextField 
                                            select
                                            fullWidth
                                            className={classes.statusPO}
                                            variant="outlined"
                                            name="status"
                                            defaultValue={status}
                                            label="Status Harga"
                                            onChange={handleChangeStatusPrice}
                                        >
                                            <MenuItem value="3">On Process</MenuItem>
                                            <MenuItem value="1">Disetujui</MenuItem>
                                            <MenuItem value="2">Ditolak</MenuItem>
                                        </TextField>
                                    }
                                />
                                <CardContent>
                                    {!loading ? (
                                        <ListPurchaseOrder purchaseOrders={purchaseOrders} />
                                    ):(
                                        <Skeleton variant="rect" height={200}></Skeleton>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    purchaseOrder : state.purchaseOrder
})

export default connect(mapStateToProps, { getPurchaseOrder })(PurchaseOrder)