
import axios from 'axios'
import { setAlert } from './alert'
import { 
    GET_VILLAGE,
    GET_VILLAGE_EDIT
} from './types'

export const getVillage = (kode_pos) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/village/cek_kode_pos?kode_pos=${kode_pos}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_VILLAGE,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getVillageEdit = (kode_pos) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/village/cek_kode_pos?kode_pos=${kode_pos}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_VILLAGE_EDIT,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}