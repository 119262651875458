import axios from 'axios'
import { setAlert } from './alert'
import { 
    DELETE_PRODUCT_PRICE_BUYBACK,
    GET_PRODUCT_PRICE_BUYBACK,
    ADD_PRODUCT_PRICE_BUYBACK
} from './types'

export const getProductPriceBuyback = (id,page) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/product/${id}/product_price_buyback/v2?page=${page}&page_size=10`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_PRODUCT_PRICE_BUYBACK,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const addProductPriceBuyback = (id_product, formData, history) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/product/${id_product}/product_price_buyback`
    const token = sessionStorage.getItem('access_token')

    const myData = {
        id_product : id_product,
        buyback_price : formData.buyback_price,
    }

    try {
        const res = await axios({
            url: endpoint,
            method: "POST",
            data: myData,
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: ADD_PRODUCT_PRICE_BUYBACK,
            payload: res.data
        })

        dispatch(setAlert("New Product Price Added", "success"))
        // history.push(`/product-price`);

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const deleteProductPriceBuyback = (id_price, id) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/product/${id}/product_price_buyback/${id_price}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "DELETE",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: DELETE_PRODUCT_PRICE_BUYBACK,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}