import React,{Fragment} from "react";
import {Box, Paper} from "@material-ui/core";
import NumberFormat from "react-number-format";

const OrderDetails = props => {
  const {classes, purchaseOrder} = props;
  return (
    <Fragment>
      <Paper className={classes.paperContent}>
        <div className={classes.paperPadding}>
          <div className={classes.tagMenu}>
            Rincian Pesanan
          </div>
          <div className={classes.row}>
            <Box display={"flex"} className={classes.boxMarginRow}>
              <Box width={'40%'}>
                Total Item
              </Box>
              <Box width={'60%'}>
                : <NumberFormat value={purchaseOrder.details ? purchaseOrder.details.length : 0} displayType={'text'} suffix={' varian'} thousandSeparator={true}/>
              </Box>
            </Box>
            <Box display={"flex"} className={classes.boxMarginRow}>
              <Box width={'40%'}>
                Total Order
              </Box>
              <Box width={'60%'}>
                : <NumberFormat value={purchaseOrder.total_item} displayType={'text'} suffix={' pcs'} thousandSeparator={true}/>
              </Box>
            </Box>
            <Box display={"flex"} className={classes.boxMarginRow}>
              <Box width={'40%'}>
                Total Berat
              </Box>
              <Box width={'60%'}>
                : {purchaseOrder.total_weight}
              </Box>
            </Box>
            <Box display={"flex"} className={classes.boxMarginRow}>
              <Box width={'40%'}>
                Total Harga
              </Box>
              <Box width={'60%'}>
                : <NumberFormat value={purchaseOrder.total_price_invoice} displayType={'text'} prefix={'Rp '} thousandSeparator={true}/>
              </Box>
            </Box>
            {/*<Box display={"flex"} className={classes.boxMarginRow}>*/}
            {/*  <Box width={'40%'}>*/}
            {/*    Kode Unik*/}
            {/*  </Box>*/}
            {/*  <Box width={'60%'}>*/}
            {/*    : <NumberFormat value={purchaseOrder.unique_number} displayType={'text'} prefix={'Rp +'} thousandSeparator={true}/>*/}
            {/*  </Box>*/}
            {/*</Box>*/}
            {/*<Box display={"flex"} className={classes.boxMarginRow}>*/}
            {/*  <Box width={'40%'}>*/}
            {/*    Total Bayar*/}
            {/*  </Box>*/}
            {/*  <Box width={'60%'}>*/}
            {/*    : <NumberFormat value={purchaseOrder.total_payment} displayType={'text'} prefix={'Rp '} thousandSeparator={true}/>*/}
            {/*  </Box>*/}
            {/*</Box>*/}
          </div>
        </div>
      </Paper>
    </Fragment>
  );
};

export default OrderDetails;