import {updateObject} from "../shared/utility";
import * as actionTypes from "../actions/types";

const initialState = {
  notification: {},
  changing: 0,
  loading: false,
  error: {},
}

const getNotificationStart = (state, action) => {
  return updateObject(state, {
    error: {},
    loading: true
  })
}

const getNotificationSuccess = (state, action) => {
  // const newOrder = updateObject(action.storeData, { id: action.storeDataId })
  return updateObject(state, {
    loading: false,
    notification: action.notification
  })
}

const getNotificationFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  })
}

const storeNotificationStart = (state, action) => {
  return updateObject(state, {
    error: {},
    loading: true
  })
}

const storeNotificationSuccess = (state, action) => {
  // const newOrder = updateObject(action.storeData, { id: action.storeDataId })
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
}

const storeNotificationFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_NOTIFICATION_START:
      return getNotificationStart(state, action)
    case actionTypes.GET_NOTIFICATION_SUCCESS:
      return getNotificationSuccess(state, action)
    case actionTypes.GET_NOTIFICATION_FAIL:
      return getNotificationFail(state, action)
    case actionTypes.STORE_NOTIFICATION_START:
      return storeNotificationStart(state, action)
    case actionTypes.STORE_NOTIFICATION_SUCCESS:
      return storeNotificationSuccess(state, action)
    case actionTypes.STORE_NOTIFICATION_FAIL:
      return storeNotificationFail(state, action)
    default:
      return state
  }
}

export default reducer