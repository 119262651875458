import React,{Fragment} from "react";
import {Grid, makeStyles, Typography, Paper} from '@material-ui/core'
// import {Add as AddIcon} from "@material-ui/icons";

//component
import ListResetStock from './ListResetStock';
import SearchBranch from "./SearchBranch";

const useStyle = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom:  theme.spacing(2)
  },
  tagMenu: {
    fontWeight: "bold",

  },
  addButton: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
    textTransform: "none",
    fontWeight: "bold",
    borderRadius: '60px',
    // padding: 'auto',
    '&:hover':{
      backgroundColor: theme.palette.warning.light,
    }
  },
  paperRoot: {
    padding: theme.spacing(3)
  },
  paperTag: {
    marginBottom: theme.spacing(3)
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    // marginTop: theme.spacing(2)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  btnSelect: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
    textTransform: "none",
    '&:hover': {
      backgroundColor: theme.palette.warning.light
    }
  }
}));
const ResetStockCompenent = props => {
  const classes = useStyle();

  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.row}>
          <Grid container spacing={2} justify={"space-between"}>
            <Grid item>
              <Typography variant={"h4"} className={classes.tagMenu}>
                Reset Stok Cabang
              </Typography>
            </Grid>
            <Grid item>
              <SearchBranch classes={classes}/>
              {/*<Button variant={"contained"} size={"medium"} startIcon={React.cloneElement(<AddIcon/>)} className={classes.addButton}>Reset Stock</Button>*/}
            </Grid>
          </Grid>

        </div>
        <div className={classes.row}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper variant={"outlined"} className={classes.paperRoot}>
                <div className={classes.paperTag}>
                  <Typography variant={"h5"} className={classes.tagMenu}>
                    History Reset Stok
                  </Typography>
                </div>
                <div>
                  <ListResetStock/>
                </div>

              </Paper>

            </Grid>
          </Grid>
        </div>
      </div>
    </Fragment>
  );
};

export default ResetStockCompenent;