import React, {Fragment, useEffect} from "react";
import {useParams} from 'react-router-dom';
import {connect} from "react-redux";
import * as actions from '../../../actions';
import {Loading} from "../../../components/UI";
import {makeStyles} from "@material-ui/styles";
import {Grid, Typography} from "@material-ui/core";
import {isEmpty} from "../../../shared/utility";
import {Form} from "./components";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  btn: {
    backgroundColor: '#011747',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#032162'
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const UpdateUser = props => {
  const {id} = useParams();
  const classes = useStyles()
  const {
    onGetUser,
    loading,
    user
  } = props;

  useEffect(() => {
    onGetUser(id)
  },[onGetUser,id])

  // console.log(user);
  return (loading ? <Loading/> :
    <Fragment>
      <div className={classes.root}>
        <div className={classes.row}>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="h4">Update User</Typography>
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          {!isEmpty(user) && <Form id={id} user={user} classes={classes}/>}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.user.loadingV2,
    user: state.user.userV2,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetUser: (id) => dispatch(actions.getUserDetail(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUser);