import * as actionTypes from '../actions/types';
import { updateObject } from '../shared/utility'

const initialState = {
  reset_stock_cabang: null,
  reset_stock_cabang_detail: null,
  error: {},
  changing: 0,
  loading: false,
}

const fetchResetStockCabangStart = (state, action) => {
  return updateObject(state, { error: {}, loading: true })
}

const fetchResetStockCabangSuccess = (state, action) => {
  return updateObject(state, {
    reset_stock_cabang: action.reset_stock_cabang,
    loading: false
  })
}

const fetchResetStockCabangFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false })
}

const storeResetStockCabangStart = (state, action) => {
  return updateObject(state, { error: {}, loading: true })
}

const storeResetStockCabangSuccess = (state, action) => {
  return updateObject(state, {
    changing: state.changing+1,
    loading: false
  })
}

const storeResetStockCabangFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_RESET_STOCK_CABANG_START: return fetchResetStockCabangStart(state, action)
    case actionTypes.FETCH_RESET_STOCK_CABANG_SUCCESS: return fetchResetStockCabangSuccess(state, action)
    case actionTypes.FETCH_RESET_STOCK_CABANG_FAIL: return fetchResetStockCabangFail(state, action)
    case actionTypes.STORE_RESET_STOCK_CABANG_START: return storeResetStockCabangStart(state, action)
    case actionTypes.STORE_RESET_STOCK_CABANG_SUCCESS: return storeResetStockCabangSuccess(state, action)
    case actionTypes.STORE_RESET_STOCK_CABANG_FAIL: return storeResetStockCabangFail(state, action)

    default: return state
  }
}

export default reducer;