import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit'
import DetailIcon from '@material-ui/icons/Search'
import { Link as RouterLink } from 'react-router-dom'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import { 
	Tooltip,
	IconButton,
	Chip,
} from '@material-ui/core';

const columns = [
  { id: 'no', label: 'No', minWidth: 100 },
  { id: 'nama', label: 'Nama', minWidth: 150 },
  { id: 'alamat', label: 'Alamat', minWidth: 200 },
  { id: 'telepon', label: 'Telepon', minWidth: 100 },
  { id: 'status_aktif', label: 'Status Aktif', minWidth: 100 },
  { id: 'action', label: 'Action', minWidth: 140 },
  
];

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	container: {
		maxHeight: 440,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	statusNotActive: {
		backgroundColor: 'red',
		color: '#FFFFFF'
	}
}));

const ListBranch = (props) => {
	const { branchSearch, loadingSearch, page, setPage } = props
	const classes = useStyles();
	
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	let no = 1
	if(!loadingSearch && branchSearch !== null){
		no = branchSearch.from
	}

	return loadingSearch || branchSearch == null ? 
		<Backdrop className={classes.backdrop} open>
			<CircularProgress color="inherit" />
		</Backdrop> 
		: 
		<Fragment>
			<Paper className={classes.root}>
			<TableContainer className={classes.container}>
				<Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
				<TableHead>
					<TableRow>
					{columns.map((column) => (
						<TableCell
						key={column.id}
						align={column.align}
						style={{ minWidth: column.minWidth }}
						>
						{column.label}
						</TableCell>
					))}
					</TableRow>
				</TableHead>
				<TableBody>
					{branchSearch.data.map((branch) => (
						<TableRow key={branch.id}>
							<TableCell>
								{no++}
							</TableCell>
							<TableCell>
								{branch.name}
							</TableCell>
							<TableCell>
								{branch.address}
							</TableCell>
							<TableCell>
								{branch.phone}
							</TableCell>
							<TableCell>
								{branch.is_active === '1' ? (
									<Chip label='Aktif' color="secondary" />
								): (
									<Chip label='Tidak Aktif' className={classes.statusNotActive} />
								)}
							</TableCell>
							<TableCell>
								<Tooltip title="Detail Branch">
									<RouterLink to={`/branch/edit/${branch.id}`}>
										<IconButton aria-label="detail">
											<DetailIcon />
										</IconButton>
									</RouterLink>
								</Tooltip>
								<Tooltip title="Edit Branch">
									<RouterLink to={`/branch/edit/${branch.id}`}>
										<IconButton aria-label="edit">
											<EditIcon />
										</IconButton>
									</RouterLink>
								</Tooltip>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10]}
				component="div"
				count={!loadingSearch && branchSearch.total}
				rowsPerPage={rowsPerPage}
				page={page}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
			</Paper>
		</Fragment>
	
}

export default ListBranch