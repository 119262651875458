import React, { useState, Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import { 
    Grid, Typography, Card, CardContent, TextField, MenuItem, CardActions, Button, InputAdornment
} from '@material-ui/core'
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers"
import SchemaValidation from './validation'
import { useHistory, useParams } from 'react-router-dom'

import { connect } from 'react-redux'
import { updateProductPrice } from '../../../../../actions/productPrice'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
        width: '100%'
    },
    row: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    btn: {
        backgroundColor: '#011747',
        color: '#FFF'
    },
    backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}))

const CreateProduct = (props) => {
    const { updateProductPrice, handleCloseEditPrice, price } = props
    const classes = useStyles()
    const history = useHistory()
    const { id } = useParams()
    const { register, handleSubmit, errors } = useForm({
		resolver: yupResolver(SchemaValidation)
    });
    
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });

    const handleChange = event => {
        // console.log(event.target.value);
        event.persist();
    
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [event.target.name]: event.target.value
          }
        }));
    };

    const onSubmit = e => {
        // console.log(id)
        updateProductPrice(price.id, id, formState.values, history)
        handleCloseEditPrice()
    }

    return price !== undefined && 
        <Fragment>
            <div className={classes.root}>
                <div className={classes.row}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Typography variant="h4">Edit Harga Produk</Typography>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.row}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={3}>
                            <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                            >
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    defaultValue={price.type || ''}
                                                    label="Tipe Harga Produk"
                                                    margin="dense"
                                                    name="type"
                                                    onChange={handleChange}
                                                    helperText={
                                                        errors.type && errors.type.message
                                                    }
                                                    error={errors.type && true}
                                                    inputRef={register}
                                                    select
                                                >
                                                    <MenuItem key="aog" value="aog">
                                                        AOG
                                                    </MenuItem>
                                                    <MenuItem key="mog" value="mog">
                                                        MOG
                                                    </MenuItem>
                                                    <MenuItem key="cabang" value="cabang">
                                                        Cabang
                                                    </MenuItem>
                                                    <MenuItem key="customer" value="customer">
                                                        Customer
                                                    </MenuItem>
                                                </TextField>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    defaultValue={price.sell_price || ''}
                                                    label="Harga Jual"
                                                    margin="dense"
                                                    name="sell_price"
                                                    onChange={handleChange}
                                                    helperText={
                                                        errors.sell_price && errors.sell_price.message
                                                    }
                                                    error={errors.sell_price && true}
                                                    inputRef={register}
                                                    InputProps={{
                                                        startAdornment: (
                                                          <InputAdornment position="start">
                                                            Rp
                                                          </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                        
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="medium" variant="outlined" onClick={handleCloseEditPrice}>
                                            Batal
                                        </Button>
                                        <Button type="submit" size="medium" variant="contained" className={classes.btn}>
                                            Simpan
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </Fragment>
}

export default connect(null, { updateProductPrice })(CreateProduct)