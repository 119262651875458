import React, {Fragment, useEffect} from "react";
import {useParams} from 'react-router-dom';
import * as action from '../../../actions';
import {connect} from "react-redux";
import {Grid, Paper, Typography} from "@material-ui/core";

import useStyles from '../Styles/UseStyles';
import {isEmpty} from "../../../shared/utility";
import TableProduct from "./TableProduct";
import {Loading} from "../../../components/UI";

const ViewQuotaProduct = props => {
  const {id} = useParams();
  const {onGetQuotaProduct,quotaProduct,loading} = props;

  const classes = useStyles();

  useEffect(() => {
    onGetQuotaProduct(id)
  },[onGetQuotaProduct,id]);

  console.log(quotaProduct);
  let tableProduct = ''
  if (!isEmpty(quotaProduct)){
    tableProduct = <TableProduct quotaProduct={quotaProduct} classes={classes}/>
  }

  return (loading ? <Loading/> :
    <Fragment>
      <div className={classes.root}>
        <div className={classes.row}>
          <Grid container spacing={2} justify={"space-between"}>
            <Grid item>
              <Typography variant={"h4"} className={classes.tagMenu}>
                Pengaturan Kuota Harian Pusat
              </Typography>
            </Grid>
          </Grid>

        </div>
        <div className={classes.row}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper variant={"outlined"} className={classes.paperRoot}>
                <div className={classes.paperTag}>
                  <Typography variant={"h5"} className={classes.tagMenu}>
                    Detail Kuota Harian Pusat
                  </Typography>
                </div>
                <div>
                  {tableProduct}
                </div>

              </Paper>

            </Grid>
          </Grid>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    quotaProduct: state.quotaProduct.quotaProduct,
    loading: state.quotaProduct.loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetQuotaProduct: (id) => dispatch(action.getQuotaProduct(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewQuotaProduct);