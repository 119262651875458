import React,{Fragment} from "react";
import {Box, Button, Typography} from "@material-ui/core";
import {useHistory} from 'react-router-dom';

import * as actions from '../../../../../actions';
import {connect} from "react-redux";

const FormCreateValidation = props => {
  const history = useHistory();
  const {quota, classes, closedModalDialog,onStoreQuotaProduct} = props;

  const store = (data) => {
    data.products = quota;
    onStoreQuotaProduct(data, history);
  }
  return (
    <Fragment>
      <Box display={"flex"} justifyContent={"center"} className={classes.boxMarginRow}>
        <Box>
          <Typography variant={"h5"}>
            Yakin Ingin Simpan Kuota Harian ?
          </Typography>
        </Box>
      </Box>
      <br/>
      <Box display={"flex"} justifyContent="flex-end">
        <Box>
          <Button
            variant={"outlined"}
            size={"small"}
            className={classes.cancelButton}
            onClick={closedModalDialog}
          >
            Batal
          </Button>
        </Box>
        <Box>
          <Button
            variant={"contained"}
            size={"small"}
            className={classes.draftButton}
            onClick={() => store({status: 'draft'})}
          >
            Draft
          </Button>
        </Box>
        <Box>
          <Button
            variant={"contained"}
            size={"small"}
            className={classes.submitButton}
            onClick={() => store({status: 'saved'})}
          >
            Simpan
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onStoreQuotaProduct: (storeData, history) => dispatch(actions.storeQuotaProduct(storeData,history))
  }
}

export default connect(null,mapDispatchToProps)(FormCreateValidation);