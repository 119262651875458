
import axios from 'axios'
import { setAlert } from './alert'
import {
    GET_PRODUCT_DASHBOARD,
    GET_STATS_IN_CARD,
    GET_GOLD_PRICE,
    GET_NET_INCOME,
    GET_PRODUCT_BUYBACK_DASHBOARD,
    GET_TRANSACTION_SALES,
    GET_GOLD_PRICE_START,
    GET_GOLD_PRICE_FAIL,
    GET_NET_INCOME_START,
    GET_NET_INCOME_FAIL,
    GET_TRANSACTION_SALES_START,
    GET_TRANSACTION_SALES_FAIL,
    GET_TRANSACTION_MONEY_START,
    GET_TRANSACTION_MONEY,
    GET_TRANSACTION_MONEY_FAIL
} from './types'

export const getProduct = (type) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/product_price?type=${type}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_PRODUCT_DASHBOARD,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getProductBuyback = (type) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/product_price_buyback?type=${type}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_PRODUCT_BUYBACK_DASHBOARD,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getStatsInCard = () => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/dashboard_grafik/card`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_STATS_IN_CARD,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }   
}

export const getGrafikNetIncome = (start_date, end_date) => async dispatch => {
    dispatch({type: GET_NET_INCOME_START})
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/dashboard_grafik/pendapatan?start_date=${start_date}&end_date=${end_date}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_NET_INCOME,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        dispatch({type: GET_NET_INCOME_FAIL})
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getGrafikGoldPrice = (id_product, type_customer, start_date, end_date) => async dispatch => {
    dispatch({type: GET_GOLD_PRICE_START});

    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/dashboard_grafik/gold_price_time?id_product=${id_product}&type_customer=${type_customer}&start_date=${start_date}&end_date=${end_date}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_GOLD_PRICE,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        dispatch({type: GET_GOLD_PRICE_FAIL});
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getGrafikTransactionSales = (start_date, end_date, type) => async dispatch => {
    dispatch({type:GET_TRANSACTION_SALES_START})
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/dashboard_grafik/trx_day?start_date=${start_date}&end_date=${end_date}&type=${type}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_TRANSACTION_SALES,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        dispatch({type:GET_TRANSACTION_SALES_FAIL})
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getGrafikTransactionMoney = (start_date, end_date, type) => async dispatch => {
    dispatch({type:GET_TRANSACTION_MONEY_START})
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/dashboard_grafik/trx_day?start_date=${start_date}&end_date=${end_date}&type=${type}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_TRANSACTION_MONEY,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        dispatch({type:GET_TRANSACTION_MONEY_FAIL})
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}