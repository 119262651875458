import React, {Fragment, useEffect} from "react";
import {Paper, Grid, Typography} from "@material-ui/core";

import {connect} from "react-redux";
import * as actions from '../../../actions';
import {Loading} from "../../../components/UI";
import {makeStyles} from "@material-ui/core/styles";
import {isEmpty} from "../../../shared/utility";
import TableProduct from "./TableProduct";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom:  theme.spacing(2)
  },
  tagMenu: {
    fontWeight: "bold",

  },
  addButton: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
    textTransform: "none",
    fontWeight: "bold",
    borderRadius: '60px',
    // padding: 'auto',
    '&:hover':{
      backgroundColor: theme.palette.warning.light,
    }
  },
  paperRoot: {
    padding: theme.spacing(3)
  },
  paperTag: {
    marginBottom: theme.spacing(3)
  },
  boxMarginRow: {
    margin: "auto auto 15px"
  },
  boxMarginColumn: {
    margin: "auto auto auto 10px"
  },
  submitButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
    textTransform: "none",
    fontWeight: "bold",
    margin: "auto auto auto 10px",
    // borderRadius: '60px',
    // padding: 'auto',
    '&:hover':{
      backgroundColor: theme.palette.success.light,
    }
  },
  draftButton: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
    textTransform: "none",
    fontWeight: "bold",
    margin: "auto auto auto 10px",
    // borderRadius: '60px',
    // padding: 'auto',
    '&:hover':{
      backgroundColor: theme.palette.warning.light,
    }
  },
  cancelButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    textTransform: "none",
    fontWeight: "bold",
    margin: "auto auto auto 10px",
    // borderRadius: '60px',
    // padding: 'auto',
    '&:hover':{
      borderColor: theme.palette.error.light,
      color: theme.palette.error.light,
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  chipSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
  },
  chipDraft: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white
  }
}))

const CreateQuotaProduct = props =>{
  const classes = useStyles();
  const {loading, onFetchProduct, products} = props;

  useEffect(() => {
    onFetchProduct('gold')
  },[onFetchProduct]);

  let tableProduct = '';
  if (!isEmpty(products)){
    tableProduct = <TableProduct products={products} classes={classes}/>
  }

  return (loading ? <Loading/> :
    <Fragment>
      <div className={classes.root}>
        <div className={classes.row}>
          <Grid container spacing={2} justify={"space-between"}>
            <Grid item>
              <Typography variant={"h4"} className={classes.tagMenu}>
                Pengaturan Kuota Harian Pusat
              </Typography>
            </Grid>
          </Grid>

        </div>
        <div className={classes.row}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper variant={"outlined"} className={classes.paperRoot}>
                <div className={classes.paperTag}>
                  <Typography variant={"h5"} className={classes.tagMenu}>
                    Form Kuota Harian Pusat
                  </Typography>
                </div>
                <div>
                  {tableProduct}
                </div>

              </Paper>

            </Grid>
          </Grid>
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = state => {
  return {
    loading: state.product.loadingV2,
    products: state.product.productsV2
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchProduct: (status) => dispatch(actions.fetchProducts(status)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuotaProduct);