import React, {Fragment, useEffect, useState} from "react";
import {Main} from './style';
import moment from 'moment';
import {FormSearchPurchaseOrder, ListPurchaseOrder} from "./components";

import * as actions from '../../actions';
import {connect} from "react-redux";
import {Loading} from "../../components/UI";

const PurchaseOrderV2 = props => {
  const {
    onFetchPurchaseOrders,
    purchaseOrders,
    loading,
    loadingRound,
    onfetchRound,
    rounds,
    onDownloadPurchaseOrders,
    onDownloadPurchaseOrdersOld
  } = props;
  const classes = Main();

  const [formSearch, setFormSearch] = useState({
    status: '',
    search: '',
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
    round_id: ''
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      onFetchPurchaseOrders(1, formSearch)
    }, 1000)

    return () => clearTimeout(timer)
    // onFetchPurchaseOrders(1, formSearch)
  },[onFetchPurchaseOrders, formSearch]);

  useEffect(() => {
    onfetchRound()
  },[onfetchRound]);

  const handleChangePage = (newPage) => {
    onFetchPurchaseOrders(newPage, formSearch);
  }

  const [ startDate, setStartDate ] = useState({
    startDate: new Date(),
  });

  const handleStartDate = (input) => {
    setStartDate(startDate => ({
      ...startDate,
      startDate: input
    }));
    setFormSearch(() => ({
      ...formSearch,
      start_date: moment(input).format('YYYY-MM-DD')
    }));
  };

  const [ endDate, setEndDate ] = useState({
    endDate: new Date(),
  });

  const handleEndDate = (input) => {
    setEndDate(endDate => ({
      ...endDate,
      endDate: input
    }));
    setFormSearch(() => ({
      ...formSearch,
      end_date: moment(input).format('YYYY-MM-DD')
    }));
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEnd, setIsOpenEnd] = useState(false);

  const handleSearch = event => {
    const target = event.target.name;
    event.persist();
    setFormSearch((formSearch) => ({
      ...formSearch,
      [target]: event.target.value
    }));
  };

  const downloadClick = () => {
    onDownloadPurchaseOrders(formSearch);
  }

  const downloadOldClick = () => {
    onDownloadPurchaseOrdersOld(formSearch);
  }

  return (loading || loadingRound ? <Loading/> :
    <Fragment>
      <div className={classes.root}>
        <div className={classes.row}>
          <div className={classes.tagMenu}>
            Purchase Order
          </div>
        </div>
        <div className={classes.row}>
          <FormSearchPurchaseOrder
            classes={classes}
            moment={moment}
            setIsOpen={setIsOpen}
            startDate={startDate}
            isOpen={isOpen}
            handleStartDate={handleStartDate}
            setIsOpenEnd={setIsOpenEnd}
            endDate={endDate}
            isOpenEnd={isOpenEnd}
            handleEndDate={handleEndDate}
            handleSearch={handleSearch}
            formSearch={formSearch}
            rounds={rounds}
          />
        </div>
        <div className={classes.row}>
          <ListPurchaseOrder
            classes={classes}
            purchaseOrders={purchaseOrders}
            propsHandleChangePage={(newPage) => handleChangePage(newPage)}
            downloadClick={() => downloadClick()}
            downloadOldClick={() => downloadOldClick()}
          />
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    purchaseOrders: state.purchaseOrder.purchaseOrdersV2,
    loading: state.purchaseOrder.loading,
    loadingRound: state.round.loading,
    rounds: state.round.rounds
  }
}

const mapDispatchToProps = dispatch =>{
  return {
    onFetchPurchaseOrders: (page, formSearch) => dispatch(actions.fetchPurchaseOrders(page,formSearch)),
    onfetchRound:() => dispatch(actions.fetchRounds()),
    onDownloadPurchaseOrders: (formSearch) => dispatch(actions.downloadPurchaseOrders(formSearch)),
    onDownloadPurchaseOrdersOld: (formSearch) => dispatch(actions.downloadPurchaseOrdersOld(formSearch))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(PurchaseOrderV2);