import React, {Fragment, useEffect, useState} from "react";
import {Button, Grid, withStyles} from "@material-ui/core";
import {TextField} from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import {connect} from "react-redux";
import * as actions from "../../../../actions";
import {Loading} from "../../../../components/UI";

const schema = yup.object().shape({
});

const PrimaryCheckbox = withStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.dark,
    },
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />);

const FormNotification = props => {
  const {classes, status, submit, errorForm,notification,onGetNotification,changing,loading} = props;

  useEffect(() => {
    onGetNotification(status)
  },[onGetNotification,status,changing]);

  console.log(notification);

  const [formState, setFormState] = useState({
    message: notification != null ? notification.message : '',
    checkedA: (
      notification != null ? (notification.auto_send === 1 ? true : false) : true
    ),
    status: status
  })

  const handleChange = (event) => {
    const target = event.target.name
    event.persist()

    if(event.target.type === 'checkbox'){
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.checked
      }))
    }else{
      setFormState((formState) => ({
        ...formState,
        [target]:event.target.value
      }))
    }
  }

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = data => {
    data.auto_send = formState.checkedA ? 1 : 0;
    data.status = formState.status;
    submit(data);
  }

  return (loading ? <Loading/> :
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.row}>
        <Grid container spacing={2}>
          <Grid item xl={12} md={12} sm={12} xs={12}>
            <TextField
              id="outlined-multiline-static"
              autoFocus
              label="Pesan"
              multiline
              rows={10}
              placeholder={'Template'}
              variant="outlined"
              fullWidth
              name={'message'}
              inputRef={register}
              error={errorForm.message ? true : false}
              helperText={errorForm.message && errorForm.message[0]}
              defaultValue={notification != null ? notification.message : ''}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container justify={"space-between"} className={classes.row}>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <PrimaryCheckbox
                    // checked={notification != null ? (notification.auto_send === 1 ? true : false) : true}
                    defaultChecked={notification != null && (notification.auto_send === 1)}
                    onChange={handleChange}
                    name="checkedA"
                  />
                }
                label="Auto Send Message"
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <Button type={"submit"} variant={"contained"} size={"small"} className={classes.btnSuccess}>Simpan</Button>
          </Grid>
        </Grid>
      </form>

    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.notification.loading,
    notification: state.notification.notification,
    changing: state.notification.changing
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // onStoreNotification: (storeData) => dispatch(actions.storeNotification(storeData)),
    onGetNotification: (status) => dispatch(actions.getNotification(status))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(FormNotification);