import React, {Fragment, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import {useHistory} from 'react-router-dom';

import * as actions from '../../../actions';
import {connect} from "react-redux";
import {isEmpty} from "../../../shared/utility";
import TableBody from "@material-ui/core/TableBody";
import {Loading, TablePaginationActions} from "../../../components/UI";
import QuotaProductData from "./RoundData";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";

const columns = [
  { id: 'No', label: 'No', minWidth: '5%' },
  { id: 'tanggal', label: 'Tanggal Update', minWidth: '30%' },
  { id: 'pic', label: 'PIC', minWidth: '30%' },
  { id: 'status', label: 'STATUS', minWidth: '30%' },
  { id: 'action', label: 'Action', minWidth: '5%' },
  // { id: 'detail', label: 'Detail', minWidth: '12%' },

];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  chipSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
  },
  chipDraft: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white
  }
}));

const ListQuotaProduct = props => {
  const classes = useStyles();
  const history = useHistory();

  const {onFetchQuotaProduct, loading, quotaProducts, changing} = props;
  useEffect(() => {
    onFetchQuotaProduct(1)
  },[onFetchQuotaProduct,changing]);
  console.log(quotaProducts)

  const handleChangePage = (event, newPage) => {
    // onFetchMembers(newPage, formSearch);
    onFetchQuotaProduct(newPage)
  }

  let tableBody = '';
  if (!isEmpty(quotaProducts)){
    const page = quotaProducts.current_page - 1;
    const rowsPerPage = 10;
    const countRows = quotaProducts.total_item
    tableBody = (
      <Fragment>
        <TableBody>
          {quotaProducts.data.map((quotaProduct, index) => (
            <QuotaProductData
              quotaProduct={quotaProduct}
              key={quotaProduct.id}
              index={index}
              edited={(data) => history.push(`/quota-product/${data.id}/edit`)}
              viewed={(data) => history.push(`/quota-product/${data.id}`)}
              classes={classes}
            />
          ))}

        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              colSpan={8}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    )
  }

  return (loading ? <Loading/> :
    <Fragment>
      <Paper className={classes.root}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    quotaProducts: state.quotaProduct.quotaProducts,
    loading: state.quotaProduct.loading,
    changing: state.quotaProduct.changing
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchRound: () => dispatch(actions.fetchRounds()),
    onFetchQuotaProduct: (page) => dispatch(actions.fetchQuotaProducts(page))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListQuotaProduct);