import React,{Fragment} from "react";
import {
  Grid,
  Paper
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {Search} from "@material-ui/icons";
import {isEmpty} from "../../../../../../shared/utility";

const PaymentProof = props => {
  const {
    classes,
    purchaseOrder,
    paymentProofFileShow
  } = props;

  let paymentProof = '';

  if (!isEmpty(purchaseOrder.file_bukti_bayar)) {
    // console.log(assetImageUrl);
    paymentProof = (
      <Fragment>
        {purchaseOrder.file_bukti_bayar.map((image, index) => (
          <Grid key={index} item lg={3} md={3} sm={12} xs={12}>
            <Card className={classes.imageList}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={
                    // image.link.substr((image.link.length - 4), 4) === '.png'
                    (image.link.substr((image.link.length - 4), 4) === '.png') ||
                    (image.link.substr((image.link.length - 4), 4) === '.jpg') ||
                    (image.link.substr((image.link.length - 5), 5) === '.jpeg')
                      ? image.link
                      : require('../../../../../../assets/images/ico-pdf.png')
                  }
                  title="Contemplative Reptile"
                />
              </CardActionArea>
              <CardContent>
                <Typography variant="h5">
                  {image.caption}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonWarning}
                  startIcon={<Search />}
                  onClick={
                    image.link.substr((image.link.length - 4), 4) === '.png'
                      ? () => paymentProofFileShow(image)
                      : () => window.open(image.link, '_blank')
                  }
                  fullWidth
                >
                  Lihat
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Paper className={classes.paperContent}>
        <div className={classes.paperPadding}>
          <div className={classes.tagMenu}>
            Foto Bukti Bayar
          </div>
        </div>
        <Grid className={classes.row} container spacing={2}>
          {paymentProof}
        </Grid>
      </Paper>
    </Fragment>
  );
};

export default PaymentProof;