import React, {Fragment, useEffect} from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";

import * as actions from '../../../actions';
import {connect} from "react-redux";
import {Loading, TablePaginationActions} from "../../../components/UI";
import {isEmpty} from "../../../shared/utility";
import TableBody from "@material-ui/core/TableBody";
import BranchPurchaseQuotaData from "./BranchPurchaseQuotaData";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";

import {useHistory} from 'react-router-dom';

const columns = [
  { id: 'No', label: 'No', minWidth: '5%' },
  { id: 'tanggal', label: 'Tanggal Update', minWidth: '30%' },
  { id: 'nama_cabang', label: 'Nama Cabang', minWidth: '30%' },
  { id: 'pic', label: 'PIC', minWidth: '30%' },
  { id: 'detail', label: 'Detail', minWidth: '5%' },
];

const ListBranchPurchaseQuota = props => {
  const {loading,changing,branchPurchaseQuotas,onFetchBranchPurchaseQuota,formSearch} = props;
  const history = useHistory();

  console.log(formSearch);
  useEffect(() => {
    const timer = setTimeout(() => {
      onFetchBranchPurchaseQuota(1,formSearch)
    }, 1000)

    return () => clearTimeout(timer)
  },[onFetchBranchPurchaseQuota,changing,formSearch]);

  const handleChangePage = (event, newPage) => {
    onFetchBranchPurchaseQuota(newPage,formSearch)
  }

  let tableBody = '';
  if (!isEmpty(branchPurchaseQuotas)){
    const page = branchPurchaseQuotas.current_page - 1;
    const rowsPerPage = 10;
    const countRows = branchPurchaseQuotas.total_item
    tableBody = (
      <Fragment>
        <TableBody>
          {branchPurchaseQuotas.data.map((branchPurchaseQuota, index) => (
            <BranchPurchaseQuotaData
              branchPurchaseQuota={branchPurchaseQuota}
              key={branchPurchaseQuota.id}
              index={index}
              detailed={(data) => history.push(`/branch-purchase-quota/${data.branch.id}`)}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              colSpan={8}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    )
  }

  return (loading ? <Loading/> :
    <Fragment>
      <Paper>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.branchPurchaseQuota.loading,
    branchPurchaseQuotas: state.branchPurchaseQuota.branchPurchaseQuotas,
    changing: state.branchPurchaseQuota.changing
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchBranchPurchaseQuota: (page, formSearch) => dispatch(actions.fetchBranchPurchaseQuotas(page,formSearch)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListBranchPurchaseQuota);