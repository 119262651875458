import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CapitalizedText from '../../../components/layout/CapitalizedText'

import { connect } from 'react-redux';
import { getProduct, delProduct } from '../../../actions/product'
import { 
	Tooltip,
	IconButton,
} from '@material-ui/core';

const columns = [
  { id: 'no', label: 'No', minWidth: 100 },
  { id: 'nama', label: 'Nama Item', minWidth: 150 },
  { id: 'berat', label: 'Berat / Banyak', minWidth: 200 },
  { id: 'satuan', label: 'Satuan', minWidth: 100 },
  { id: 'action', label: 'Action', minWidth: 140 },
  
];

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	container: {
		maxHeight: 440,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const ListProduct = ({ 
    getProduct, 
    delProduct,
	product : { products, loading, counting } 
}) => {
	const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const history = useHistory()
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    var no = 1

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
    };
    
    const [dialogOpen, setDialogOpen] = React.useState({
        open: false,
        item : null
    });

    const handleClickDialogOpen = (product) => {
        setDialogOpen({
            open : true,
            item : product
        });
    };

    const handleCloseDialog = () => {
        setDialogOpen({
            open : false,
            item : null
        });
    };

    const handleProductDelete = (product) => {
        delProduct(product.id, history)
        handleCloseDialog()
    }

	useEffect(() => {
		getProduct()
	}, [loading, getProduct, counting]);

	return loading || products === null ? 
		<Backdrop className={classes.backdrop} open>
			<CircularProgress color="inherit" />
		</Backdrop> 
		: 
		<Fragment>
			<Paper className={classes.root}>
			<TableContainer className={classes.container}>
				<Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
				<TableHead>
					<TableRow>
					{columns.map((column) => (
						<TableCell
						key={column.id}
						align={column.align}
						style={{ minWidth: column.minWidth }}
						>
						{column.label}
						</TableCell>
					))}
					</TableRow>
				</TableHead>
				<TableBody>
					{products.map((product) => (
						<TableRow key={product.id}>
                            <TableCell>
								{no++}
							</TableCell>
							<TableCell>
								<CapitalizedText text={product.name} />
							</TableCell>
							<TableCell>
								{product.weight}
							</TableCell>
							<TableCell>
								<CapitalizedText text={product.unit} />
							</TableCell>
							<TableCell>
								<Tooltip title="Edit Branch">
									<RouterLink to={`/product/edit/${product.id}`}>
										<IconButton aria-label="edit">
											<EditIcon />
										</IconButton>
									</RouterLink>
								</Tooltip>
								<Tooltip title="Hapus Branch">
									<IconButton aria-label="delete" onClick={() => handleClickDialogOpen(product)}>
										<DeleteIcon />
									</IconButton>
								</Tooltip>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={!loading && products.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
			</Paper>
            <Dialog
                open={dialogOpen.open}
                keepMounted
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Konfirmasi Penghapusan ProductData"}</DialogTitle>
                <DialogContent>
                {dialogOpen.item !== null && (
                    <DialogContentText id="alert-dialog-slide-description">
                        Apakah anda yakin ingin menghapus produk {dialogOpen.item.name} {dialogOpen.item.weight} {dialogOpen.item.unit}?
                    </DialogContentText>
                )}
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                    Batal
                </Button>
                <Button onClick={() => handleProductDelete(dialogOpen.item)} color="primary">
                    Hapus
                </Button>
                </DialogActions>
            </Dialog>
		</Fragment>
	
}

ListProduct.propTypes = {
    getProduct: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  product: state.product
})

export default connect(mapStateToProps, { getProduct, delProduct })(ListProduct)